import React from "react";
import { useSearchParams } from "react-router-dom";
import cx from "classnames";

import Button from "../../../../../ui/Button";
import Tooltip from "../../../../../ui/Tooltip";
import Text from "../../../../../ui/Text";
import { ResponsiveHide } from "../../../../../ui/Responsive";
import { Check, CircleCheck, DoubleCircleCheck, Pause } from "../../../../../ui/Icon";

import { updateQueryString } from "../../../../../../utils/queryStringHelpers";
import { Appointment, PractitionerTaskStatus } from "../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  appointment: Appointment;
  providerId?: number;
};

const ScribeStatus = ({ appointment, providerId }: PropsType) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const everyNoteSynced = appointment.notes?.every((note) => !!note.lastSyncedAt);
  const someNotesSynced = appointment.notes?.some((note) => !!note.lastSyncedAt);

  const sessionComplete = appointment.practitionerTaskStatus === PractitionerTaskStatus.MIKA_USED;
  const sessionPaused = appointment.practitionerTaskStatus === PractitionerTaskStatus.MIKA_PAUSED;
  const fullySynced = sessionComplete && everyNoteSynced;
  const partiallySynced = sessionComplete && !everyNoteSynced && someNotesSynced;

  const isProvidersAppointment = appointment.practitionerId === providerId;
  const isEligibleForPromptStart = !sessionComplete && !sessionPaused && isProvidersAppointment;

  return (
    <>
      {isEligibleForPromptStart && (
        <Button
          className={styles.ScribeStartButton}
          size="S"
          onClick={() => {
            updateQueryString(
              { appointmentId: appointment.id.toString(), promptStart: "y" },
              setSearchParams
            );
          }}
          inline
        >
          Start
          <ResponsiveHide hideOnDesktop> Scribe</ResponsiveHide>
        </Button>
      )}
      {fullySynced && (
        <div className={cx(styles.PractitionerTaskStatus)}>
          <Tooltip
            contentClassName={styles.TooltipContentMedium}
            icon={
              <div className={cx(styles.Emerald)}>
                <DoubleCircleCheck size={20} />
              </div>
            }
            position="topLeft"
          >
            <Text bold size="S">
              All documents synced
            </Text>
            {appointment.notes?.map((note) => {
              return (
                <div className={styles.NoteTitleRow} key={note.id}>
                  <div className={styles.Emerald}>
                    <Check size={18} strokeWidth={3} />
                  </div>
                  <Text size="S" className={styles.NoteTitle}>
                    {note.title}
                  </Text>
                </div>
              );
            })}
          </Tooltip>
          <ResponsiveHide hideOnDesktop> All documents synced</ResponsiveHide>
        </div>
      )}
      {partiallySynced && (
        <div className={cx(styles.PractitionerTaskStatus)}>
          <Tooltip
            contentClassName={styles.TooltipContentMedium}
            icon={<DoubleCircleCheck size={20} />}
            position="topLeft"
          >
            <Text bold size="S">
              Some documents synced
            </Text>
            {appointment.notes?.map((note) => {
              return (
                <div className={styles.NoteTitleRow} key={note.id}>
                  {note.lastSyncedAt ? (
                    <div className={styles.Emerald}>
                      <Check size={18} strokeWidth={3} />
                    </div>
                  ) : (
                    <div />
                  )}
                  <Text size="S" className={styles.NoteTitle}>
                    {note.title}
                  </Text>
                </div>
              );
            })}
          </Tooltip>
          <ResponsiveHide hideOnDesktop> Some documents synced</ResponsiveHide>
        </div>
      )}
      {sessionComplete && !partiallySynced && !fullySynced && (
        <div className={cx(styles.PractitionerTaskStatus)}>
          <Tooltip
            icon={<CircleCheck size={20} />}
            contentClassName={styles.TooltipContentSmall}
            position="topLeft"
          >
            <Text size="S">Session completed</Text>
          </Tooltip>
          <ResponsiveHide hideOnDesktop> Session completed</ResponsiveHide>
        </div>
      )}
      {sessionPaused && (
        <div className={cx(styles.PractitionerTaskStatus)}>
          <Tooltip
            icon={<Pause size={20} />}
            contentClassName={styles.TooltipContentSmall}
            position="topLeft"
          >
            <Text size="S">Session paused</Text>
          </Tooltip>
          <ResponsiveHide hideOnDesktop> Session paused</ResponsiveHide>
        </div>
      )}
    </>
  );
};

export default ScribeStatus;
