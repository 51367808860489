import React, { useEffect, useContext, useRef } from "react";
import { jwtDecode } from "jwt-decode";
import { useLocation } from "react-router-dom";

import { UserContext, DecodedToken } from "./providers/UserProvider";
import { OrganizationContext } from "./providers/OrganizationProvider";

import { AppMetricType, saveAppMetrics } from "../hooks/useAppAnalytics";

import { isEligibleForSelfServeBilling } from "../utils/scribeFeatureHelpers";
import { isDemoAccountExpired } from "../utils/demoAccountExpiry";

import { UserTypeConstants } from "../typedConstants";

type PropsType = {
  children: React.ReactNode;
};

const MAX_INACTIVE_TIME_MS = 1000 * 60 * 60; // 1 hour
const TOKEN_VALIDATION_INTERVAL_TIME_MS = 1000 * 60 * 5; // 5 minutes

const SessionManager = ({ children }: PropsType) => {
  const { pathname, search } = useLocation();
  const { userId, logout, settings: userSettings } = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const user = useContext(UserContext);
  const countDownTimer = useRef<NodeJS.Timeout>();

  const onSessionExpiry = () => {
    saveAppMetrics([
      {
        type: AppMetricType.SESSION_USER_TIMEOUT,
        message: "User login session timed out",
        userId: user.userId,
        organizationId: organization?.id,
        timestamp: new Date().toISOString()
      }
    ]);
    // Log out user
    logout();
  };

  const resetCountDown = () => {
    if (countDownTimer.current) {
      clearTimeout(countDownTimer.current);
    }

    countDownTimer.current = setTimeout(onSessionExpiry, MAX_INACTIVE_TIME_MS);
  };

  // Start countdown
  useEffect(() => {
    resetCountDown();

    return () => {
      if (countDownTimer.current) {
        clearTimeout(countDownTimer.current);
      }
    };
  }, [userId]);

  // Start periodic token checks
  useEffect(() => {
    const validationInterval = setInterval(() => {
      const jwt = localStorage.getItem("jwt");
      const decoded: DecodedToken | null = jwt ? jwtDecode(jwt) : null;
      const tokenExpiryTimeSeconds = decoded?.exp;
      const currentTimeSeconds = Math.floor(Date.now() / 1000);
      const isValidToken = tokenExpiryTimeSeconds && tokenExpiryTimeSeconds > currentTimeSeconds;

      if (!isValidToken) {
        // Log out user
        logout();
      }
    }, TOKEN_VALIDATION_INTERVAL_TIME_MS);

    return () => {
      clearInterval(validationInterval);
    };
  }, [userId]);

  const handleApplicationClick = () => {
    resetCountDown();
  };

  // Redirect expired demo account to scribe billing sign up page
  useEffect(() => {
    const demoAccountExpired = isDemoAccountExpired(user.settings);
    const scribeBillingSignUpPath = "/settings/billing?form=scribeSignUp";
    const isOnSignUpForm = `${pathname}${search}` === scribeBillingSignUpPath;
    const canSelfServeBilling = isEligibleForSelfServeBilling(userSettings);

    if (
      canSelfServeBilling &&
      demoAccountExpired &&
      user?.userType !== UserTypeConstants.MIKATA_ADMIN &&
      !isOnSignUpForm
    ) {
      // redirect
      window.location.href = scribeBillingSignUpPath;
    }
  }, [user.userType, user.settings, pathname, search]);

  // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
  return (
    <div id="sessionManager" onClick={handleApplicationClick}>
      {children}
    </div>
  );
};

export default SessionManager;
