import { UserTypeConstants } from "../typedConstants";

export enum AnnouncementType {
  MODAL = "modal",
  TOOLTIP = "tooltip"
}

export enum AnnouncementName {
  SCRIBE_WELCOME = "scribeWelcome",
  DEMO_DAYS_REMAINING = "demoDaysRemaining",
  DEMO_EXPIRED = "demoExpired",
  NEW_SCRIBE_FEATURES = "newScribeFeatures",
  CREATE_UNINTEGRATED_APPOINTMENT_TUTORIAL = "createUnintegratedAppointmentTutorial",
  START_SCRIBE_SESSION_TUTORIAL = "startScribeSessionTutorial",
  SCRIBE_FEEDBACK_TUTORIAL = "scribeFeedbackTutorial",
  SCRIBE_MULTI_TEMPLATES = "scribeMultiTemplates",
  SCRIBE_MULTI_LANGUAGE = "scribeMultiLanguage"
}

export type AnnouncementConfiguration = {
  active: boolean;
  dismissible?: boolean;
  expiryDate?: string;
};

export type AnnouncementsSetting = {
  [announcementName: string]: AnnouncementConfiguration;
};

export type Announcement = AnnouncementConfiguration & {
  name: AnnouncementName;
  type: AnnouncementType;
};

export enum UserFeatureNames {
  SCRIBE = "scribe"
}

export type ScribeUserFeature = {
  status?: "active" | "cancelled" | "trial" | null;
  expiresAt?: string | null; // eg. "2024-09-02 00:00:01". Refers to the prepaid until date, not the trial expiry
  // Trial properties
  trialType?: "B2B" | "B2C" | "unknown" | null;
  trialExpectedPayer?: "clinic" | "provider" | "unknown" | null;
  trialStart?: string | null; // eg. "2024-09-02 00:00:01"
  trialEnd?: string | null; // eg. "2024-09-02 00:00:01"
  trialConvertedAt?: string | null; // eg. "2024-09-02 00:00:01"
  trialProductKey?: string | null;
  // General settings
  customVocabulary?: string[];
  dataRemovalPolicyInDays?: string | number | null;
};

export type UserSettingFeatureName = "scribe";

export type FeaturesSetting = {
  scribe?: ScribeUserFeature;
};

export type UserSettings = {
  announcements?: AnnouncementsSetting;
  features?: FeaturesSetting;
};

export type User = {
  id: number;
  organizationId?: number;
  email: string;
  firstName: string;
  lastName: string;
  roleId: number;
  settings: UserSettings;
  type: UserTypeConstants;
  active: boolean;
};
