import moment from "moment";
import { ScribeUserFeature, User } from "../types";

export default (user: User, featureName: "scribe"): ScribeUserFeature | false => {
  const featureSetting = user.settings?.features?.[featureName];
  if (!featureSetting) return false;

  const featureActive = ["active"].includes(featureSetting?.status || "");
  const featureExpiry = featureSetting.expiresAt;
  const featureInTrial = ["trial"].includes(featureSetting?.status || "");
  const featureTrialEnd = featureSetting?.trialEnd;
  const trialEndsInFuture = featureTrialEnd ? moment(featureTrialEnd).isAfter(moment()) : null;

  const expiresInFuture = featureExpiry ? moment(featureExpiry).isAfter(moment()) : null;

  if (expiresInFuture) return featureSetting;

  if (
    (featureActive && (expiresInFuture || expiresInFuture === null)) ||
    (featureInTrial && trialEndsInFuture)
  )
    return featureSetting;

  return false;
};
