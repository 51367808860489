import axios, { AxiosError } from "axios";
import { ROOT_URL } from "../constants";

const getJwt = () => {
  return localStorage.getItem("jwt");
};

type UpdateUserData = {
  practitionerUpdateData?: {
    active?: boolean;
    displayName?: string;
    fteFactor?: number;
    workPhone?: string;
    defaultLocationId?: number | null;
  };
  staffUpdateData?: {
    locationIds?: number[];
  };
  userUpdateData?: {
    active?: boolean;
    deactivatedAt?: string | null;
    email?: string | null;
    firstName?: string;
    lastName?: string;
    password?: string;
    roleId?: number;
    status?: string;
    defaultLocationId?: number | null;
  };
};

export const updateUser = async (id: number | string, params: UpdateUserData) => {
  const config = {
    headers: { Authorization: `${getJwt()}`, "Content-Type": "application/json" }
  };

  try {
    const response = await axios.patch(`${ROOT_URL}/users/${id}`, params, config);
    const { data } = response;
    return data;
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      return { error: e.response?.data };
    }
    if (JSON.stringify(e).includes("400")) {
      return { error: 400 };
    }
    return { error: e };
  }
};

export const resetUserPassword = async (id: number) => {
  const config = {
    headers: { Authorization: `${getJwt()}`, "Content-Type": "application/json" }
  };

  try {
    const response = await axios.patch(`${ROOT_URL}/users/${id}/password-reset`, {}, config);
    const { data } = response;
    return data;
  } catch (e: unknown) {
    console.log("error requesting to reset user password: ", e);
    return { error: e };
  }
};

type ContextualLoginData = {
  telusEMRCategory?: string;
  telusInstanceId?: string;
  contextualLaunchToken?: string;
};

export const contextualLogin = async (params: ContextualLoginData) => {
  try {
    const response = await axios.post(`${ROOT_URL}/users/contextual-login`, params);
    const { data } = response;
    return data;
  } catch (error: unknown) {
    return { error };
  }
};

type LoginData = {
  email: string;
  password: string;
  contextualLoginData?: ContextualLoginData;
  twoFAData?: {
    twoFAToken?: string;
    twoFARecoveryCode?: string;
  };
};

export const login = async (params: LoginData) => {
  try {
    const response = await axios.post(`${ROOT_URL}/users/login`, params);
    const { data } = response;
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    let dataToReturn;
    [404, 401, 402, 403, 400].forEach((code) => {
      if (e.response) {
        if (e.response.status === code) {
          dataToReturn = { error: code };
        }
      }
    });
    return dataToReturn;
  }
};

type UpdateAdminJWTData = {
  organizationId: string | null;
  userId: number;
};

export const updateAdminJWT = async (params: UpdateAdminJWTData) => {
  const config = {
    headers: { Authorization: `${getJwt()}`, "Content-Type": "application/json" }
  };

  const { userId, organizationId } = params;
  try {
    const response = await axios.post(
      `${ROOT_URL}/users/${userId}/updateJWT`,
      { organizationId },
      config
    );
    const { data } = response;
    return data;
  } catch (e: unknown) {
    [404, 401, 402, 403, 400].forEach((code) => {
      if (JSON.stringify(e).includes(String(code))) {
        return { error: code };
      }
    });
  }
};
