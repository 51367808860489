import axios from "axios";

import getToken from "../utils/getToken";

import { addNotification } from "./notifications";

import {
  CLONE_NOTE_TEMPLATE,
  FETCH_NOTE_TEMPLATES,
  GET_NOTE_TEMPLATE,
  UPDATE_NOTE_TEMPLATE,
  DELETE_NOTE_TEMPLATE,
  ROOT_URL,
  SET_DEFAULT_NOTE_TEMPLATES,
  FETCH_NOTE_TEMPLATE_SUGGESTIONS
} from "../constants";
import { ActionStatus, Dispatch, NoteTemplate, NoteTemplateUpdateData } from "../types";
import { closeModal } from "./modals";

export type NoteTemplatesFetchData = {
  noteTemplate: NoteTemplate;
};

export type FetchNoteTemplatesAction = {
  type: typeof FETCH_NOTE_TEMPLATES;
  status?: ActionStatus;
  payload?: {
    noteTemplates: NoteTemplate[];
    noteTemplateTags?: Record<string, string[]>;
  };
};

export type FetchNoteTemplateSuggestionsAction = {
  type: typeof FETCH_NOTE_TEMPLATE_SUGGESTIONS;
  status?: ActionStatus;
  payload?: {
    noteTemplateSuggestions: NoteTemplate[];
  };
};

export type NoteTemplatesFetchOptions = {
  silent?: boolean;
};

export const fetchNoteTemplates =
  (options?: NoteTemplatesFetchOptions) => async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    const isSilentFetch = options?.silent;

    if (!isSilentFetch) {
      dispatch({
        type: FETCH_NOTE_TEMPLATES,
        status: ActionStatus.loading
      });
    }

    try {
      const response = await axios.get(`${ROOT_URL}/notes/note-templates`, config);

      return dispatch({
        type: FETCH_NOTE_TEMPLATES,
        status: ActionStatus.success,
        payload: response.data
      });
    } catch (error) {
      dispatch(
        addNotification({
          type: "error",
          title: "Error fetching note templates",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: FETCH_NOTE_TEMPLATES,
        status: ActionStatus.error
      });
    }
  };

export type NoteTemplateSuggestionsFetchOptions = {
  silent?: boolean;
  showErrorNotification?: boolean;
};

export const fetchNoteTemplateSuggestions =
  (options?: NoteTemplateSuggestionsFetchOptions) => async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    const isSilentFetch = options?.silent;
    const showErrorNotification = options?.showErrorNotification;

    if (!isSilentFetch) {
      dispatch({
        type: FETCH_NOTE_TEMPLATE_SUGGESTIONS,
        status: ActionStatus.loading
      });
    }

    try {
      const response = await axios.get(`${ROOT_URL}/notes/note-templates/suggestions`, config);

      return dispatch({
        type: FETCH_NOTE_TEMPLATE_SUGGESTIONS,
        status: ActionStatus.success,
        payload: response.data
      });
    } catch (error) {
      if (showErrorNotification) {
        dispatch(
          addNotification({
            type: "error",
            title: "Error fetching note template suggestions",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
      }

      return dispatch({
        type: FETCH_NOTE_TEMPLATE_SUGGESTIONS,
        status: ActionStatus.error
      });
    }
  };

export type CloneNoteTemplateData = {
  cloneFromTemplateId: number;
  cloneToUserId?: number;
  includeAlways?: boolean;
  isFavourited?: boolean;
  onSuccess?: (clonedTemplateId: string) => void;
};

export type CloneNoteTemplateAction = {
  type: typeof CLONE_NOTE_TEMPLATE;
  status?: ActionStatus;
  payload?: {
    success: boolean;
    noteTemplate: NoteTemplate;
  };
};

export const cloneNoteTemplate =
  (
    noteTemplateData: CloneNoteTemplateData,
    successMessage: string = "Successfully cloned note template"
  ) =>
  async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: CLONE_NOTE_TEMPLATE,
      status: ActionStatus.loading
    });

    const data = {
      cloneToUserId: noteTemplateData.cloneToUserId,
      isFavourited: noteTemplateData.isFavourited,
      includeAlways: noteTemplateData.includeAlways
    };

    try {
      const response = await axios.post(
        `${ROOT_URL}/notes/note-templates/${noteTemplateData.cloneFromTemplateId}`,
        data,
        config
      );

      fetchNoteTemplates()(dispatch).then(() => {
        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: successMessage,
            autoDismiss: true
          })
        );
      });

      dispatch({
        type: CLONE_NOTE_TEMPLATE,
        status: ActionStatus.success,
        payload: response.data
      });
      const { onSuccess } = noteTemplateData;
      if (onSuccess) {
        onSuccess(response.data.noteTemplate.id);
      }
    } catch (error) {
      dispatch(
        addNotification({
          type: "error",
          title: "Error cloning note template",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: CLONE_NOTE_TEMPLATE,
        status: ActionStatus.error
      });
    }
  };
export type SetDefaultNoteTemplateData = {
  noteTemplateTags: string[];
  onSuccess?: () => void;
};
export type SetDefaultNoteTemplateAction = {
  type: typeof SET_DEFAULT_NOTE_TEMPLATES;
  status?: ActionStatus;
  payload?: {
    success: boolean;
    noteTemplate: NoteTemplate;
  };
};
export type SetDefaultNoteTemplateOptions = {
  showNotification?: boolean;
};
export const setDefaultNoteTemplate =
  (
    setDefaultNoteTemplateData: SetDefaultNoteTemplateData,
    options?: SetDefaultNoteTemplateOptions,
    successMessage: string = "Successfully updated default template"
  ) =>
  async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: SET_DEFAULT_NOTE_TEMPLATES,
      status: ActionStatus.loading
    });
    const { noteTemplateTags, onSuccess } = setDefaultNoteTemplateData;
    const showNotification = options?.showNotification;

    try {
      const response = await axios.post(
        `${ROOT_URL}/notes/note-templates/default`,
        {
          noteTemplateTags
        },
        config
      );

      fetchNoteTemplates()(dispatch).then(() => {
        if (showNotification) {
          dispatch(
            addNotification({
              type: "success",
              title: "Success",
              subtitle: successMessage,
              autoDismiss: true
            })
          );
        }
      });

      dispatch({
        type: SET_DEFAULT_NOTE_TEMPLATES,
        status: ActionStatus.success,
        payload: response.data
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (showNotification) {
        dispatch(
          addNotification({
            type: "error",
            title: "Error setting default note template",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
      }
      return dispatch({
        type: SET_DEFAULT_NOTE_TEMPLATES,
        status: ActionStatus.error
      });
    }
  };

export type NoteTemplateGetData = {
  noteTemplate: NoteTemplate;
};

export type GetNoteTemplateAction = {
  type: typeof GET_NOTE_TEMPLATE;
  status?: ActionStatus;
  payload?: { noteTemplate: NoteTemplate };
};

export const getNoteTemplateDetails =
  (
    noteTemplateId: string,
    showErrorToast: boolean = true,
    onErrorCallback?: (noteTemplateId: string) => void
  ) =>
  (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: `${token}`, "Content-Type": "application/json" }
    };

    dispatch({
      type: GET_NOTE_TEMPLATE,
      status: ActionStatus.loading
    });

    return axios
      .get(`${ROOT_URL}/notes/note-templates/${noteTemplateId}`, config)
      .then((response) => {
        return dispatch({
          type: GET_NOTE_TEMPLATE,
          status: ActionStatus.success,
          payload: response.data
        });
      })
      .catch((err) => {
        if (showErrorToast) {
          dispatch(
            addNotification({
              type: "error",
              title: "Failed to get note template details",
              subtitle: "Please try again",
              autoDismiss: true
            })
          );
        }
        if (onErrorCallback) {
          onErrorCallback(noteTemplateId);
        }
        return dispatch({
          type: GET_NOTE_TEMPLATE,
          status: ActionStatus.error
        });
      });
  };

export type UpdateNoteTemplateData = {
  noteTemplate: NoteTemplateUpdateData;
};

export type UpdateNoteTemplateAction = {
  type: typeof UPDATE_NOTE_TEMPLATE;
  status?: ActionStatus;
  payload?: { noteTemplate: NoteTemplate };
};

export const updateNoteTemplateDetails =
  (
    noteTemplate: NoteTemplateUpdateData,
    noteTemplateId: string,
    successMessage: string = "Successfully updated note template"
  ) =>
  (dispatch: Dispatch) => {
    const token = getToken();

    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: UPDATE_NOTE_TEMPLATE,
      status: ActionStatus.loading
    });

    return axios
      .put(`${ROOT_URL}/notes/note-templates/${noteTemplateId}`, noteTemplate, config)
      .then((response) => {
        fetchNoteTemplates()(dispatch);

        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: successMessage,
            autoDismiss: true
          })
        );
        return dispatch({
          type: UPDATE_NOTE_TEMPLATE,
          status: ActionStatus.success,
          payload: response.data
        });
      })
      .catch((err) => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to update note template details",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
        return dispatch({
          type: UPDATE_NOTE_TEMPLATE,
          status: ActionStatus.error
        });
      });
  };

export type NoteTemplateDeleteData = {
  noteTemplateId: string;
  onSuccess?: () => void;
};

export type DeleteNoteTemplateAction = {
  type: typeof DELETE_NOTE_TEMPLATE;
  status?: ActionStatus;
  payload?: { noteTemplate: NoteTemplate };
};

export const deleteNoteTemplate = (data: NoteTemplateDeleteData) => (dispatch: Dispatch) => {
  const { noteTemplateId, onSuccess } = data;
  const token = getToken();
  const config = {
    headers: { Authorization: `${token}`, "Content-Type": "application/json" }
  };

  dispatch({
    type: DELETE_NOTE_TEMPLATE,
    status: ActionStatus.loading
  });

  return axios
    .delete(`${ROOT_URL}/notes/note-templates/${noteTemplateId}`, config)
    .then((response) => {
      closeModal()(dispatch);

      if (onSuccess) {
        onSuccess();
      }

      dispatch(
        addNotification({
          type: "success",
          title: "Success",
          subtitle: "Successfully deleted note template",
          autoDismiss: true
        })
      );

      fetchNoteTemplates()(dispatch);

      return dispatch({
        type: DELETE_NOTE_TEMPLATE,
        status: ActionStatus.success,
        payload: response.data
      });
    })
    .catch((err) => {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to delete note template",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: DELETE_NOTE_TEMPLATE,
        status: ActionStatus.error
      });
    });
};
