import React, { useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { jwtDecode } from "jwt-decode";
import cx from "classnames";

import Button from "../../../ui/Button";
import { Insights, Platform } from "../../../ui/Icon";
import { Form, SelectInput } from "../../../ui/Input";
import Loader from "../../../ui/Loader";
import MultiSelector from "./MultiSelector";
import Search from "../../../ui/Search";
import Status from "../../../ui/Status";
import TableGrid from "../../../ui/TableGrid";
import Text from "../../../ui/Text";
import Tooltip from "../../../ui/Tooltip";

import {
  fetchBillingConfiguration as fetchBillingConfigurationAction,
  fetchPractitioners as fetchPractitionersAction,
  openModal as openModalAction,
  OpenModal,
  BillingActionType
} from "../../../../actions";

import { usePermissions, PermissionsGuard } from "../../../../hooks/usePermissions";
import useSearchFilter from "../../../../hooks/useSearchFilter";

import { UserContext } from "../../../providers/UserProvider";

import getProviderScribeBilling from "../../../../utils/getProviderScribeBilling";
import { userRoleDisplayMap } from "../../../../utils/userRoleDisplayMap";
import { userTypeDisplayMap } from "../../../../utils/userTypeDisplayMap";
import formatDate from "../../../../utils/formatDate";

import {
  ModalTypes,
  UserStatuses,
  UserTypeConstants,
  userStatusConfigMap,
  userStatusOptions
} from "../../../../constants";

import {
  BillingConfigSetting,
  Location,
  Practitioner,
  Permissions,
  Staff,
  StaffToken,
  ReduxStateType,
  DashboardUser,
  UserFeatureNames
} from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  organizationId: number;
  staff: Array<Staff>;
  locations: Array<Location>;
  practitioners: Practitioner[];
  practitionersLoading: boolean;
  billingLoading: boolean;
  settings?: BillingActionType;
  fetchBillingConfiguration: (organizationId: string) => void;
  fetchPractitioners: () => void;
  openModal: OpenModal;
};

const defaultStatuses = [
  UserStatuses.ACTIVE,
  UserStatuses.INVITE_EXPIRED,
  UserStatuses.INVITE_SENT,
  UserStatuses.INVITE_FAILED,
  UserStatuses.AWAITING_PASSWORD_RESET,
  UserStatuses.LOCKED_OUT,
  UserStatuses.PASSWORD_RESET_FAILED,
  UserStatuses.PENDING
];

const UsersTable = ({
  organizationId,
  staff,
  locations,
  practitioners,
  practitionersLoading,
  billingLoading,
  settings,
  fetchBillingConfiguration,
  fetchPractitioners,
  openModal
}: PropsType): JSX.Element => {
  const user = useContext(UserContext);
  const isMikataAdmin = user.userType === UserTypeConstants.MIKATA_ADMIN;
  const canViewBillingConfig = usePermissions(
    [isMikataAdmin ? Permissions.VIEW_BILLING : Permissions.VIEW_BILLING_SELF],
    false
  );
  const [billingConfig, setBillingConfig] = useState<BillingConfigSetting>();

  const [statusFilter, setStatusFilter] = useState<string[]>(defaultStatuses);
  const [typeFilter, setTypeFilter] = useState<string>(" ");

  const users: DashboardUser[] = [
    ...(staff as DashboardUser[]),
    ...(practitioners as DashboardUser[])
  ];

  const typeFilteredRows = useMemo(() => {
    return users.filter((row: DashboardUser) => {
      return typeFilter !== " " ? row.type === typeFilter : true;
    });
  }, [users, typeFilter]);

  const statusAndTypeFilteredRows = useMemo(() => {
    return typeFilteredRows.filter((row: DashboardUser) => {
      return statusFilter.includes(row.status);
    });
  }, [typeFilteredRows, statusFilter]);

  useEffect(() => {
    fetchPractitioners();
  }, []);

  useEffect(() => {
    if (organizationId && canViewBillingConfig) {
      fetchBillingConfiguration(organizationId.toString());
    }
  }, [organizationId]);

  useEffect(() => {
    if (!billingLoading && settings) {
      const billingConfigSetting = settings.setting;
      const newBillingConfig = billingConfigSetting?.settingValue as BillingConfigSetting;
      setBillingConfig(newBillingConfig);
    }
  }, [billingLoading]);

  const onStatusSelectorChange = (statuses: string[]) => {
    setStatusFilter(statuses);
  };

  const { filteredRows, onSearchChange, onSearchClear } = useSearchFilter<DashboardUser>(
    statusAndTypeFilteredRows,
    (search: string, user: DashboardUser) => {
      return Boolean(
        (user.type === UserTypeConstants.PRACTITIONER &&
          (user as Practitioner).fullName &&
          (user as Practitioner).fullName.toLowerCase().includes(search.toLowerCase())) ||
          (user.type === UserTypeConstants.PRACTITIONER &&
            (user as Practitioner).displayName &&
            (user as Practitioner).displayName.toLowerCase().includes(search.toLowerCase())) ||
          (user.type === UserTypeConstants.PRACTITIONER &&
            (user as Practitioner).emrPractitionerId &&
            (user as Practitioner).emrPractitionerId.includes(search)) ||
          (user.type === UserTypeConstants.STAFF &&
            `${(user as Staff).firstName || ""} ${(user as Staff).lastName || ""}`
              .toLowerCase()
              .includes(search.toLowerCase()))
      );
    }
  );

  const headers = [
    { colName: "type", content: "Type" },
    { colName: "name", content: "Name" },
    { colName: "email", content: "Email" },
    { colName: "emrId", content: "EMR ID" },
    { colName: "role", content: "Role" },
    { colName: "features", content: "Features" },
    { colName: "lastLogin", content: "Last Login" },
    { colName: "status", content: "Status" }
  ];

  if (practitionersLoading) return <Loader screen />;
  const userRows = filteredRows
    .sort((a: DashboardUser, b: DashboardUser) => {
      const aFullName =
        a.type === UserTypeConstants.STAFF
          ? `${(a as Staff).firstName} ${(a as Staff).lastName}`
          : (a as Practitioner).fullName;
      const bFullName =
        b.type === UserTypeConstants.STAFF
          ? `${(b as Staff).firstName} ${(b as Staff).lastName}`
          : (b as Practitioner).fullName;

      return aFullName.localeCompare(bFullName);
    })
    .map((row: DashboardUser) => {
      const {
        practitionerActive,
        firstName,
        lastName,
        fullName,
        displayName,
        email,
        emrPractitionerId,
        type,
        role,
        token,
        userId,
        settings
      } = row;

      let { status, lastLoggedIn } = row;
      if (token && status === UserStatuses.INVITE_SENT) {
        const decodedToken: StaffToken = jwtDecode(token);
        const numericDate = Date.now() / 1000;
        const tokenExpired = decodedToken.exp - numericDate <= 0;
        if (tokenExpired) status = UserStatuses.INVITE_EXPIRED;
      }

      const providerHasScribeBilling = getProviderScribeBilling(Number(userId), billingConfig);
      const scribeFeature = settings?.features?.scribe || {};
      const hasScribeFeature = ["active", "trial"].includes(scribeFeature?.status || "");
      const hasTrialScribeFeature = scribeFeature?.status === "trial";

      return {
        __onRowClick: () => {
          if (row.type === UserTypeConstants.STAFF) {
            openModal(ModalTypes.EDIT_STAFF_USER, {
              organizationId,
              staffInfo: { ...(row as Staff), status },
              locations
            });
          } else if (row.type === UserTypeConstants.PRACTITIONER) {
            openModal(ModalTypes.EDIT_PROVIDER, {
              provider: { ...(row as Practitioner), status },
              scribeFeature,
              scribeBilling: providerHasScribeBilling || undefined
            });
          }
        },
        type: <Text size="S">{userTypeDisplayMap(type)}</Text>,
        name: (
          <div>
            {type === UserTypeConstants.STAFF && <Text bold>{`${firstName} ${lastName}`}</Text>}
            {type === UserTypeConstants.PRACTITIONER && (
              <div>
                <Text bold>{`${fullName}`}</Text>
                {displayName && <Text size="S">{displayName}</Text>}
              </div>
            )}
          </div>
        ),
        email: <div className={styles.EmailCell}>{email}</div>,
        emrId: <div>{emrPractitionerId}</div>,
        role: <Text size="S">{userRoleDisplayMap(role)}</Text>,
        features: (
          <div>
            {type === UserTypeConstants.PRACTITIONER && (
              <div className={styles.Features}>
                {practitionerActive && (
                  <Tooltip
                    icon={
                      <div>
                        <Platform />
                      </div>
                    }
                  >
                    Mikata Platform <br /> Provider&apos;s appointments will appear on dashboard and
                    can receive messages
                  </Tooltip>
                )}
                {hasScribeFeature && (
                  <Tooltip
                    icon={
                      <div
                        className={cx(styles.FeaturesScribe, {
                          [styles.FeaturesScribeTrial]: hasTrialScribeFeature
                        })}
                      >
                        <Insights />
                      </div>
                    }
                  >
                    Scribe{hasTrialScribeFeature && " (Trial)"}
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        ),
        lastLogin: <Text>{lastLoggedIn ? formatDate(lastLoggedIn, "dateOnly") : "-"}</Text>,
        status: (
          <Status value={status} options={userStatusOptions} configMap={userStatusConfigMap} />
        )
      };
    });

  return (
    <div>
      <div className={styles.FilterRow}>
        <Form className={styles.FilterForm} initialValues={{ statusFilter, typeFilter }}>
          <div className={styles.Filter}>
            <SelectInput
              fieldName="typeFilter"
              options={[
                { label: "All types", value: " " },
                { label: "Provider", value: UserTypeConstants.PRACTITIONER },
                { label: "Staff", value: UserTypeConstants.STAFF }
              ]}
              placeholder="All types"
              customOnChange={(event) => {
                return setTypeFilter(event.typeFilter);
              }}
            />
          </div>
        </Form>
        <div className={styles.Filter}>
          <MultiSelector
            selectedOptions={statusFilter}
            onOptionsChange={onStatusSelectorChange}
            options={userStatusOptions}
            optionsTitle="Statuses"
            placeholder="Statuses"
          />
        </div>
        <Search
          id="searchUser"
          placeholder="Search a user"
          classNames={styles.Search}
          onChange={onSearchChange}
          onClear={onSearchClear}
        />
        <PermissionsGuard
          requiresVerifiedOrg={false}
          requiredPermissions={[Permissions.CREATE_STAFF]}
        >
          <Button
            id="openAddUserModal"
            className={styles.AddStaffButton}
            secondary
            size="S"
            onClick={() => {
              openModal(ModalTypes.ADD_STAFF_USER, {
                organizationId: Number(organizationId)
              });
            }}
          >
            <span className={styles.ButtonText}>Add Staff</span>
          </Button>
        </PermissionsGuard>
      </div>
      <TableGrid headers={headers} rows={userRows} maxPageRows={10} showRowFocus />
    </div>
  );
};

const mapStateToProps = ({ billing, organizationData, practitioners }: ReduxStateType) => {
  return {
    organizationId: organizationData?.organizationData?.id
      ? organizationData?.organizationData.id
      : 0,
    staff: organizationData?.organizationData?.staff || [],
    locations: organizationData?.organizationData?.locations || [],
    practitioners: practitioners.data,
    practitionersLoading: practitioners.loading,
    settings: billing.billing,
    billingLoading: billing.billingLoading
  };
};

export default connect(mapStateToProps, {
  fetchBillingConfiguration: fetchBillingConfigurationAction,
  fetchPractitioners: fetchPractitionersAction,
  openModal: openModalAction
})(UsersTable);
