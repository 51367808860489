import React from "react";
import { connect } from "react-redux";

import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import Button from "../../../ui/Button";
import { DownloadCloud } from "../../../ui/Icon";

import {
  addNotification as addNotificationAction,
  closeModal as closeModalAction
} from "../../../../actions";

import { getDownloadLinkURL } from "../../../../lib/uploads.requests";
import { NewNotification, PatientUpload } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  chatFlowTitle?: string;
  uploads: PatientUpload[];
  addNotification: (notification: NewNotification) => void;
  closeModal: () => void;
};

const getDownloadLink = async (
  uploadItem: PatientUpload,
  addNotification: (notification: NewNotification) => void,
  closeModal: () => void
) => {
  const { fileName: fileNameToDownload, conversationId, patientUploadId } = uploadItem;
  try {
    const { url: signedURL } = await getDownloadLinkURL(
      fileNameToDownload,
      patientUploadId,
      conversationId
    );

    if (signedURL) {
      /**
       * For automatically triggering download of the file instead of opening it in a new browser tab
       * https://stackoverflow.com/questions/50037912/reactjs-download-file-without-anchor-tag-or-without-opening-a-file-in-new-tab
       */

      const response = await fetch(signedURL);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const anchorElement = document.createElement("a");
      anchorElement.href = url;
      anchorElement.setAttribute("download", `appointment-${fileNameToDownload}`);
      anchorElement.target = "_blank";
      document.body.appendChild(anchorElement);

      anchorElement.click();
      anchorElement?.parentNode?.removeChild(anchorElement);

      closeModal();
    }
  } catch (e) {
    addNotification({
      type: "error",
      title: "Failed to download the file",
      autoDismiss: true
    });
  }
};

const ViewPatientUploads = ({ chatFlowTitle, uploads, addNotification, closeModal }: PropsType) => {
  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L">Patients Uploads</Heading>
      <Heading size="S" bold>
        {chatFlowTitle}
      </Heading>
      {uploads.map((item: PatientUpload, idx: number) => {
        return (
          <div key={`${item.secureMessageItemId + idx}`} className={styles.UploadButtons}>
            <Button
              inline
              size="XS"
              onClick={() => {
                getDownloadLink(item, addNotification, closeModal);
              }}
            >
              Upload {idx + 1} &nbsp; &nbsp;
              <DownloadCloud size={24} />
            </Button>
          </div>
        );
      })}
    </FloatModal>
  );
};

export default connect(null, {
  addNotification: addNotificationAction,
  closeModal: closeModalAction
})(ViewPatientUploads);
