import React from "react";

import Button, { ButtonRow } from "../../../ui/Button";
import { TextInput, TextAreaInput } from "../../../ui/Input";

import { ScribeModel } from "../../../../types";

type PropsType = {
  editingExistingModel: boolean;
  formDisabled?: boolean;
  closeModal: () => void;
  scribeModel?: ScribeModel;
};

const FormContent = ({
  formDisabled,
  closeModal,
  scribeModel,
  editingExistingModel
}: PropsType) => {
  return (
    <>
      <TextInput
        fieldName="name"
        label="Name"
        initialValue={editingExistingModel ? scribeModel?.name : ""}
        placeholder="Enter Name"
        disabled={formDisabled}
      />
      <TextInput
        fieldName="type"
        label="Type"
        placeholder="e.g. noteContent"
        initialValue={editingExistingModel ? scribeModel?.type : ""}
      />
      <TextAreaInput
        fieldName="description"
        label="Description"
        initialValue={editingExistingModel ? scribeModel?.description : ""}
        placeholder="Enter Description"
        disabled={formDisabled}
        info={<span>Mikata internal use only</span>}
      />
      <TextInput
        fieldName="endpointUrl"
        label="Endpoint Region"
        placeholder="e.g. https://mikata-scribe-east-canada-gpt-35.openai.azure.com/"
        initialValue={editingExistingModel ? scribeModel?.endpointUrl : ""}
      />
      <TextInput
        fieldName="endpointName"
        label="Deployment ID"
        initialValue={editingExistingModel ? scribeModel?.endpointName : ""}
        placeholder="e.g. sweden-central-gpt4o-20240513"
        disabled={formDisabled}
      />
      <TextAreaInput
        fieldName="configuration"
        label="Configuration"
        placeholder="Stringified JSON object"
        initialValue={
          editingExistingModel ? JSON.stringify(scribeModel?.configuration, null, 2) : ""
        }
        size="L"
        disabled={formDisabled}
      />
      <ButtonRow topBar>
        <Button inline onClick={closeModal}>
          Cancel
        </Button>
        <Button id="editScribeModelSave" type="submit" disabled={formDisabled}>
          {scribeModel && scribeModel.published
            ? "Update published model (hot fixes only)"
            : "Save"}
        </Button>
      </ButtonRow>
    </>
  );
};

export default FormContent;
