import React, { useContext, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";

import NoteTemplateDetails from "./NoteTemplateDetails";
import Loader from "../../../../ui/Loader";
import TableGrid from "../../../../ui/TableGrid";
import Text from "../../../../ui/Text";
import Search from "../../../../ui/Search";
import Heading from "../../../../ui/Heading";
import Button from "../../../../ui/Button";
import Tooltip from "../../../../ui/Tooltip";
import {
  Template,
  Pin,
  Heart,
  HeartBlank,
  PinBlank,
  Profile,
  Notes,
  OpenMail,
  LineItem
} from "../../../../ui/Icon";
import Tags from "../../../../ui/Tags";
import DetailsCell from "./DetailsCell";

import useSearchFilter from "../../../../../hooks/useSearchFilter";
import formatDate from "../../../../../utils/formatDate";
import {
  AdminModalTypes,
  NoteTemplateTagsConfig,
  StaffSettingsPageTabs,
  UserTypeConstants,
  NoteTemplateTypeTags,
  NoteTemplateSystemTags
} from "../../../../../constants";
import { PermissionsGuard } from "../../../../../hooks/usePermissions";
import { UserContext } from "../../../../providers/UserProvider";
import { OrganizationContext } from "../../../../providers/OrganizationProvider";
import { getNoteTemplateOwnerDisplayName } from "./helpers";

import {
  fetchNoteTemplates as fetchNoteTemplatesAction,
  fetchScribeModels as fetchScribeModelsAction,
  openModal as openModalAction,
  updateNoteTemplateDetails as updateNoteTemplateDetailsAction,
  OpenModal
} from "../../../../../actions";
import {
  NoteTemplate,
  ScribeModel,
  ReduxStateType,
  Permissions,
  NoteTemplateUpdateData
} from "../../../../../types";

import { updateQueryString, useQueryString } from "../../../../../utils/queryStringHelpers";

import styles from "./index.module.scss";

type PropsType = {
  openModal: OpenModal;
  fetchNoteTemplates: () => void;
  fetchScribeModels: () => void;
  updateNoteTemplateDetails: (
    noteTemplate: NoteTemplateUpdateData,
    noteTemplateId: string,
    successMessage: string
  ) => void;
  noteTemplatesLoading: boolean;
  noteTemplates: NoteTemplate[];
  scribeModels: ScribeModel[];
  mikataLevel?: boolean;
};

type IconConfigType = {
  [key: string]: {
    icon: React.ComponentType<{ size: number; color: string }>;
    label: string;
    iconSize?: number;
  };
};

export const getNoteTemplateIcon = (
  templateType: string = "",
  size: number,
  isActive?: boolean
) => {
  const iconConfig: IconConfigType = {
    notes: { icon: Notes, label: "Notes" },
    letters: { icon: OpenMail, label: "Letters", iconSize: 24 },
    forms: { icon: LineItem, label: "Forms" },
    patient: { icon: Profile, label: "Patient" },
    default: { icon: Template, label: "General" }
  };

  const matchedKey = Object.keys(iconConfig).find((key) => key === templateType) || "default";

  const { icon: Icon, label, iconSize = size } = iconConfig[matchedKey as keyof IconConfigType];

  return (
    <Tooltip
      contentClassName={styles.TooltipContent}
      icon={
        <div className={styles.TriggerIcon}>
          <Icon size={iconSize} color={isActive ? "white" : "currentColor"} />
        </div>
      }
      position="topRight"
    >
      {label}
    </Tooltip>
  );
};

const nonAdminHeaders = [
  { colName: "type", content: "Type" },
  { colName: "templateName", content: "Template" },
  { colName: "owner", content: "Owner" },
  { colName: "lastUpdated", content: "Last Updated" },
  { colName: "icon", content: "" },
  { colName: "details", content: "" }
];

const adminHeaders = [
  { colName: "id", content: "ID" },
  { colName: "type", content: "Type" },
  { colName: "templateName", content: "Template" },
  { colName: "owner", content: "Owner" },
  { colName: "lastUpdated", content: "Last Updated" },
  { colName: "icon", content: "" },
  { colName: "details", content: "" }
];

const NoteTemplatesTable = ({
  openModal,
  fetchNoteTemplates,
  fetchScribeModels,
  updateNoteTemplateDetails,
  noteTemplatesLoading,
  noteTemplates,
  scribeModels,
  mikataLevel = false
}: PropsType): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { parsed } = useQueryString();
  const { SCRIBE_TEMPLATES_ALL } = StaffSettingsPageTabs;

  const { userType, userId } = useContext(UserContext);
  const isAdmin = userType === UserTypeConstants.MIKATA_ADMIN;
  const organization = useContext(OrganizationContext);

  const { filteredRows, onSearchChange, onSearchClear } = useSearchFilter<NoteTemplate>(
    noteTemplates,
    (search: string, noteTemplate: NoteTemplate) => {
      return !!(
        (noteTemplate.title && noteTemplate.title.toLowerCase().includes(search.toLowerCase())) ||
        (noteTemplate.providerName &&
          noteTemplate.providerName.toLowerCase().includes(search.toLowerCase()))
      );
    }
  );

  const isDetailsModalOpen = Boolean(parsed.noteTemplateId);

  const openDetailsModal = (noteTemplateId: number): void => {
    updateQueryString({ noteTemplateId: noteTemplateId.toString() }, setSearchParams);
  };

  const closeDetailsModal = (): void => {
    updateQueryString({ noteTemplateId: undefined }, setSearchParams);
    onSearchClear();
  };

  useEffect(() => {
    fetchNoteTemplates();
    fetchScribeModels();
  }, []);

  // sorting below for Practitioner's view by keeping `includeAlways` aka default templates at the top
  const sortedRowsWithDefaultTemplates = filteredRows.sort((a: NoteTemplate, b: NoteTemplate) => {
    if ((a.includeAlways && a.userId !== null) || (b.includeAlways && b.userId !== null)) {
      return 0;
    }
    return a.title.localeCompare(b.title);
  });

  const updateDefaultStatus = (noteTemplate: NoteTemplate, updatedStatus: boolean) => {
    const noteTemplateId = noteTemplate?.id?.toString();
    const updateData: NoteTemplateUpdateData = {
      title: noteTemplate.title,
      tags: noteTemplate.tags || [],
      description: noteTemplate.description,
      includeAlways: updatedStatus,
      content: noteTemplate.content,
      isFavourited: noteTemplate.isFavourited,
      outputLanguage: noteTemplate.outputLanguage as string
    };
    if (noteTemplateId) {
      updateNoteTemplateDetails(
        updateData,
        noteTemplateId,
        updatedStatus ? "Added as default template" : "Removed as default template"
      );
    }
  };

  const updateFavouriteStatus = (noteTemplate: NoteTemplate, updatedStatus: boolean) => {
    const noteTemplateId = noteTemplate?.id?.toString();
    const updateData: NoteTemplateUpdateData = {
      title: noteTemplate.title,
      description: noteTemplate.description,
      tags: noteTemplate.tags || [],
      includeAlways: noteTemplate?.includeAlways,
      content: noteTemplate.content,
      isFavourited: updatedStatus,
      outputLanguage: noteTemplate.outputLanguage as string
    };
    if (noteTemplateId) {
      updateNoteTemplateDetails(
        updateData,
        noteTemplateId,
        updatedStatus ? "Added as favourite template" : "Removed as favourite template"
      );
    }
  };

  const getApplicableTags = (tags: string[]) => {
    return mikataLevel
      ? tags
      : tags?.filter((item) => Object.values(NoteTemplateSystemTags).indexOf(item) === -1);
  };

  const noteTemplatesRows = (
    (isAdmin &&
      (organization
        ? filteredRows.filter((nt: NoteTemplate) => nt.userId || nt.organizationId)
        : filteredRows.filter((nt: NoteTemplate) => !nt.userId && !nt.organizationId))) ||
    sortedRowsWithDefaultTemplates
  )
    .filter(
      (item) => location.pathname === SCRIBE_TEMPLATES_ALL.path || isAdmin || item.isFavourited
    )
    .sort((a: NoteTemplate, b: NoteTemplate) => a.id - b.id)
    .map((noteTemplate) => ({
      ...noteTemplate,
      __onRowClick: (): void => {
        openDetailsModal(noteTemplate.id);
      },
      icon: (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {noteTemplate.userId && (
            <div className={styles.IconColumn}>
              {noteTemplate.includeAlways ? (
                <Button
                  inline
                  onClick={(event) => {
                    event.stopPropagation();
                    updateDefaultStatus(noteTemplate, false);
                  }}
                >
                  <Tooltip
                    contentClassName={styles.TooltipContentDefault}
                    icon={<Pin />}
                    position="topRight"
                  >
                    Remove as default
                  </Tooltip>
                </Button>
              ) : (
                <Button
                  inline
                  onClick={(event) => {
                    event.stopPropagation();
                    updateDefaultStatus(noteTemplate, true);
                  }}
                >
                  <Tooltip
                    contentClassName={styles.TooltipContentDefault}
                    icon={<PinBlank />}
                    position="topRight"
                  >
                    Add as default
                  </Tooltip>
                </Button>
              )}
              {noteTemplate.isFavourited ? (
                <Button
                  inline
                  onClick={(event) => {
                    event.stopPropagation();
                    updateFavouriteStatus(noteTemplate, false);
                  }}
                >
                  <Tooltip
                    contentClassName={styles.TooltipContentFavourite}
                    icon={<Heart />}
                    position="topRight"
                  >
                    Remove as favourite
                  </Tooltip>
                </Button>
              ) : (
                <Button
                  inline
                  onClick={(event) => {
                    event.stopPropagation();
                    updateFavouriteStatus(noteTemplate, true);
                  }}
                >
                  <Tooltip
                    contentClassName={styles.TooltipContentFavourite}
                    icon={<HeartBlank />}
                    position="topRight"
                  >
                    Add as favourite
                  </Tooltip>
                </Button>
              )}
            </div>
          )}
        </>
      ),
      type: getNoteTemplateIcon(
        noteTemplate?.tags?.find((item) => Object.values(NoteTemplateTypeTags).includes(item)),
        24
      ),
      templateName: (
        <div className={styles.TemplateName}>
          <Text size="M">{noteTemplate?.title}</Text>
          <Text size="XS">{noteTemplate?.description}</Text>
          {noteTemplate?.tags && noteTemplate?.tags?.length > 0 && (
            <Tags tags={getApplicableTags(noteTemplate?.tags)} configMap={NoteTemplateTagsConfig} />
          )}
        </div>
      ),
      owner: getNoteTemplateOwnerDisplayName(noteTemplate),
      lastUpdated: formatDate(noteTemplate?.created_at || "", "simpleDate"),
      details: (
        <DetailsCell
          mikataLevel={mikataLevel}
          noteTemplateId={noteTemplate?.id}
          noteTemplateUserId={noteTemplate?.userId}
        />
      )
    }));

  if (noteTemplatesLoading) return <Loader screen />;

  return (
    <>
      {mikataLevel && <Heading size="L">Mikata Scribe Templates</Heading>}
      <div className={styles.FilterRow}>
        <Search
          id="searchTemplate"
          placeholder="Search a template"
          onChange={onSearchChange}
          onClear={onSearchClear}
        />

        <PermissionsGuard
          requiredPermissions={[Permissions.CLONE_NOTE_TEMPLATES]}
          requiresVerifiedOrg={false}
        >
          {!mikataLevel && (
            <Button
              id="addNoteTemplate"
              secondary
              disabled={mikataLevel}
              onClick={() => {
                openModal(AdminModalTypes.ADD_NOTE_TEMPLATE, {
                  providerId: !isAdmin ? userId : undefined
                });
              }}
            >
              Add Template
            </Button>
          )}
        </PermissionsGuard>
      </div>
      {noteTemplatesRows.length > 0 ? (
        <TableGrid
          id="noteTemplatesTable"
          headers={isAdmin ? adminHeaders : nonAdminHeaders}
          rows={noteTemplatesRows}
          maxPageRows={10}
          showRowFocus
        />
      ) : (
        <Text>No records found</Text>
      )}
      <NoteTemplateDetails
        mikataLevel={mikataLevel || false}
        noteTemplateId={parsed.noteTemplateId}
        isModalOpen={isDetailsModalOpen}
        closeModal={closeDetailsModal}
        scribeModels={scribeModels}
      />
    </>
  );
};

const mapStateToProps = ({ noteTemplates, scribeModels }: ReduxStateType) => {
  return {
    noteTemplates: noteTemplates.noteTemplates,
    noteTemplatesLoading: noteTemplates.noteTemplatesLoading,
    scribeModels: scribeModels.scribeModels,
    scribeModelsLoading: scribeModels.scribeModelsLoading
  };
};

export default connect(mapStateToProps, {
  openModal: openModalAction,
  fetchNoteTemplates: fetchNoteTemplatesAction,
  fetchScribeModels: fetchScribeModelsAction,
  updateNoteTemplateDetails: updateNoteTemplateDetailsAction
})(NoteTemplatesTable);
