/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import { Form } from "../../../ui/Input";
import Loader from "../../../ui/Loader";
import Text from "../../../ui/Text";
import Button, { ButtonRow } from "../../../ui/Button";
import FormContent from "./FormContent";

import {
  createScribeModel,
  updateScribeModel,
  getScribeModelDetails,
  fetchScribeModels as fetchScribeModelsAction,
  closeModal as closeModalAction
} from "../../../../actions";

import { ScribeModel, ScribeModelEditData, ReduxStateType } from "../../../../types";

type PropsType = {
  editingExistingModel: boolean;
  scribeModelId?: number;
  scribeModel?: ScribeModel;
  addLoading?: boolean;
  editLoading?: boolean;
  scribeModelDetailsLoading?: boolean;
  closeModal: () => void;
  updateModel: (id: number, data: ScribeModelEditData) => void;
  getModel: (id: number, options?: { removeFromState: boolean }) => void;
  createModel: (createDraftOfModelId?: number, data?: ScribeModelEditData) => void;
  fetchScribeModels: () => void;
};

type ScribeModelFormState = any;

const EditScribeModel = ({
  scribeModelId,
  scribeModel,
  addLoading,
  editLoading,
  scribeModelDetailsLoading,
  editingExistingModel,
  updateModel,
  getModel,
  createModel,
  fetchScribeModels,
  closeModal
}: PropsType) => {
  useEffect(() => {
    if (scribeModelId) {
      getModel(scribeModelId);
    }
    return () => {
      getModel(0, { removeFromState: true });
    };
  }, [scribeModelId]);

  if (!scribeModelId && editingExistingModel) {
    return (
      <FloatModal isOpen onClose={closeModal}>
        <Text>Invalid model id</Text>
      </FloatModal>
    );
  }

  const initialState: any = editingExistingModel
    ? scribeModel
    : { ...scribeModel, saveAsNew: true };

  const save = async (formState: any) => {
    const data: ScribeModelEditData = {
      name: formState.name,
      type: formState.type,
      description: formState.description,
      endpointUrl: formState.endpointUrl,
      endpointName: formState.endpointName,
      configuration: formState.configuration
    };
    if (editingExistingModel) {
      await updateModel(scribeModelId || 0, data);
      await fetchScribeModels();
    } else {
      await createModel(undefined, data);
      await fetchScribeModels();
    }
  };

  const formDisabled = addLoading || editLoading;
  if (!initialState || (editingExistingModel && (!scribeModel || scribeModelDetailsLoading)))
    return (
      <FloatModal isOpen onClose={closeModal}>
        <Loader screen />
      </FloatModal>
    );

  const createDraft = async () => {
    await createModel(scribeModelId || 0);
    closeModal();
    await fetchScribeModels();
  };

  const publish = async () => {
    await updateModel(scribeModelId || 0, { published: true });
    closeModal();
    await fetchScribeModels();
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L">
        {editingExistingModel ? "Edit Scribe Model" : "Create Scribe Model"}
      </Heading>
      {editingExistingModel && (
        <ButtonRow>
          {scribeModel?.published ? (
            <Button onClick={createDraft}>Create draft</Button>
          ) : (
            <Button onClick={publish}>Publish</Button>
          )}
        </ButtonRow>
      )}
      <Form
        onSubmit={(formState) => save(formState.values as ScribeModelFormState)}
        initialValues={initialState}
      >
        <FormContent
          editingExistingModel={editingExistingModel}
          formDisabled={formDisabled}
          closeModal={closeModal}
          scribeModel={scribeModel}
        />
      </Form>
    </FloatModal>
  );
};

const mapStateToProps = ({ scribeModels }: ReduxStateType) => {
  return {
    scribeModel: scribeModels.scribeModel,
    addLoading: scribeModels.scribeModelsCreateLoading,
    editLoading: scribeModels.scribeModelsUpdateLoading,
    getLoading: scribeModels.scribeModelDetailsLoading
  };
};

export default connect(mapStateToProps, {
  createModel: createScribeModel,
  updateModel: updateScribeModel,
  getModel: getScribeModelDetails,
  fetchScribeModels: fetchScribeModelsAction,
  closeModal: closeModalAction
})(EditScribeModel);
