import React from "react";

type PropsType = {
  size?: number;
};

const DoubleCircleCheck = ({ size = 24 }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill="currentColor"
        d="M12.886 1.163a11 11 0 1 0 9.06 11.918 1 1 0 0 0-1.99-.197 9 9 0 1 1-7.413-9.75 1 1 0 0 0 .343-1.971Zm5.247 2.063a1 1 0 0 1 .141 1.407l-9 11a1 1 0 0 1-1.481.074l-3.5-3.5a1 1 0 1 1 1.414-1.414l2.719 2.719 8.3-10.145a1 1 0 0 1 1.407-.141Zm5.5 0a1 1 0 0 1 .141 1.407l-9 11a1 1 0 0 1-1.481.074l-1-1a1 1 0 0 1 1.414-1.414l.219.219 8.3-10.145a1 1 0 0 1 1.407-.141Z"
      />
    </svg>
  );
};

export default DoubleCircleCheck;
