import React, { useEffect, useContext, useMemo, useState } from "react";
import { connect } from "react-redux";

import TableGrid, { StopClickPropagation } from "../../../ui/TableGrid";
import Loader from "../../../ui/Loader";
import Text from "../../../ui/Text";
import Search from "../../../ui/Search";
import { ToggleInput, Form, SelectInput } from "../../../ui/Input";
import { Insights } from "../../../ui/Icon";

import useSearchFilter from "../../../../hooks/useSearchFilter";

import {
  fetchBillingConfiguration as fetchBillingConfigurationAction,
  fetchPractitioners as fetchPractitionersAction,
  FetchPractitionersOptions,
  openModal as openModalAction,
  OpenModal,
  BillingActionType
} from "../../../../actions";
import { updateUser } from "../../../../lib";

import {
  ModalTypes,
  UserTypeConstants,
  PermissionLevels,
  PermissionPrefixes
} from "../../../../constants";
import styles from "./index.module.scss";

import {
  ReduxStateType,
  Practitioner,
  Permissions,
  BillingConfigSetting,
  ScribeUserFeature,
  UserFeatureNames
} from "../../../../types";
import { usePermissions, hasPermissionLevelOrHigher } from "../../../../hooks/usePermissions";
import Tooltip from "../../../ui/Tooltip";
import { OrganizationContext } from "../../../providers/OrganizationProvider";
import { UserContext } from "../../../providers/UserProvider";
import getProviderScribeBilling from "../../../../utils/getProviderScribeBilling";

type PropsType = {
  practitioners: Practitioner[];
  loading: boolean;
  billingLoading: boolean;
  settings?: BillingActionType;
  fetchBillingConfiguration: (organizationId: string) => void;
  fetchPractitioners: (options?: FetchPractitionersOptions) => void;
  openModal: OpenModal;
};

const headers = [
  { colName: "id", content: "ID" },
  { colName: "fullName", content: "Name" },
  { colName: "displayName", content: "Display Name" },
  { colName: "emrPractitionerId", content: "EMR ID" },
  { colName: "workPhone", content: "Phone Number" },
  { colName: "features", content: "Features" },
  { colName: "status", content: "Status" }
];

const ProvidersTable = ({
  practitioners,
  loading,
  billingLoading,
  settings,
  fetchBillingConfiguration,
  fetchPractitioners,
  openModal
}: PropsType): JSX.Element => {
  const user = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const canActivateProviders = hasPermissionLevelOrHigher(
    PermissionPrefixes.UPDATE_USER_OTHER,
    PermissionLevels.THIRTY
  );
  const isMikataAdmin = user.userType === UserTypeConstants.MIKATA_ADMIN;
  const canViewBillingConfig = usePermissions(
    [isMikataAdmin ? Permissions.VIEW_BILLING : Permissions.VIEW_BILLING_SELF],
    false
  );
  const [statusFilter, setStatusFilter] = useState<boolean | string>(" ");
  const [billingConfig, setBillingConfig] = useState<BillingConfigSetting>();

  const statusFilteredRows = useMemo(() => {
    return practitioners.filter((row) => {
      return statusFilter !== " " ? row.practitionerActive === statusFilter : true;
    });
  }, [practitioners, statusFilter]);

  const { filteredRows, onSearchChange, onSearchClear } = useSearchFilter<Practitioner>(
    statusFilteredRows,
    (search: string, practitioner: Practitioner) => {
      return !!(
        (practitioner.fullName &&
          practitioner.fullName.toLowerCase().includes(search.toLowerCase())) ||
        (practitioner.displayName &&
          practitioner.displayName.toLowerCase().includes(search.toLowerCase())) ||
        (practitioner.emrPractitionerId && practitioner.emrPractitionerId.includes(search))
      );
    }
  );
  // Fetch practitioners on first load
  useEffect(() => {
    fetchPractitioners();
  }, []);

  useEffect(() => {
    if (organization && canViewBillingConfig) {
      fetchBillingConfiguration(organization.id.toString());
    }
  }, [organization?.id]);

  useEffect(() => {
    if (!billingLoading && settings) {
      const billingConfigSetting = settings.setting;
      const newBillingConfig = billingConfigSetting?.settingValue as BillingConfigSetting;
      setBillingConfig(newBillingConfig);
    }
  }, [billingLoading]);

  const providerRows = filteredRows.map((provider) => {
    const fieldName = `provider-${provider.id}`;
    const providerHasScribeBilling = getProviderScribeBilling(
      Number(provider.userId),
      billingConfig
    );
    const providerScribeFeature: ScribeUserFeature =
      provider.settings?.features?.scribe || ({} as ScribeUserFeature);
    const providerHasScribeFeature = Boolean(user.canUseFeature(UserFeatureNames.SCRIBE));

    return {
      ...provider,
      __onRowClick: () =>
        openModal(ModalTypes.EDIT_PROVIDER, {
          provider,
          scribeFeature: providerScribeFeature,
          scribeBilling: providerHasScribeBilling || undefined
        }),
      fullName: <Text bold>{provider.fullName}</Text>,
      features: providerHasScribeFeature && (
        <Tooltip
          icon={
            <div>
              <Insights size={18} />
            </div>
          }
        >
          Scribe
        </Tooltip>
      ),
      status: (
        <StopClickPropagation active={canActivateProviders}>
          <Form
            key={`${provider.id}-${provider.practitionerActive}`}
            initialValues={{ [fieldName]: provider.practitionerActive }}
          >
            <ToggleInput
              key={`${provider.id}`}
              fieldName={fieldName}
              labelChecked="Active"
              labelUnchecked="Inactive"
              disabled={!canActivateProviders}
              customOnChange={async (value) => {
                await updateUser(provider.userId, {
                  practitionerUpdateData: { active: Object.values(value)[0] }
                });

                fetchPractitioners({ silent: true });
              }}
            />
          </Form>
        </StopClickPropagation>
      )
    };
  });

  if (loading) return <Loader screen />;

  const statusFilterInitialValue =
    statusFilter === " " ? statusFilter : JSON.stringify(statusFilter);

  return (
    <>
      <div className={styles.FilterRow}>
        <Form initialValues={{ statusFilter: statusFilterInitialValue }}>
          <div className={styles.StatusFilter}>
            <SelectInput
              fieldName="statusFilter"
              options={[
                { label: "All statuses", value: " " },
                { label: "Active", value: "true" },
                { label: "Inactive", value: "false" }
              ]}
              placeholder="All statuses"
              customOnChange={(event) => {
                return event.statusFilter === " "
                  ? setStatusFilter(" ")
                  : setStatusFilter(JSON.parse(event.statusFilter));
              }}
            />
          </div>
        </Form>
        <Search
          id="searchProvider"
          placeholder="Search a provider"
          classNames={styles.Search}
          onChange={onSearchChange}
          onClear={onSearchClear}
        />
      </div>
      <TableGrid
        id="providers"
        headers={headers}
        rows={providerRows}
        maxPageRows={15}
        showRowFocus
      />
    </>
  );
};

const mapStateToProps = ({ practitioners, billing }: ReduxStateType) => {
  return {
    practitioners: practitioners.data,
    loading: practitioners.loading,
    settings: billing.billing,
    billingLoading: billing.billingLoading
  };
};

export default connect(mapStateToProps, {
  fetchBillingConfiguration: fetchBillingConfigurationAction,
  fetchPractitioners: fetchPractitionersAction,
  openModal: openModalAction
})(ProvidersTable);
