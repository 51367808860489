/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import cx from "classnames";

import Text from "../ui/Text";
import { Settings } from "../ui/Icon";

import { UserContext } from "../providers/UserProvider";

import { PermissionsGuard } from "../../hooks/usePermissions";

import { getBillingUrl } from "../../lib";

import { Permissions } from "../../types";

import styles from "./clinicAccountDropdown.module.scss";
import { UserTypeConstants } from "../../constants";

import { isDemoAccountExpired } from "../../utils/demoAccountExpiry";
import { ResponsiveHide } from "../ui/Responsive";

type PropsType = {
  organizationId?: number;
  logout: () => void;
};

const ClinicAccountDropdown = ({ organizationId, logout }: PropsType) => {
  const user = useContext(UserContext);
  const demoAccountExpired = isDemoAccountExpired(user.settings);
  const { userType } = user;
  const isStaffUser = userType === UserTypeConstants.STAFF;

  const elementRef = useRef<any>();
  const [open, setOpen] = useState(false);
  const [urlLoading, setUrlLoading] = useState(false);
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (!url && organizationId && process.env.NODE_ENV === "production") {
      setUrlLoading(true);
      getBillingUrl(organizationId).then((billingUrl: string | { error: string }) => {
        if (typeof billingUrl === "string") {
          setUrl(billingUrl);
        }
        setUrlLoading(false);
      });
    }
  }, [organizationId]);

  const toggle = () => {
    setOpen((isOpen) => !isOpen);
  };

  const offDropdownClick = (event: any) => {
    const dropdownEl = elementRef.current;
    const isOutsideClick = dropdownEl && !dropdownEl.contains(event.target);

    if (isOutsideClick) {
      toggle();
      document.removeEventListener("mouseup", offDropdownClick, false);
    } else {
      setTimeout(() => {
        toggle();
        document.removeEventListener("mouseup", offDropdownClick, false);
      }, 100);
    }
  };

  useEffect(() => {
    if (open) {
      // add when mounted
      document.addEventListener("mouseup", offDropdownClick);
      return () => {
        document.removeEventListener("mouseup", offDropdownClick);
      };
    }

    document.removeEventListener("mouseup", offDropdownClick);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mouseup", offDropdownClick);
    };
  }, [open]);

  return (
    <div ref={elementRef} className={cx(styles.DropdownWrapper)}>
      <button
        type="button"
        className={cx(styles.Dropdown)}
        onClick={() => {
          toggle();
        }}
      >
        <Settings />
      </button>

      <div className={cx(styles.DropdownList, { [styles.DropdownListOpen]: open })}>
        <ResponsiveHide hideOnMobile>
          <PermissionsGuard
            requiredPermissions={[Permissions.VIEW_SETTINGS_PAGE]}
            requiresVerifiedOrg={false}
          >
            {!demoAccountExpired && (
              <NavLink to="/settings" className={styles.DropdownListItem}>
                <Text className={styles.DropdownListItemText} bold size="S" component="span">
                  Settings
                </Text>
              </NavLink>
            )}
          </PermissionsGuard>
          <PermissionsGuard
            requiresVerifiedOrg={false}
            requiredPermissions={[Permissions.VIEW_BILLING_ORGANIZATION]}
          >
            {url && isStaffUser && (
              <a href={url} rel="noreferrer" target="_blank" className={styles.DropdownListItem}>
                <Text className={styles.DropdownListItemText} bold size="S" component="span">
                  {urlLoading ? "..." : "Billing"}
                </Text>
              </a>
            )}
          </PermissionsGuard>

          <PermissionsGuard
            requiresVerifiedOrg={false}
            requiredPermissions={[Permissions.VIEW_BILLING_SELF]}
          >
            <NavLink
              to={{ pathname: "/settings/billing" }}
              state={{ modal: false }}
              className={styles.DropdownListItem}
            >
              <Text className={styles.DropdownListItemText} bold size="S" component="span">
                Billing
              </Text>
            </NavLink>
          </PermissionsGuard>
        </ResponsiveHide>

        <a
          type="button"
          href="https://mikatahealth.com/help"
          rel="noreferrer"
          target="_blank"
          className={styles.DropdownListItem}
        >
          <Text className={styles.DropdownListItemText} bold size="S" component="span">
            Help
          </Text>
        </a>
        <button type="button" className={styles.DropdownListItem} onClick={logout}>
          <Text className={styles.DropdownListItemText} bold size="S" component="span">
            Log out
          </Text>
        </button>
      </div>
    </div>
  );
};

export default ClinicAccountDropdown;
