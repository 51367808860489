/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef } from "react";
import cx from "classnames";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useField } from "informed";

import { Calendar, ChevronLeft, ChevronRight } from "../../Icon";
import Text from "../../Text";
import Button from "../../Button";

import "./custom.scss";
import styles from "./index.module.scss";
import BaseInput from "../BaseInput";
import { InputPropsType } from "../../../../types";

type PropsType = InputPropsType & {
  initialValue?: Date;
  customMinDate?: Date;
  customMaxDate?: Date;
  isClearable?: boolean;
  showIncrementDate?: boolean;
  displayFormat?: string;
  className?: string;
};

const customHeader = (
  selectedDate: Date | null,
  date: Date | null,
  decreaseMonth: React.MouseEventHandler<HTMLButtonElement> | undefined,
  increaseMonth: React.MouseEventHandler<HTMLButtonElement> | undefined,
  prevMonthButtonDisabled: boolean | undefined,
  nextMonthButtonDisabled: boolean | undefined,
  displayFormat: string
) => {
  return (
    <div className={styles.CalendarHeader}>
      <div className={styles.CalendarHeaderDate}>
        <Calendar size={24} />
        <Text size="S" bold className={styles.CalendarHeaderText}>
          {selectedDate ? moment(selectedDate).format(displayFormat) : "No date"}
        </Text>
      </div>
      <div className={styles.CalendarHeaderMonth}>
        <Button
          onClick={decreaseMonth}
          disabled={!date || prevMonthButtonDisabled}
          className={styles.CalendarButton}
        >
          <ChevronLeft />
        </Button>
        <Text size="S" component="span" bold className={styles.CalendarDate}>
          {date ? moment(date).format("MMMM YYYY") : "No date"}
        </Text>
        <Button
          onClick={increaseMonth}
          disabled={!date || nextMonthButtonDisabled}
          className={styles.CalendarButton}
        >
          <ChevronRight />
        </Button>
      </div>
    </div>
  );
};

// eslint-disable-next-line react/display-name
const ExampleCustomInput = forwardRef<any, any>(
  (
    {
      selectedDate,
      onClick,
      displayFormat
    }: { selectedDate: string; displayFormat: string; onClick: () => void },
    ref: any
  ) => {
    return (
      <button
        className={styles.CustomInputButton}
        type="button"
        onClick={onClick}
        ref={ref.current as any}
      >
        <Calendar size={24} />
        <Text size="S" bold className={styles.CustomInputButtonText}>
          {selectedDate ? moment(selectedDate).format(displayFormat) : "No date"}
        </Text>
      </button>
    );
  }
);

const DateSelectInput = ({
  fieldName,
  label,
  showField = true,
  initialValue,
  customMinDate,
  customMaxDate,
  isClearable,
  displayFormat = "dddd, MMMM D",
  showIncrementDate = true,
  disabled = false,
  className = ""
}: PropsType) => {
  const { fieldState, fieldApi } = useField({
    name: fieldName,
    initialValue: initialValue ? moment(initialValue).format("dddd, MMMM D, YYYY").toString() : ""
  });

  const value = fieldState.value as any;
  const error = fieldState.error as string | undefined;
  const { setValue, setTouched, validate: validateInput } = fieldApi;

  const onChangeHandler = (date: Date | null) => {
    if (date) {
      setValue(moment(date).format("dddd, MMMM D, YYYY").toString());
    } else {
      setValue(null);
    }

    setTouched(true);
    if (error) {
      validateInput();
    }
  };

  return (
    <BaseInput showField={showField} fieldName={fieldName} label={label} error={error}>
      <div className={cx(styles.DateSelector, className)}>
        {showIncrementDate && (
          <div id="ChevronLeft" className={styles.DateSelectorSide}>
            <Button
              className={styles.CalendarButton}
              onClick={() => {
                onChangeHandler(value ? moment(value).subtract(1, "days").toDate() : new Date());
              }}
              disabled={
                disabled ||
                (customMinDate && value && moment(value).isSame(moment(customMinDate), "day"))
              }
            >
              <ChevronLeft size={26} />
            </Button>
          </div>
        )}

        <div className={styles.DateSelectorMain}>
          {/* Please see the following docs for more information of react datepicker this.props
          https://github.com/Hacker0x01/react-datepicker/tree/master/docs
          https://reactdatepicker.com/#example-custom-input */}
          <DatePicker
            selected={value ? moment(value).toDate() : null}
            onChange={onChangeHandler}
            className={styles.DatePicker}
            calendarClassName={styles.Calendar}
            dateFormat="eeee, MMMM d"
            minDate={customMinDate}
            maxDate={customMaxDate}
            isClearable={isClearable}
            customInput={<ExampleCustomInput displayFormat={displayFormat} selectedDate={value} />}
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled
            }) =>
              customHeader(
                value ? moment(value).toDate() : null,
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
                displayFormat
              )
            }
            showPopperArrow={false}
            popperClassName={styles.Popper}
            disabled={disabled}
          />
        </div>
        {showIncrementDate && (
          <div id="ChevronRight" className={styles.DateSelectorSide}>
            <Button
              className={styles.CalendarButton}
              onClick={() => {
                onChangeHandler(value ? moment(value).add(1, "days").toDate() : new Date());
              }}
              disabled={
                disabled ||
                (customMaxDate && value && moment(value).isSame(moment(customMaxDate), "day"))
              }
            >
              <ChevronRight size={26} />
            </Button>
          </div>
        )}
      </div>
    </BaseInput>
  );
};

export default DateSelectInput;
