import moment from "moment-timezone";
import { UserSettings } from "../types";

export const isDemoAccountExpired = (userSettings: UserSettings) => {
  const scribeFeatureStatus = userSettings?.features?.scribe?.status || null;
  const demoAccountExpiry = userSettings?.features?.scribe?.trialEnd || null;

  if (!scribeFeatureStatus || !demoAccountExpiry) return false;
  if (scribeFeatureStatus !== "trial") return false;

  const now = moment();
  const expiresInFuture = moment(demoAccountExpiry).diff(now, "hours") < 0;

  return expiresInFuture;
};

export const daysUntilExpiry = (demoAccountExpiry: string) => {
  const now = moment();
  const hoursTillExpiry = moment(demoAccountExpiry).diff(now, "hours");
  if (hoursTillExpiry < 24) {
    return 0;
  }
  return moment(demoAccountExpiry).diff(now, "days");
};
