import React from "react";

import {
  MessageTemplatesReduxState,
  MessageTemplatesAction
} from "../reducers/messageTemplatesReducer";
import { MessagesReduxState, MessagesAction } from "../reducers/messagesReducer";
import {
  AutomatedMessagesReduxState,
  AutomatedMessagesAction
} from "../reducers/automatedMessagesReducer";
import { CampaignsReduxState, CampaignsAction } from "../reducers/campaignsReducer";

import { NotesAction, NotesReduxState } from "../reducers/notesReducer";
import {
  MessageConfigurationsReduxState,
  MessageConfigurationsAction
} from "../reducers/messageConfigurationsReducer";
import { NotificationsReduxState, NotificationsAction } from "../reducers/notificationsReducer";
import { ModalsReduxState, ModalsAction } from "../reducers/modalsReducer";
import { ReasonsReduxState, ReasonsAction } from "../reducers/reasonsReducer";
import { ChatsReduxState, ChatsActions } from "../reducers/chatsReducer";
import { PractitionersReduxState, PractitionersAction } from "../reducers/practitionersReducer";
import { InstructionsReduxState, InstructionsAction } from "../reducers/instructionsReducer";
import {
  ReportTemplatesReduxState,
  ReportTemplatesAction
} from "../reducers/reportTemplatesReducer";
import { OrganizationAction, OrganizationReduxState } from "../reducers/organizationReducer";
import { AppointmentsAction, AppointmentsReduxState } from "../reducers/appointmentReducer";
import {
  AppointmentRequestsAction,
  AppointmentRequestsReduxState
} from "../reducers/appointmentRequestsReducer";
import { LocationsReduxState, LocationsAction } from "../reducers/locationsReducer";
import { PatientsAction, PatientsReduxState } from "../reducers/patientsReducer";
import { AnalyticsAction, AnalyticsReduxState } from "../reducers/analyticsReducer";
import { ServicesAction, ServicesReduxState } from "../reducers/servicesReducer";
import { DocumentsActions, DocumentsReduxState } from "../reducers/documentsReducer";
import { UsersActions, UsersReduxState } from "../reducers/usersReducer";
import { BillingAction, BillingReduxState } from "../reducers/billingReducer";
import { NoteTemplatesReduxState } from "../reducers/noteTemplatesReducer";
import { ScribeModelsReduxState } from "../reducers/scribeModelsReducer";
import { PatientContactDetailsReduxState } from "../reducers/patientContactDetailsReducer";
import { FeedbackAction, FeedbackReduxState } from "../reducers/feedbackReducer";
import { TasksReduxState, TasksAction } from "../reducers/tasksReducer";

import { FeedbackFormModes, UserStatuses, UserTypeConstants } from "../typedConstants";
import { MicStreamErrorType, EventData } from "./recordingSession";
import { JsonContent } from "./contentJSON";
import { ChatFlowResponseType } from "./chat";
import { ConditionValue } from "../components/ui/Input/JsonLogicInput/types";
import {
  AutomationDetails,
  MessageConfigurationFormDetails,
  AutomationJourneyTestData
} from "./automations";

import { Note } from "./scribe";
import { ScribeUserFeature, User, UserSettings } from "./users";

export type { NotesSyncLoading } from "../reducers/notesReducer";
export type { AppointmentUpdatesLoading } from "../reducers/appointmentReducer";
export type { AppointmentRequestsUpdatesLoading } from "../reducers/appointmentRequestsReducer";

export enum MessageTemplateMedium {
  sms = "sms",
  email = "email",
  any = "any",
  web_chat = "web/chat",
  voice = "voice"
}

export type MessageTemplate = {
  id: number;
  displayName: string;
  topic: string;
  payloadContent: string;
  isDefault: boolean;
  medium: MessageTemplateMedium;
  subject: string;
  createdAt: string;
  updatedAt: string;
  chatFlowId: number | null;
  chatFlowTitle: string | null;
  journeys: Array<{ id: number; displayName: string }>;
};

export type MessageConfigurationTemplate = {
  topic: string;
  messageTemplate: string;
  triggerValue: number;
  triggerUnit: Array<string>;
  triggerOrder: Array<string>;
  triggerEvent: Array<string>;
};

export type ChatFlow = {
  id: number;
  title: string;
  apptReason: string | null;
  organizationId: number;
  messageOrder: number[] | null;
  version: string;
  created_at: string;
  updated_at: string;
  tagnames: string[] | null;
  messages: string[];
};

export enum TextInputFormat {
  TEXT = "text",
  POSTAL_CODE = "postalCode"
}
export enum NumberInputFormat {
  NUMBER = "number",
  PERSONAL_HEALTH_NUMBER = "personalHealthNumber"
}
export type AnswerOption = { text: string; value: string };

export type ScaleInputOption = AnswerOption & {
  subText: string;
};

export type ChatNodePayloadOptions = null | {
  answers?: (string | AnswerOption | ScaleInputOption)[];
  zones?: { label: string; h: string; w: string; x: string; y: string }[];
  contentJson?: JsonContent;
  maxDigits?: number;
  inputFormat?: NumberInputFormat | TextInputFormat;
  uploadText?: string;
  format?: string;
  skippable?: boolean;
  skipMessage?: string;
  subChatFlowId?: number;
  personalHealthNumberRequired?: boolean;
  dateOfBirthRequired?: boolean;
  insufficientPatientDataMessage?: string;
  information?: JsonContent;
  variant?: string;
  taskContent?: { title: string; description: string };
  multiAppointmentMessage?: string;
  bookingReasons?: BookingReason[];
};

export type ChatFlowsNodes = {
  id: number;
  displayName: string;
  inputName: string;
  topic: string;
  payloadContent: string | null;
  payloadOptions: ChatNodePayloadOptions;
  cardType: string;
  isDefault: boolean;
  medium: MessageTemplateMedium;
  subject: string;
  createdAt: string;
  updatedAt: string;
  chatFlowId: number | null;
  chatFlowTitle: string | null;
  draftChatFlowId: number | null;
  publishedChatFlowId: number | null;
  edgesIn: number[] | null;
  edgesOut: number[] | null;
  tagNames: string[];
  subChatFlow?: {
    id: number;
    title: string;
  };
};

export type CreateChatFlowNode = {
  organizationId: number | null;
  displayName: string;
  cardType: string;
  inputName: string | null;
};

export type ChatEdge = {
  id: number | null;
  chatFlowId: number | null;
  parentId: number[];
  messageTemplateId: number | null;
};

export type MessageTemplateUpdateData = {
  id: number;
  displayName: string;
  topic: string;
  payloadContent: string;
};

export type MessageTemplateAddData = {
  displayName: string;
  topic: string;
  payloadContent: string;
  medium: MessageTemplateMedium;
  subject: string;
};

export type MessageTemplateConnection = {
  id: number;
  chatFlowId: number;
  fromMessageTemplateId: number;
  toMessageTemplateId: number;
  input: string;
  jsonLogic: ConditionValue;
};

export enum CampaignStatus {
  "DRAFT" = "draft",
  "ERROR" = "error",
  "COMPLETE" = "complete",
  "ACTIVE" = "active",
  "CREATING_RECIPIENTS" = "creating_recipients",
  "FILE_ERROR" = "file_error"
}

export type Campaign = {
  id: number;
  chatFlowId: number | null;
  created_at: string;
  fileName: string;
  messageTemplateId: number | null;
  name: string;
  organizationId: number;
  recipientCount: number;
  sendRate: number;
  sentDate: string | null;
  startDate: string | null;
  status: CampaignStatus;
  updated_at: string;
  uploadId: number | null;
};

export type NotificationType = "success" | "error" | "warning";

export type NewNotification = {
  type: NotificationType;
  title: string;
  subtitle?: string;
  autoDismiss?: boolean;
};

export type Notification = {
  id: string;
  type: NotificationType;
  title: string;
  subtitle?: string;
  autoDismiss?: boolean;
};

export type AddMessageTemplateModalProps = {
  inTouchpointForm?: boolean;
  onSuccess?: (templateId: number) => void;
};

export type EditMessageTemplateModalProps = {
  automationId: number;
  messageTemplateId: number;
  onSuccess?: (templateId: number) => void;
};

export type EditStaffUserModalProps = {
  organizationId: number;
  staffInfo: Staff;
  locations: Array<Location>;
  onSuccess?: () => void;
};

export type EditAdminUserModalProps = {
  userInfo: User;
  onSuccess?: () => void;
};

export type PurchaseNumbersModelProps = {
  organizationId: number;
  service: Service;
};

export type MigrateServiceModalProps = {
  organizationId: number;
  services: Array<Service>;
};

export type DeleteServiceModalProps = {
  organizationId: number;
  service: Service;
};

export type DirectPatientMessagingModalProps = {
  patientDisplayName: string;
  patientId: number;
  appointmentId: number;
  mobilePhone: string | null;
  emailAddress: string | null;
  canSendSecureMessage: boolean;
  dpmContentSuggestion?: string;
  sendProviderActivityMessage?: (eventType: string, eventData?: EventData) => void;
};

export type FilterDropdownModalProps = {
  selectedPractitioners: string[];
  practitionerOptions: Array<{ label: string; value: string | number }>;
  onPractitionerChange: (value: string) => void;
  selectedReasons: string[];
  reasonOptions: Array<{ label: string; value: string | number }>;
  onReasonsChange: (value: string) => void;
  selectedActiveStatusFilters: string[];
  onStatusFilterChange: (value: string) => void;
  statusFilterOptions: Array<{ label: string; value: string | number }>;
  selectedActiveCheckinStatusFilters: string[];
  onCheckinFilterChange: (value: string) => void;
  checkinFilterOptions: Array<{ label: string; value: string | number }>;
  includeFilteredOut: boolean;
  onIncludeFilteredOutChange: (value: boolean) => void;
  showCheckinStatus: boolean;
  isMikataAdmin: boolean;
  closeModal: () => void;
};

export type FilterTemplatesModalProps = {
  selectedTypes: string[];
  typesOptions: Array<{ label: string; value: string | number }>;
  onTypesChanges: (values: string[]) => void;
  selectedOwners: string[];
  ownerOptions: Array<{ label: string; value: string | number }>;
  onOwnersChange: (values: string[]) => void;
  selectedSpecialty: string[];
  specialtyOptions: Array<{ label: string; value: string | number }>;
  onSpecialtyChange: (values: string[]) => void;
  selectedStyling: string[];
  stylingOptions: Array<{ label: string; value: string | number }>;
  onStylingChange: (values: string[]) => void;
  closeModal: () => void;
};

export type EditScribeModelModalProps = {
  scribeModelId?: number;
  editingExistingModel: boolean;
  onSuccess?: () => void;
};

export type ScribeBilling = {
  advancedSubscriptionId: number;
  paidBy: string;
};

export type FindOrganizationModalProps = {
  onSave: () => void;
};

export type ModalProps =
  | React.ReactPropTypes
  | { messageTemplate: MessageTemplate; onSuccess?: () => void }
  | { automation: AutomationDetails; onSuccess?: () => void }
  | { instruction: Instruction; onSuccess?: () => void }
  | {
      messageConfigurationId: number;
      messageConfigurationDescription: string;
      automationId: number;
    }
  | { organizationId: number; location: Location }
  | {
      selectedWord: string;
      onReplaceWord?: (oldWord: string, updatedWord?: string) => void;
      noteId?: number;
    }
  | { organizationId: number | null }
  | { organizationId: number | null; chatFlowTags: Array<string> }
  | {
      chatFlowId?: number;
      chatFlowIds?: number[];
      messageTemplate?: MessageTemplate;
      automationJourneyData?: AutomationJourneyTestData;
    } // SEND_MESSAGE_TEMPLATE
  | { automationId: number }
  | { messageConfigurations: Array<MessageConfigurationFormDetails> }
  | { messageTemplatesByTopic: Array<MessageTemplate> }
  | {
      provider: Practitioner;
      scribeBilling?: ScribeBilling;
      scribeFeature: ScribeUserFeature;
    }
  | { practitioners: Practitioner[]; subscriptionId: string; billingConfig?: Setting }
  | { templateId: number | null; parentId: number | null; order: string }
  | { reportId: number | null; cardId: number | string }
  | { organizationData: OrganizationData }
  | { chatId: string | undefined }
  | { onSuccess: (settings: Array<Setting>) => void; settings: Array<Setting> }
  | { outputId: number }
  | { chatFlowOutput: ChatFlowOutput }
  | EditStaffUserModalProps
  | EditAdminUserModalProps
  | { campaign: Campaign }
  // CONFIRMATION
  | {
      onConfirmation: () => void;
      title?: string;
      content?: React.ReactNode;
      warningText?: string | null;
      confirmButtonText?: string;
      confirmButtonVariant?: "danger";
      mustCheckConfirm?: boolean;
      confirmationText?: string;
    }
  | {
      organizationId: number;
      appointmentRequestId: number;
      appointmentSlot: AvailableSlot;
      patient: Patient | null;
      reason: Reason;
      timezone: string;
    }
  | { conversation: AppointmentConversation; createdAt: string }
  | { appointmentId: number; chatFlowIds: number[] }
  | AddMessageTemplateModalProps
  | EditMessageTemplateModalProps
  | PurchaseNumbersModelProps
  | MigrateServiceModalProps
  | DeleteServiceModalProps
  | DirectPatientMessagingModalProps
  | FilterDropdownModalProps
  | FilterTemplatesModalProps
  | EditScribeModelModalProps
  | { staffOptions: { label: string; value: string }[] }
  // Initialize Note
  | {
      patientId: number;
      appointmentId: number;
      setActiveAppointmentNoteId: (noteId: number) => void;
    }
  // Link Organization
  | {
      parentOrganization: OrganizationData;
    }
  // MIC_SETUP
  | { micStreamErrorType: MicStreamErrorType }
  // Scribe Note Templates
  | { noteTemplateId: string }
  // Enroll Two FA
  | { credentials: { email: string; password: string }; qrCode: string; recoveryCodes: string[] }
  // clone template for provider
  | { providerId?: number | boolean; noteTemplateId?: number }
  | FindOrganizationModalProps
  | { chatFlowTitle?: string; uploads: PatientUpload[] };

export type ModalType = string;

// Used for mutually exclusive types
export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };

export type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

export type Location = {
  id: number;
  active: boolean;
  fullName: string;
  displayName: string;
  telecom: string;
  telecomExtension: string;
  timezone: string;
  email: string | null;
  abbrev: string;
  street: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  note: string | null;
  syncLock: boolean;
  information: JsonContent;
};

export type Address = {
  id: number;
  type: string;
  use: string;
  street: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  created_at: string;
  updated_at: string;
  note: string | null;
};

export type UserRole = {
  id?: number;
  title: string;
  created_at?: string;
  updated_at?: string;
  permissions?: string[];
  rank?: number;
  organizationId?: number;
};

export type Manager = {
  staffId: number;
  created_at: string;
  title: string;
  userId: number;
  firstName: string;
  lastName: string;
  email: string | null;
  mobilePhone: string | null;
};

export type Staff = {
  firstName: string;
  lastName: string;
  role: string;
  mobilePhone: string | null;
  email: string | null;
  userId: number;
  emrUserId: string;
  status: UserStatuses;
  type: string;
  lastLoggedIn: string;
  locationIds: number[];
  active: boolean;
  subject: string | null;
  token: string | null;
};

export type StaffToken = {
  userId: number;
  email: string;
  organizationId: number;
  exp: number;
  iat: number;
};

export type Practitioner = {
  id: number;
  fullName: string;
  displayName: string;
  workPhone: string | null;
  email?: string | null;
  emrUserId: string;
  userId: number;
  practitionerActive: boolean;
  userActive: boolean;
  emrPractitionerId: string;
  lastLoggedIn: string;
  settings: UserSettings;
  status: UserStatuses;
  type: string;
  role: string;
  token: string | null;
  fteFactor: number;
  defaultLocationId: number | null;
};

export type DashboardUser = {
  // shared
  active: boolean;
  email: string | null;
  userId: number;
  emrUserId: string;
  lastLoggedIn: string;
  status: UserStatuses;
  type: string;
  role: string;
  token: string | null;
  // for Staff
  firstName?: string;
  lastName?: string;
  mobilePhone?: string | null;
  locationIds?: number[];
  subject?: string | null;
  // for Practitioners
  id?: number;
  fullName?: string;
  displayName?: string;
  workPhone?: string | null;
  emrPractitionerId?: string;
  settings?: UserSettings;
  fteFactor?: number;
  practitionerActive?: boolean;
  userActive?: boolean;
  defaultLocationId?: number | null;
};

export enum EMRCategory {
  "WOLF" = "Wolf",
  "ACCURO" = "Accuro",
  "PS_SUITE" = "PS Suite",
  "INTELERAD" = "Intelerad",
  "MEDACCESS" = "Med Access",
  "JUNO" = "Juno",
  "OSCAR" = "Oscar",
  "INTRAHEALTH" = "Intrahealth Profile",
  "INTELERAD_UPLOAD" = "Intelerad Upload",
  "UNINTEGRATED" = "Unintegrated"
}

export enum TelusCategoryMap {
  "WOLF" = "Wolf",
  "PS_SUITE" = "PSS",
  "MEDACCESS" = "MA"
}

export type EMRType = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  integrationType: string;
  category: EMRCategory;
  refreshTokenValidity: number | null;
  csvStartLine: number | null;
};

export type Reason = {
  id: number;
  value: string;
  emrReasonId: string | null;
  active: boolean;
  checklistId: number | null;
  checklistDisplayName?: string | null;
  checklistDescription?: string | null;
};

export type Chat = {
  id: number;
  title: string;
  version: string;
  organizationId: string;
  createdAt: string;
  published: boolean;
  isSubFlow: boolean;
  tagNames?: string[];
};

export type Instruction = {
  id: number;
  displayName: string;
  description: string | null;
  groupName: string | null;
  type: "combo" | "primary" | "default" | "";
  filler: boolean;
};

export type InstructionWithReasons = Instruction & {
  reasonIds: Array<number>;
};

export type InstructionDetailed = Instruction & {
  content: JsonContent;
};

export enum OrganizationEmrFeatures {
  UNINTEGRATED_APPOINTMENTS = "unintegratedAppointments",
  UNINTEGRATED_PATIENTS = "unintegratedPatients"
}

export type OrganizationData = {
  id: number;
  displayName: string;
  fullName: string;
  locations: Array<Location>;
  canUpload: boolean;
  address: Address;
  role: UserRole;
  setupComplete: boolean;
  verified: boolean;
  integrated: boolean;
  features: string[];
  phoneNumber: string | null;
  email: string | null;
  emrFeatures: OrganizationEmrFeatures[];
  manager: Array<Manager>;
  staff: Array<Staff>;
  practitioners: Array<Practitioner>;
  userRoles: Array<UserRole>;
  emrType: EMRType;
  accuroUUID: string | null;
  syncTime: string | null;
  reasons: Array<Reason>;
  timezone: string;
  primaryColour: string | null;
  secondaryColour: string | null;
  token: string;
  integrationCategory: string;
  configured: boolean;
  subscriptionLineItems: string;
  chargeOverUserId: string;
  logo: string;
  type: string | null;
  children: { id: number; fullName: string }[];
  parentOrganizationId: number | null;
  parent: { id: number; fullName: string } | null;
  reminderTypePreference: string;
};

export type PatientOrganizationData = {
  id: number;
  accuroUUID: string | null;
};

export type UploadRecord = {
  id: number;
  organizationId: number;
  locationId: number | null;
  userId: number;
  uploadTime: string;
  uploadType: string;
  status: string;
  numberOfAppointments: number | null;
  created_at: string;
  updated_at: string;
  numberOfRemindersSent: number | null;
  filename: string | null;
  dataHash: string | null;
  duplicateId: number | null;
  appointmentsNeedingReminders: number | null;
  errorMessage: string | null;
};

export type AdminOrgData = {
  id: number;
  canUpload: boolean;
  active: boolean;
  fullName: string;
  timezone: string;
  inTestMode: boolean;
  emrName: string;
  emrIntegrationType: string;
  emrCategory: string;
  type: string;
  verified: boolean;
  groupName: string;
};

export type ReportTemplate = {
  id: number;
  name: string;
  organizationId: number;
  chatFlowId: number;
  deleted: boolean;
  categoryTagName: string | null;
};

export enum ReportTemplateCardType {
  SECTION = "section",
  SUB_REPORT_SECTION = "subReportSection",
  HEADER = "header",
  TEXT = "text",
  TEXT_NEWLINE = "textNewline",
  TEXT_INLINE = "textInline",
  CONVERSATION_SUMMARY = "conversationSummary",
  SECURE_MESSAGE_ITEMS_SUMMARY = "secureMessageItemsSummary",
  TRANSCRIPT = "transcript"
}

export enum ReportTemplateCardTypeLabel {
  section = "section",
  subReportSection = "sub-report",
  header = "header",
  text = "text",
  textNewline = "text new-line",
  textInline = "text inline",
  conversationSummary = "conversation summary",
  secureMessageItemsSummary = "secure message items summary",
  transcript = "transcript"
}

export type CreateReportTemplate = {
  displayName: string;
  autoPush: boolean;
  noteTemplate: boolean;
};

export type ReportTemplateCard = {
  id: number | string;
  reportTemplateId: number;
  order: number;
  content: string;
  type: ReportTemplateCardType;
  contentType: string;
  condition: JSON | null;
  thenContent: string;
  elseContent: string;
  parentId: number | null;
  displayName: string;
  children: ReportTemplateCard[];
  depth: number;
  categoryTagId: number | null;
};

export type ChatFlowOutput = {
  id: number;
  chatFlowId: number;
  organizationId: number;
  reportTemplateId?: string;
  type: string;
  config?: {
    letterTypeId?: number;
    title?: string;
    pushableDemographic?: Array<string>;
    dataMappings?: Record<
      string,
      {
        inputName?: string;
        tagName?: string;
        required?: boolean;
        defaultValue?: string;
      }
    >;
  };
};

export type CreateChatFlowOutput = {
  type: string;
  config: {
    letterTypeId?: number;
    title?: string;
    pushableDemographic?: string[];
    dataMappings?: Record<
      string,
      {
        inputName?: string;
        tagName?: string;
        required?: boolean;
        defaultValue?: string;
      }
    >;
  };
  chatFlowId: number;
  organizationId: number;
  reportTemplateId?: string | null;
};

export type UpdateChatFlowOutput = {
  type?: string;
  config?: {
    letterTypeId?: number;
    title?: string;
  };
  reportTemplateId?: string | null;
  chatFlowOutputId?: number;
  chatFlowId?: number;
  deleted?: boolean;
};

export type ReportMappingSetting = {
  reportTemplateId: number;
  reportTemplateName: string;
  title: string;
};

export type StatusMappingSetting = {
  [status: string]: string;
};

export type CheckinStatusMappingSetting = {
  [status: string]: string;
};

export type AccuroReportMappingSetting = ReportMappingSetting & {
  letterTypeId: number;
  letterTypeName: string;
};

export type ServiceMigrationSetting = {
  oldServiceRecordId: number;
  newServiceRecordId: number;
};
export type StaffNotificationsSetting = {
  [notificationName: string]: { recipients: string[]; sendAtHour: string };
};

export type LineItems = {
  id: number;
  productName: string;
  productKey: string;
  cycle: string;
};

export type Subscription = {
  id: number;
  lineItems?: LineItems[];
  arrears?: boolean;
  userId?: number | "unassigned" | null;
};

export type Subscriptions = Subscription[];

export type PricingItem = {
  minimum: number;
  maximum: number;
  price: string;
};

export enum BillingInvoiceTypes {
  SINGLE = "singleInvoice",
  MULTI = "multiInvoice"
}

export type BillingConfigSetting = {
  // clinic customer
  chargeOverCustomerId?: number | null;
  // clinic base subscription
  chargeOverSubscriptionId?: number | null;
  chargeOverUserId?: number | null;
  chargeOverSubscriptionInfo?: Record<string, string>;
  // multi org billing
  invoiceType?: BillingInvoiceTypes;
  pricing?: PricingItem[]; // For Parent org only which comes from 'organizationType'
  // New subs system
  subscriptions?: Subscriptions | [];
};

export type DemoAccountSetting = {
  expiresAt: string;
  productKey: string;
};

export type Setting = {
  id?: number;
  settingName: string;
  settingValue:
    | string
    | Array<ReportMappingSetting>
    | StatusMappingSetting
    | CheckinStatusMappingSetting
    | boolean
    | string[]
    | ServiceMigrationSetting
    | StaffNotificationsSetting
    | { bookingReasons: BookingReason[]; emrScheduleLocationMap?: { [scheduleId: string]: string } }
    | BillingConfigSetting
    | DemoAccountSetting;
  organizationId: number;
};

export type Feature = {
  value: string;
  name: string;
  description: string;
  configurationModalName?: string;
};

export type SignedURLData = {
  filename: string;
  uploadRecord: number;
  url: string;
  file: File;
};

export type SecureMessageItemData = {
  id: number;
  organizationId: number;
  payloadContent: string | null;
  staffUploadId: number | null;
  senderId: number;
  createdAt: string;
  deleted: boolean;
  bucketName: string | null;
  s3FileName: string | null;
  fileName: string | null;
  senderFirstName: string;
  senderLastName: string;
  deleterFirstName: string;
  deleterLastName: string;
};

export enum ConversationStatuses {
  NOT_APPLICABLE = "Not applicable",
  NOT_SENT = "Not sent",
  NO_CONTACT = "No contact",
  FIRST_CONTACT = "First Contact",
  NOT_STARTED = "Not started",
  NEEDS_HELP = "Needs help",
  WRONG_PROCEDURE = "Wrong Procedure",
  IN_PROGRESS = "In progress",
  ABANDONED = "Abandoned",
  COMPLETE = "Complete",
  FLAGGED = "Flagged",
  LOCKED_OUT = "Locked Out"
}

export enum SMSDeliveryStatus {
  QUEUED = "queued",
  SENDING = "sending",
  SENT = "sent",
  FAILED = "failed",
  DELIVERED = "delivered",
  UNDELIVERED = "undelivered",
  RECEIVING = "receiving",
  RECEIVED = "received",
  ACCEPTED = "accepted",
  SCHEDULED = "scheduled",
  READ = "read",
  LIKE_DELIVERED = "like-delivered"
}

export enum TranscriptMessageStatus {
  "complete" = "complete",
  "noContact" = "noContact"
}

export type PayloadOptions = {
  patientVerified: boolean;
};

export type TranscriptMessage = {
  id: number;
  status: TranscriptMessageStatus | null;
  incoming: boolean;
  sent: string;
  medium: "sms" | "email" | "web/chat";
  serviceStatus: null | SMSDeliveryStatus;
  payloadContent: string;
  payloadOptions: PayloadOptions | null;
  senderFirstName: string;
  senderLastName: string;
  title: string;
};

export type Conversation = {
  id: number;
  chatFlowId: number;
  status: ConversationStatuses;
  createdAt: string;
  updatedAt: string;
  title: string;
};

export type ScheduleTag = {
  name: string;
};

export type BookingReason = {
  isDefault: boolean;
  skipBooking: boolean;
  autoBookable: boolean;
  requiresPrimaryPractitioner: boolean;
  cardText?: string;
  label?: string;
  hideLocationOptions: boolean;
  hidePractitionerOptions: boolean;
  excludedLocationIds: number[];
  excludedPractitionerIds: number[];
  note?: string;
  duration: number | null;
  defaultEmrReasonId: string | null;
  selectionConditions: JSON | null;
  includedScheduleTags: string[];
  availabilityRangeEndWeeks: number | null;
};

export type AvailableSlot = {
  duration: number;
  slotIds: number[];
  startTime: string;
  endTime: string;
  locationId: number;
  locationName: string;
  practitionerId: number;
  practitionerName: string;
  scheduleId?: string;
  isStillAvailable?: boolean;
  usedForBooking?: boolean;
};

export type AppointmentBookingData = {
  bookingReason: BookingReason;
  responseType: ChatFlowResponseType.BOOKING;
  selectedAppointmentSlots: AvailableSlot[];
  bookedAt?: string;
  reasonUsedForBooking?: Reason;
  emrPatientUsedForBooking?: Patient;
};

export type AppointmentRequest = {
  id: number;
  organizationId: number;
  assignedUserFirstName?: string;
  assignedUserLastName?: string;
  assignedStaffUserId?: number;
  bookingData: AppointmentBookingData | null;
  dateTimePreference?: {
    date?: string | string[];
    time?: string | string[];
    option?: string | string[];
  }[];
  hasRequisition: 0 | 1 | null;
  isMobile: boolean;
  locationPreference?: string[];
  patientBirthday: string;
  patientFirstName: string;
  patientHealthcareNumber: string;
  patientLastName: string;
  patientNumber: string;
  reasons?: string[];
  status: string;
  uploadIds: number[];
  created_at: string;
  updated_at: string;
};

export type AppointmentRequestDetail = {
  appointmentId?: number;
  id: number;
  organizationId: number;
  bookingData: AppointmentBookingData | null;
  created_at: string;
  dateTimePreference?: {
    date?: string | string[];
    time?: string | string[];
    option?: string | string[];
  }[];
  hasRequisition: 0 | 1 | null;
  isMobile: boolean;
  locationPreference?: string[];
  patientBirthday: string;
  patientFirstName: string;
  patientHealthcareNumber: string;
  patientLastName: string;
  patientNumber: string;
  reasons?: string[];
  status: string;
  uploadIds: number[];
  updated_at: string;
  reports: Report[];
  transcript: TranscriptMessage[];
};

export type Content =
  | { type: "title"; content: string }
  | { type: "header"; content: string }
  | { type: "text"; content: string }
  | { type: "transcript"; content: string }
  | { type: "table"; content: string }
  | { type: "imageSelect"; content: string }
  | { type: "paragraphBreak"; content: string };

export type Report = {
  id: number;
  conversationId: number;
  content: Content[];
};

export enum ActionStatus {
  loading = "loading",
  silentLoading = "silentLoading",
  success = "success",
  error = "error"
}

export enum AppointmentStatus {
  RESCHEDULE = "RESCHEDULE",
  CONFIRMED = "CONFIRMED",
  UNCLEAR = "UNCLEAR",
  NEED_CONFIRM = "NEED_CONFIRM",
  NEEDS_CALL = "NEEDS_CALL",
  NONE = "NONE",
  PENDING = "PENDING",
  CONTACTED = "CONTACTED",
  COMPLETE = "COMPLETE"
}

export enum AppointmentCheckedInStatus {
  NONE = "none",
  CHECKED_IN = "checked_in",
  NOTICE_SENT = "notice_sent",
  NO_CONTACT_INFO = "no_contact_info",
  STAFF_CONTACTED = "staff_contacted",
  UNCLEAR = "unclear"
}

export enum PractitionerTaskStatus {
  NONE = "none",
  MIKA_USED = "mikaUsed",
  MIKA_PAUSED = "mikaPaused"
}

export type Appointment = {
  id: number;
  organizationId: number;
  start: string;
  endTime: string;
  practitionerId: number;
  practitionerName: string;
  practitionerDisplayName: string;
  practitionerTaskStatus: null | PractitionerTaskStatus;
  patientFirstName: string;
  patientLastName: string;
  patientId: number;
  emrPatientId?: string;
  emrAppointmentId?: string;
  locationName: string;
  verifiedPhoneNumber: string | null;
  mobilePhone: string | null;
  homePhone: string | null;
  email: string | null;
  status: AppointmentStatus;
  checkedIn: AppointmentCheckedInStatus | null;
  filteredIn?: boolean;
  cancelled?: boolean;
  deleted?: boolean;
  reasonName: string;
  conversations: Conversation[];
  timezone?: string;
  canSendSms?: boolean;
  canSendEmail?: boolean;
  canSendSecureMessage?: boolean;
  healthcareIdentifier?: string;
  dateOfBirth?: string;
  smsOptOut?: boolean;
  emailOptOut?: boolean;
  voiceOptOut?: boolean;
  notes?: Note[];
};

export type AppointmentConversation = Conversation & {
  chatFlowTitle: ConversationStatuses;
  tagnames?: string[] | null;
};

export type PatientUpload = {
  bucketName: string;
  conversationId: number;
  fileName: string;
  patientUploadId: number;
  secureMessageItemId: number;
};

export type AppointmentDetail = {
  practitionerId: number | null;
  locationId: number | null;
  reasonId: number | null;
  conversations?: [AppointmentConversation];
  reports?: Report[];
  transcript?: TranscriptMessage[];
  patientUploads?: PatientUpload[];
} & Appointment;

export type Patient = {
  emrPatientId: string;
  firstName: string;
  lastName: string;
  personalHealthNumber: number;
  patientEmail: string;
  mobilePhone: string;
  homePhone: string;
  phoneNumberToDisplay: string;
  isVerifiedMobile: boolean;
  primaryProviders: { id: number; practitionerName: string }[];
  dateOfBirth: string;
};

export type PatientContactDetails = {
  id?: number;
  organizationId?: number;
  patientId?: number;
  verifiedMobile?: string;
  email?: string;
  sentWelcome?: boolean;
  smsOptOut?: boolean;
  emailOptOut?: boolean;
  surveyOptOut?: boolean;
  created_at?: string;
  updated_at?: string;
  voiceOptOut?: boolean;
  fromNumber?: string;
  sentWelcomeAt?: string;
};

export type SyncRecord = {
  id: number;
  startTime: string;
  endTime: string;
  lastId: number;
  recordCount: number;
  organizationId: number;
  createdAt: string;
  updatedAt: string;
  status: string;
  executionId: string;
  appointmentsNeedingReminders: number;
  numberOfRemindersSent: number;
  firstOfDay: boolean;
  bulkAppointmentsSynced: boolean;
  sample: boolean;
  fetchAllAppts: boolean;
};

export type ServiceConfig = {
  serviceId: string;
  serviceNumbers: Array<string>;
};

export type Service = {
  id: number;
  organizationId: number;
  medium: string;
  provider: string;
  priority: number;
  config: ServiceConfig;
  createdAt: string;
  updatedAt: string;
};

export type Document = {
  id: number;
  organizationId: number;
  filename: string;
  s3Path: string;
  createdAt: string;
  shortCode: string;
};

export type Tag = {
  id: number;
  name: string;
  type: string;
};

export type AddFeedbackData = {
  context: {
    appointmentIds?: number[] | null;
    noteIds?: number[] | null;
    activeNoteId?: number | null;
  };
  formType: string;
  feedbackData: { consent: boolean; scribeRating?: number; userComment: string };
};

export type SetFeedbackModeData = {
  feedbackMode: FeedbackFormModes | null;
};

export type TaskAppointmentRequestContext = {
  appointmentRequestStatus: string;
  bookingMode: string;
  bookedAt: Date;
  patientFullName: string;
  patientNumber: string;
  patientVerified: boolean;
  locations?: Array<string>;
  timeOfDay?: Array<string>;
  reasons?: Array<string>;
  practitioners?: Array<{ id: number; displayName: string }>;
};

export type TaskDirectMessageContext = {
  patientFullName: string;
  patientNumber: string;
};

export type Task = {
  id: number;
  organizationId: number;
  type: string;
  openStatus: string;
  lastOpenedAt: Date;
  priority: number;
  // filterContext: TaskAppointmentRequestContext | TaskDirectMessageContext;
  filterContext: TaskAppointmentRequestContext;
  created_at: Date;
  updated_at: Date;
  parentTaskId?: number;
  appointmentRequestId?: number;
  assignedUsers?: Array<User>;
};

export type ActionType =
  | AppointmentRequestsAction
  | AppointmentsAction
  | AutomatedMessagesAction
  | BillingAction
  | CampaignsAction
  | InstructionsAction
  | LocationsAction
  | MessageConfigurationsAction
  | MessagesAction
  | MessageTemplatesAction
  | ModalsAction
  | NotesAction
  | NotificationsAction
  | OrganizationAction
  | PatientsAction
  | PractitionersAction
  | ReasonsAction
  | ChatsActions
  | ReportTemplatesAction
  | AnalyticsAction
  | ServicesAction
  | DocumentsActions
  | FeedbackAction
  | UsersActions
  | TasksAction;

export type Dispatch = (action: ActionType) => void;

export type ReduxStateType = {
  adminData: { adminData: { data: Array<AdminOrgData> } };
  appointmentRequestsInfo: AppointmentRequestsReduxState;
  appointments: AppointmentsReduxState;
  automatedMessages: AutomatedMessagesReduxState;
  billing: BillingReduxState;
  campaigns: CampaignsReduxState;
  chats: ChatsReduxState;
  feedback: FeedbackReduxState;
  instructions: InstructionsReduxState;
  locations: LocationsReduxState;
  messageConfigurations: MessageConfigurationsReduxState;
  messages: MessagesReduxState;
  messageTemplates: MessageTemplatesReduxState;
  modals: ModalsReduxState;
  notes: NotesReduxState;
  noteTemplates: NoteTemplatesReduxState;
  notifications: NotificationsReduxState;
  organizationData: { organizationData: OrganizationData; settings: Array<Setting> };
  organizationDetails: OrganizationReduxState;
  practitioners: PractitionersReduxState;
  reasons: ReasonsReduxState;
  reportTemplates: ReportTemplatesReduxState;
  scribeModels: ScribeModelsReduxState;
  uploadRecord: { uploadInProgress: boolean };
  patients: PatientsReduxState;
  patientContactDetails: PatientContactDetailsReduxState;
  analytics: AnalyticsReduxState;
  services: ServicesReduxState;
  documents: DocumentsReduxState;
  users: UsersReduxState;
  tasks: TasksReduxState;
};

export enum JourneyDetailTabModes {
  GENERAL = "general",
  TRIGGERS = "triggers",
  TOUCHPOINTS = "touchpoints",
  RESPONSE_MESSAGES = "response messages"
}

export enum AppointmentDetailTabModes {
  CHAT = "Chat Transcript",
  TIMELINE = "Timeline"
}

export enum AppointmentRequestDetailTabModes {
  BOOKING = "Booking",
  CHAT = "Transcripts",
  SUMMARY = "Summary",
  TIMELINE = "Timeline"
}

export enum FormModes {
  CREATE = "create",
  EDIT = "edit"
}

export type HistoryEvent = {
  initiatedBy: string;
  initiatedByType: UserTypeConstants;
  eventTime: string;
  changeType: HistoryEventChangeTypes;

  data: {
    description: string;
    old: {
      patientName?: string;
      practitioner?: string;
      location?: string;
      start?: string;
      reason?: string;
      status?: string;
      checkedIn?: string;
      completed?: boolean;
      cancelled?: boolean;
      deleted?: boolean;
      filteredIn?: boolean;
      healthcareIdentifier?: string;
      patientEmail?: string;
      patientMobile?: string;
      sentWelcome?: boolean;
      smsOptOut?: boolean;
      emailOptOut?: boolean;
      surveyOptOut?: boolean;
      emrPatientId?: string;
      assignedStaffUserName?: string;
    } | null;
    new: {
      patientName?: string;
      practitioner?: string;
      location?: string;
      start?: string;
      reason?: string;
      status?: string;
      checkedIn?: string;
      completed?: boolean;
      cancelled?: boolean;
      deleted?: boolean;
      filteredIn?: boolean;
      healthcareIdentifier?: string;
      patientEmail?: string;
      patientMobile?: string;
      sentWelcome?: boolean;
      smsOptOut?: boolean;
      emailOptOut?: boolean;
      surveyOptOut?: boolean;
      emrPatientId?: string;
      assignedStaffUserName?: string;
    };
  };
};

export enum HistoryEventChangeTypes {
  APPOINTMENT_CREATION = "appointmentCreation",
  APPOINTMENT_COMPLETED_ON = "appointmentCompletedOn",
  APPOINTMENT_CANCELLED = "appointmentCancelled",
  APPOINTMENT_DELETED = "appointmentDeleted",
  APPOINTMENT_STATUS = "appointmentStatus",
  APPOINTMENT_DATE = "appointmentDate",
  APPOINTMENT_REASON = "appointmentReason",
  APPOINTMENT_LOCATION = "appointmentLocation",
  APPOINTMENT_PRACTITIONER = "appointmentPractitioner",
  APPOINTMENT_CHECK_IN_STATUS = "appointmentCheckInStatus",
  APPOINTMENT_FILTERED_IN = "appointmentFiltered",
  APPOINTMENT_PATIENT_ID = "appointmentPatient",
  PATIENT_CREATION = "patientCreation",
  PATIENT_LOCATION = "patientLocation",
  PATIENT_PRACTITIONER = "patientPractitioner",
  PATIENT_HEALTH_CARE_IDENTIFIER = "patientHealthCareIdentifier",
  PATIENT_MOBILE = "patientMobile",
  PATIENT_EMAIL = "patientEmail",
  PATIENT_WELCOME_MESSAGE = "patientWelcomeMessageSent",
  PATIENT_SMS_OPT_OUT = "patientSMSOptOutStatus",
  PATIENT_EMAIL_OPT_OUT = "patientEmailOptOutStatus",
  PATIENT_VOICE_OPT_OUT = "patientVoiceOptOutStatus",
  PATIENT_SURVEY_OPT_OUT = "patientSurveyOptOutStatus",
  REQUEST_CREATION = "requestCreation",
  REQUEST_STATUS = "requestStatus",
  REQUEST_ASSIGNED = "requestAssigned",
  REQUEST_ASSIGNEE = "requestAssignee",
  REQUEST_SUBMITTED = "requestSubmitted",
  REQUEST_PROCESSED = "requestProcessed",
  REQUEST_ASSIGNEE_UPDATED = "requestAssigneeUpdated"
}
