import { UserSettings } from "../types";

export const isEligibleForSelfServeBilling = (userSettings?: UserSettings) => {
  const isB2CTrial =
    userSettings?.features?.scribe?.trialExpectedPayer === "provider" &&
    userSettings?.features?.scribe?.trialType === "B2C" &&
    userSettings?.features?.scribe.status === "trial";

  return isB2CTrial;
};
