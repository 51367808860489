import React, { useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { RawDraftContentState } from "draft-js";

import { useSearchParams } from "react-router-dom";
import Button, { ButtonRow } from "../../../../../ui/Button";
import { FullScreenModal } from "../../../../../ui/Modal";
import Loader from "../../../../../ui/Loader";
import { Form, NoteTemplateInput } from "../../../../../ui/Input";
import Card from "../../../../../ui/Card";

import NoteTemplateSummaryCard from "./NoteTemplateSummaryCard";

import { UserContext } from "../../../../../providers/UserProvider";
import { isRequired } from "../../../../../../utils/validators";
import { usePermissions } from "../../../../../../hooks/usePermissions";

import {
  openModal as openModalAction,
  OpenModal,
  getNoteTemplateDetails as getNoteTemplateDetailsAction,
  updateNoteTemplateDetails as updateNoteTemplateDetailsAction,
  deleteNoteTemplate as DeleteNoteTemplateAction,
  cloneNoteTemplate as cloneNoteTemplateAction,
  NoteTemplateDeleteData,
  CloneNoteTemplateData
} from "../../../../../../actions";
import { updateQueryString } from "../../../../../../utils/queryStringHelpers";

import {
  ModalTypes,
  defaultLanguageOptionValue,
  NoteTemplateTypeTags,
  NoteTemplateActionTags,
  NoteTemplateSpecialtyTags,
  NoteTemplateStylingTags,
  UserTypeConstants,
  NoteTemplateSystemTags,
  NoteTemplatePseudoTags
} from "../../../../../../constants";

import {
  NoteTemplate,
  NoteTemplateUpdateData,
  Permissions,
  ReduxStateType,
  ScribeModel
} from "../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  mikataLevel: boolean;
  noteTemplateId?: string;
  noteTemplate?: NoteTemplate;
  scribeModels: ScribeModel[];
  isModalOpen: boolean;
  loading: boolean;
  deleteLoading: boolean;
  openModal: OpenModal;
  cloneNoteTemplate: (data: CloneNoteTemplateData) => void;
  closeModal: () => void;
  getNoteTemplateDetails: (noteTemplateId: string) => void;
  updateNoteTemplateDetails: (noteTemplate: NoteTemplateUpdateData, noteTemplateId: string) => void;
  deleteNoteTemplate: (data: NoteTemplateDeleteData) => void;
};

type NoteTemplateFormState = {
  templateTypeTag: string;
  tags?: string[];
  name: string;
  description: string;
  content: RawDraftContentState;
  outputLanguage: string;
  associatedScribeModels: number[];
};

export const formValidator = (values: NoteTemplateFormState) => {
  return {
    templateTypeTag: isRequired("Please select a type")(values.templateTypeTag),
    name: isRequired("Please enter a name")(values.name),
    tags: undefined,
    description: isRequired("Please enter a description")(values.description),
    includeAlways: undefined,
    content: undefined,
    outputLanguage: undefined
  };
};

const NoteTemplateDetails = ({
  mikataLevel,
  noteTemplateId,
  scribeModels,
  noteTemplate,
  isModalOpen,
  loading,
  deleteLoading,
  cloneNoteTemplate,
  openModal,
  closeModal,
  getNoteTemplateDetails,
  updateNoteTemplateDetails,
  deleteNoteTemplate
}: PropsType) => {
  const { userId, userType } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const adminCanEdit = usePermissions([Permissions.UPDATE_NOTE_TEMPLATE], false);
  const hasUpdateSelfPermission = usePermissions([Permissions.UPDATE_NOTE_TEMPLATE_SELF], false);
  const hasDeletePermission = usePermissions([Permissions.DELETE_NOTE_TEMPLATE], false);
  const hasDeleteSelfPermission = usePermissions([Permissions.DELETE_NOTE_TEMPLATE_SELF], false);
  const [isFavourited, setIsFavourited] = useState<boolean>(false);
  const [includeAlways, setIncludeAlways] = useState<boolean>(false);

  const providerCanEdit =
    !mikataLevel && hasUpdateSelfPermission && userId === noteTemplate?.userId;

  const providerCanDelete =
    !mikataLevel && hasDeleteSelfPermission && userId === noteTemplate?.userId;

  const originalTemplateType =
    noteTemplate?.tags?.find((item) => Object.values(NoteTemplateTypeTags).includes(item)) || "";

  const initialFormState: NoteTemplateFormState | null = useMemo(() => {
    if (!noteTemplate) return null;

    return {
      templateTypeTag: originalTemplateType,
      tags: noteTemplate?.tags || [],
      description: noteTemplate?.description || "",
      name: noteTemplate?.title || "",
      content: noteTemplate?.content,
      outputLanguage: noteTemplate?.outputLanguage || defaultLanguageOptionValue,
      associatedScribeModels: noteTemplate?.associatedScribeModels || []
    };
  }, [noteTemplate]);

  useEffect(() => {
    if (noteTemplateId && typeof noteTemplateId === "string") {
      getNoteTemplateDetails(noteTemplateId);
    }
  }, [noteTemplateId]);

  const save = (formValues: NoteTemplateFormState) => {
    const updatedTags: string[] = [formValues.templateTypeTag];
    if (noteTemplate?.tags && noteTemplate?.tags?.length > 0) {
      const tagsToIgnore = [
        ...Object.values({ ...NoteTemplateTypeTags, ...NoteTemplatePseudoTags })
      ];
      const filteredTags = noteTemplate?.tags?.filter((tag) => !tagsToIgnore.includes(tag)) || [];
      if (filteredTags?.length > 0) {
        updatedTags.push(...filteredTags);
      }
    }
    const updateData: NoteTemplateUpdateData = {
      title: formValues.name,
      tags: updatedTags,
      description: formValues.description,
      includeAlways,
      isFavourited,
      content: formValues.content,
      outputLanguage: formValues.outputLanguage,
      associatedScribeModels: formValues.associatedScribeModels
    };
    if (noteTemplateId && typeof noteTemplateId === "string") {
      updateNoteTemplateDetails(updateData, noteTemplateId);
    }
  };

  if (!noteTemplate || !initialFormState || loading || deleteLoading) {
    return (
      <FullScreenModal subtitle="Details page" isOpen={isModalOpen} onClose={closeModal}>
        <Loader screen />
      </FullScreenModal>
    );
  }

  const cloneTemplate = () => {
    if (userId && noteTemplateId) {
      cloneNoteTemplate({
        cloneFromTemplateId: Number(noteTemplateId),
        cloneToUserId: userId,
        onSuccess: (clonedTemplateId) => {
          updateQueryString({ noteTemplateId: clonedTemplateId.toString() }, setSearchParams);
        }
      });
    }

    closeModal();
  };

  return (
    <FullScreenModal
      title={noteTemplate?.title}
      subtitle="Details page"
      headerButton={
        userType !== UserTypeConstants.MIKATA_ADMIN &&
        userId &&
        noteTemplate.userId !== userId && (
          <Button type="submit" onClick={cloneTemplate}>
            Customize template
          </Button>
        )
      }
      isOpen={isModalOpen}
      onClose={closeModal}
    >
      <div>
        <Form
          onSubmit={(formState) => {
            save(formState.values as NoteTemplateFormState);
          }}
          initialValues={initialFormState}
          validateFields={(values) => formValidator(values as NoteTemplateFormState)}
        >
          <div className={styles.Wrapper}>
            <NoteTemplateSummaryCard
              noteTemplate={noteTemplate}
              scribeModels={scribeModels}
              isFavourited={isFavourited}
              setIsFavourited={setIsFavourited}
              includeAlways={includeAlways}
              setIncludeAlways={setIncludeAlways}
              mikataLevel={mikataLevel}
              tags={noteTemplate?.tags}
            />

            <Card>
              <NoteTemplateInput
                recordId={noteTemplate.id}
                containerClass={styles.TemplateInputContainer}
                label="Content"
                fieldName="content"
                disabled={!(adminCanEdit || providerCanEdit)}
              />

              <div className={styles.Divider} />
              <ButtonRow>
                {(hasDeletePermission || providerCanDelete) && (
                  <Button
                    id="deleteNoteTemplate"
                    danger
                    inline
                    onClick={() => {
                      openModal(ModalTypes.CONFIRMATION, {
                        title: "Are you sure you want to delete this Note Template?",
                        onConfirmation: () => {
                          deleteNoteTemplate({
                            noteTemplateId: noteTemplateId || ""
                          });
                          closeModal();
                        }
                      });
                    }}
                  >
                    Delete
                  </Button>
                )}

                <Button
                  type="submit"
                  id="saveNoteTemplate"
                  disabled={!(adminCanEdit || providerCanEdit)}
                >
                  Save
                </Button>
              </ButtonRow>
            </Card>
          </div>
        </Form>
      </div>
    </FullScreenModal>
  );
};

const mapStateToProps = ({ noteTemplates }: ReduxStateType) => {
  return {
    noteTemplate: noteTemplates.noteTemplate,
    loading: noteTemplates.noteTemplateDetailsLoading,
    deleteLoading: noteTemplates.noteTemplatesDeleteLoading
  };
};

export default connect(mapStateToProps, {
  openModal: openModalAction,
  getNoteTemplateDetails: getNoteTemplateDetailsAction,
  updateNoteTemplateDetails: updateNoteTemplateDetailsAction,
  cloneNoteTemplate: cloneNoteTemplateAction,
  deleteNoteTemplate: DeleteNoteTemplateAction
})(NoteTemplateDetails);
