import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import moment from "moment";
import Loader from "../../../../ui/Loader";
import Text from "../../../../ui/Text";
import { Form } from "../../../../ui/Input";
import AccountPageForm from "./AccountPageForm";

import {
  updateOrganizationData,
  updateOrganization,
  deleteOrganization,
  fetchServices as fetchServicesAction,
  UploadFileData,
  fetchLogo,
  uploadLogo,
  fetchOrgSettings,
  fetchBillingConfiguration as fetchBillingConfigurationAction,
  updateOrgSettings,
  closeModal,
  openModal,
  OpenModal,
  fetchOrganization,
  BillingActionType
} from "../../../../../actions";
import { isEmail, isRequired, combine } from "../../../../../utils/validators";

import {
  SettingNames,
  OrganizationStates,
  ModalTypes,
  AdminModalTypes,
  EMRTypeConstants,
  OrganizationTypes,
  ChargeOverProducts
} from "../../../../../constants";

import {
  OrganizationData,
  ReduxStateType,
  SignedURLData,
  Setting,
  Option,
  Service
} from "../../../../../types";

type PropsType = {
  organization?: OrganizationData;
  loading: boolean;
  servicesLoading: boolean;
  billingConfigLoading?: boolean;
  settings?: BillingActionType;
  getSignedUrlData?: SignedURLData;
  organizationSettings?: Array<Setting>;
  services: Array<Service>;
  fetchServices: (organizationId: number) => void;
  updateOrganizationDetails: (
    organizationId: number,
    organizationData: updateOrganizationData
  ) => void;
  deleteOrganization: (organizationId: number, onSuccess?: () => void) => void;
  fetchLogoDetails: (fileToDownload: string) => void;
  uploadLogoDetails: (getSignedUrlData: UploadFileData) => void;
  fetchOrganizationSettings: (organizationId: string) => void;
  fetchBillingConfiguration: (organizationId: string) => void;
  updateOrganizationSettings: (organizationId: string, settings: Array<Setting>) => void;
  closeModalAction: () => void;
  openModalAction: OpenModal;
};

export type FormState = {
  primaryColour: string;
  secondaryColour: string;
  email: string;
  logo: string;
  organizationState: string;
  emrType: string;
  phoneWhitelist: string;
  emailWhitelist: string;
  phoneNumber: string;
  verified: boolean;
};

const hexCodeValidator = (hexCode?: string): string | undefined => {
  if (hexCode && hexCode.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i) === null) {
    return "Not a valid hex colour code";
  }
  return undefined;
};

const phoneValidator = isRequired("Phone number is required");
const emailValidator = combine([
  isRequired("Organization email is required"),
  isEmail("Please enter a valid email")
]);

const formValidator = (values: FormState) => {
  return {
    primaryColour: hexCodeValidator(values.primaryColour),
    secondaryColour: hexCodeValidator(values.secondaryColour),
    email: emailValidator(values.email),
    logo: undefined,
    organizationState: undefined,
    emrType: undefined,
    phoneWhitelist: undefined,
    emailWhitelist: undefined,
    features: undefined,
    phoneNumber: phoneValidator(values.phoneNumber),
    verified: undefined
  };
};

const organizationStateOptions = Object.keys(OrganizationStates).map((prefKey) => ({
  label: OrganizationStates[prefKey].name,
  value: OrganizationStates[prefKey].value
}));

const getTestModeSetting = (
  organization: OrganizationData,
  organizationSettings: Array<Setting>
): Setting | undefined => {
  return organizationSettings.find(
    (setting) => setting.settingName === SettingNames.TEST_MODE_ENABLED
  );
};

const getTestModeEmailWhitelistSetting = (
  organization: OrganizationData,
  organizationSettings: Array<Setting>
): Setting | undefined => {
  return organizationSettings.find(
    (setting) => setting.settingName === SettingNames.TEST_MODE_EMAIL_WHITELIST
  );
};

const getTestModePhoneWhitelistSetting = (
  organization: OrganizationData,
  organizationSettings: Array<Setting>
): Setting | undefined => {
  return organizationSettings.find(
    (setting) => setting.settingName === SettingNames.TEST_MODE_PHONE_WHITELIST
  );
};

const getOrganizationState = (
  organization: OrganizationData,
  organizationSettings: Array<Setting>
): string => {
  let testModeEnabled = false;
  const testModeSetting = getTestModeSetting(organization, organizationSettings);

  if (testModeSetting) {
    testModeEnabled =
      testModeSetting.settingValue === true || testModeSetting.settingValue === "true";
  }

  if (organization.setupComplete === false) return OrganizationStates.SETUP.value;
  if (organization.setupComplete && testModeEnabled) return OrganizationStates.TEST.value;
  return OrganizationStates.LIVE.value;
};

const getPhoneWhitelist = (
  organization: OrganizationData,
  organizationSettings: Array<Setting>
): string => {
  const phoneWhitelistSetting = getTestModePhoneWhitelistSetting(
    organization,
    organizationSettings
  );
  if (phoneWhitelistSetting && phoneWhitelistSetting.settingValue) {
    return phoneWhitelistSetting.settingValue.toString();
  }
  return "";
};

const getEmailWhitelist = (
  organization: OrganizationData,
  organizationSettings: Array<Setting>
): string => {
  const emailWhitelistSetting = getTestModeEmailWhitelistSetting(
    organization,
    organizationSettings
  );
  if (emailWhitelistSetting && emailWhitelistSetting.settingValue) {
    return emailWhitelistSetting.settingValue.toString();
  }
  return "";
};

const standardEMRTypeOptions: Option[] = Object.values(EMRTypeConstants).map((emr) => ({
  label: emr.name,
  value: emr.name
}));

const AccountPage = ({
  organization,
  loading,
  servicesLoading,
  billingConfigLoading,
  settings: billingSettings,
  getSignedUrlData,
  organizationSettings,
  services,
  fetchServices,
  updateOrganizationDetails,
  deleteOrganization,
  fetchLogoDetails,
  uploadLogoDetails,
  fetchOrganizationSettings,
  fetchBillingConfiguration,
  updateOrganizationSettings,
  closeModalAction,
  openModalAction
}: PropsType): JSX.Element | null => {
  const { organizationId } = useParams<{
    organizationId: string;
  }>();

  const navigate = useNavigate();
  const logoFileName: string = getSignedUrlData?.filename ? getSignedUrlData?.filename : "";
  const isParent = organization?.type === OrganizationTypes.PARENT;
  const hasParent = !!organization?.parentOrganizationId;

  const emrTypeOptions = !standardEMRTypeOptions.find((option) => {
    return option.label === organization?.emrType?.name;
  })
    ? [
        ...standardEMRTypeOptions,
        // Includes a custom EMR label if current EMR type is not in the list of standard EMR types
        {
          label: `${organization?.emrType?.name} (Custom)`,
          value: `${organization?.emrType?.name}`
        }
      ]
    : standardEMRTypeOptions;

  const onFileChange = (event: { logo: File }, handleFileName: (fileName: string) => void) => {
    const file: File = event.logo as File;

    if (file && organization) {
      uploadLogoDetails({
        organizationId: organization.id,

        file,
        organizationDisplayName: organization.displayName,
        onSuccess: handleFileName
      });
    }
  };
  // Fetch data on load
  useEffect(() => {
    if (organizationId) {
      // fetchOrganizationDetails(organizationId);
      fetchOrganizationSettings(organizationId);
      fetchServices(Number(organizationId));
      fetchBillingConfiguration(organizationId);
    }
  }, [organizationId]);

  // Fetch the logo
  useEffect(() => {
    if (organization && organization.logo) {
      fetchLogoDetails(organization.logo);
    }
  }, [organization]);

  const initialFormState = useMemo(() => {
    if (organization && organizationSettings) {
      const newFormState: FormState = {
        // General section
        phoneWhitelist: getPhoneWhitelist(organization, organizationSettings),
        emailWhitelist: getEmailWhitelist(organization, organizationSettings),
        organizationState: getOrganizationState(organization, organizationSettings),
        emrType: organization?.emrType?.name || "",
        phoneNumber: organization.phoneNumber || "",
        email: organization.email || "",
        // UI Customization section
        primaryColour: organization.primaryColour || "",
        secondaryColour: organization.secondaryColour || "",
        logo: organization.logo || "",
        verified: organization.verified || false
      };

      return newFormState;
    }
    return null;
  }, [organization, organizationSettings, logoFileName]);

  const confirmAndSaveOrganization = async (formValues: FormState) => {
    if (organization && organizationSettings) {
      const emrTypeSelected:
        | undefined
        | { name: string; integrated: boolean; canUpload?: boolean } = Object.values(
        EMRTypeConstants
      ).find((emr) => emr.name === formValues.emrType);
      const orgData = {
        primaryColour: formValues.primaryColour,
        secondaryColour: formValues.secondaryColour,
        logo: formValues.logo,
        email: formValues.email ? formValues.email : "",
        phoneNumber: formValues.phoneNumber ? formValues.phoneNumber : "",
        setupComplete: true,
        emrType: emrTypeSelected ? emrTypeSelected.name : undefined,
        integrated: emrTypeSelected ? emrTypeSelected.integrated : undefined,
        verified: formValues.verified,
        canUpload:
          emrTypeSelected && emrTypeSelected?.canUpload !== undefined
            ? emrTypeSelected.canUpload
            : undefined
      };

      const settingsToSave = [];
      const testModeSetting = getTestModeSetting(organization, organizationSettings) || {
        settingName: SettingNames.TEST_MODE_ENABLED,
        organizationId: organization.id,
        settingValue: false
      };
      const phoneWhitelistSetting = getTestModePhoneWhitelistSetting(
        organization,
        organizationSettings
      ) || {
        settingName: SettingNames.TEST_MODE_PHONE_WHITELIST,
        organizationId: organization.id,
        settingValue: ""
      };
      const emailWhitelistSetting = getTestModeEmailWhitelistSetting(
        organization,
        organizationSettings
      ) || {
        settingName: SettingNames.TEST_MODE_EMAIL_WHITELIST,
        organizationId: organization.id,
        settingValue: ""
      };

      let canSave = true;
      if (formValues.organizationState === OrganizationStates.SETUP.value) {
        orgData.setupComplete = false;
        testModeSetting.settingValue = false;
        settingsToSave.push(testModeSetting);
      } else if (formValues.organizationState === OrganizationStates.TEST.value) {
        const alreadyInTestMode = testModeSetting.settingValue;
        orgData.setupComplete = true;
        testModeSetting.settingValue = true;
        settingsToSave.push(testModeSetting);
        emailWhitelistSetting.settingValue = formValues.emailWhitelist || "";
        settingsToSave.push(emailWhitelistSetting);
        phoneWhitelistSetting.settingValue = formValues.phoneWhitelist || "";
        settingsToSave.push(phoneWhitelistSetting);

        if (!alreadyInTestMode) {
          canSave = false;
          openModalAction(AdminModalTypes.ACTIVATE_TEST_MODE, {
            settings: settingsToSave,
            onSuccess: (confirmedSettings: Array<Setting>) => {
              updateOrganizationDetails(organization.id, orgData);
              updateOrganizationSettings(organization.id.toString(), confirmedSettings);
              closeModalAction();
            }
          });
        }
      } else {
        orgData.setupComplete = true;
        testModeSetting.settingValue = false;
        settingsToSave.push(testModeSetting);
      }

      if (canSave) {
        updateOrganizationDetails(organization.id, orgData);
        updateOrganizationSettings(organization.id.toString(), settingsToSave);
      }
    }
  };

  /**
   * Current State of New Organization Account Details page (As of IHA2345):
   * 1. Can update organization primary and secondary colour
   * 2. Can Upload a organization logo
   * @param formValues: Current form values
   */
  const save = async (formValues: FormState) => {
    const { organizationState } = formValues;
    const isBillingSettingsEmpty = Object.keys(billingSettings || {}).length === 0;
    const isOrganizationStateToBeActive = organizationState === OrganizationStates.LIVE.value;

    if (isBillingSettingsEmpty && isOrganizationStateToBeActive) {
      openModalAction(ModalTypes.CONFIRMATION, {
        title: "Are you sure?",
        warningText:
          "This organization is not linked to a subscription. Are you sure you want to activate this organization anyway?",
        onConfirmation: async () => {
          confirmAndSaveOrganization(formValues);
        }
      });
    } else {
      confirmAndSaveOrganization(formValues);
    }
  };

  const handleDeleteOrganization = async () => {
    openModalAction(ModalTypes.CONFIRMATION, {
      content: (
        <Text>{`You are about to delete the following organization: ${organization?.fullName}`}</Text>
      ),

      onConfirmation: async () => {
        deleteOrganization(Number(organizationId), () => {
          navigate(`/mikata-admin-dashboard/organizations`);
        });
      }
    });
  };

  const canChangeOrganizationState = (): boolean => {
    const isDeactivated = !organization?.setupComplete;
    const hasServices = services.length > 0;
    const hasPhoneNumbers = services.some(
      (service: Service) => service.config?.serviceNumbers?.length > 0
    );

    const testModeSetting = organizationSettings?.find(
      (setting) => setting.settingName === SettingNames.TEST_MODE_ENABLED
    );

    const testModeEnabled = testModeSetting
      ? testModeSetting.settingValue === true || testModeSetting.settingValue === "true"
      : false;

    return (isDeactivated && hasServices && hasPhoneNumbers) || testModeEnabled || !isDeactivated;
  };

  if (!initialFormState || loading || servicesLoading || billingConfigLoading)
    return <Loader screen />;

  return (
    <Form
      onSubmit={(formState) => save(formState.values as FormState)}
      initialValues={initialFormState}
      validateFields={(values) => formValidator(values as FormState)}
    >
      <AccountPageForm
        isParent={isParent}
        hasParent={hasParent}
        emrTypeOptions={emrTypeOptions}
        onFileChange={onFileChange}
        logoFileName={logoFileName}
        handleDeleteOrganization={handleDeleteOrganization}
        organization={organization as OrganizationData}
        loading={loading}
        organizationStateOptions={organizationStateOptions}
        canChangeOrganizationState={canChangeOrganizationState()}
        openModalAction={openModalAction}
      />
    </Form>
  );
};

const mapStateToProps = ({ billing, organizationDetails, services }: ReduxStateType) => {
  return {
    settings: billing.billing,
    billingConfigLoading: billing.billingConfigLoading,
    organization: organizationDetails.data,
    getLogoSignedData: organizationDetails.logoSignedURLData,
    getSignedUrlData: organizationDetails.uploadLogoDetails,
    organizationSettings: organizationDetails.settings,
    services: services.services,
    servicesLoading: services.servicesFetchLoading,
    loading: organizationDetails.organizationLoading
  };
};

export default connect(mapStateToProps, {
  updateOrganizationDetails: updateOrganization,
  deleteOrganization,
  fetchLogoDetails: fetchLogo,
  uploadLogoDetails: uploadLogo,
  fetchServices: fetchServicesAction,
  fetchOrganizationSettings: fetchOrgSettings,
  updateOrganizationSettings: updateOrgSettings,
  fetchBillingConfiguration: fetchBillingConfigurationAction,
  openModalAction: openModal,
  closeModalAction: closeModal,
  fetchOrganization
})(AccountPage);
