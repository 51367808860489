import axios from "axios";

import getToken from "../utils/getToken";

import { addNotification } from "./notifications";

import {
  CREATE_SCRIBE_MODEL,
  FETCH_SCRIBE_MODELS,
  GET_SCRIBE_MODEL,
  UPDATE_SCRIBE_MODEL,
  ROOT_URL
} from "../constants";
import { ActionStatus, Dispatch, ScribeModel, ScribeModelEditData } from "../types";
import { closeModal } from "./modals";

export type FetchScribeModelsAction = {
  type: typeof FETCH_SCRIBE_MODELS;
  status?: ActionStatus;
  payload?: {
    scribeModels: ScribeModel[];
  };
};

export const fetchScribeModels = () => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: FETCH_SCRIBE_MODELS,
    status: ActionStatus.loading
  });

  try {
    const response = await axios.get(`${ROOT_URL}/scribe/models`, config);

    return dispatch({
      type: FETCH_SCRIBE_MODELS,
      status: ActionStatus.success,
      payload: response.data
    });
  } catch (error) {
    dispatch(
      addNotification({
        type: "error",
        title: "Error fetching scribe models",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );
    return dispatch({
      type: FETCH_SCRIBE_MODELS,
      status: ActionStatus.error
    });
  }
};

export type ScribeModelGetData = {
  scribeModel: ScribeModel;
};

export type GetScribeModelAction = {
  type: typeof GET_SCRIBE_MODEL;
  status?: ActionStatus;
  payload?: { scribeModel: ScribeModel };
};

export const getScribeModelDetails =
  (scribeModelId: number, options?: { removeFromState: boolean }) => (dispatch: Dispatch) => {
    if (options?.removeFromState) {
      dispatch({
        type: GET_SCRIBE_MODEL,
        status: ActionStatus.success,
        payload: undefined
      });
      return Promise.resolve();
    }
    const token = getToken();
    const config = {
      headers: { Authorization: `${token}`, "Content-Type": "application/json" }
    };

    dispatch({
      type: GET_SCRIBE_MODEL,
      status: ActionStatus.loading
    });

    return axios
      .get(`${ROOT_URL}/scribe/models/${scribeModelId}`, config)
      .then((response) => {
        return dispatch({
          type: GET_SCRIBE_MODEL,
          status: ActionStatus.success,
          payload: response.data
        });
      })
      .catch((err) => {
        return dispatch({
          type: GET_SCRIBE_MODEL,
          status: ActionStatus.error
        });
      });
  };

export type UpdateScribeModelAction = {
  type: typeof UPDATE_SCRIBE_MODEL;
  status?: ActionStatus;
  payload?: { scribeModel: ScribeModel };
};

export const updateScribeModel =
  (
    scribeModelId: number,
    scribeModel: ScribeModelEditData,
    successMessage: string = "Successfully updated scribe model"
  ) =>
  (dispatch: Dispatch) => {
    const token = getToken();

    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: UPDATE_SCRIBE_MODEL,
      status: ActionStatus.loading
    });

    return axios
      .patch(`${ROOT_URL}/scribe/models/${scribeModelId}`, scribeModel, config)
      .then((response) => {
        closeModal()(dispatch);

        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: successMessage,
            autoDismiss: true
          })
        );
        return dispatch({
          type: UPDATE_SCRIBE_MODEL,
          status: ActionStatus.success,
          payload: response.data
        });
      })
      .catch((err) => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to update scribe model",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
        return dispatch({
          type: UPDATE_SCRIBE_MODEL,
          status: ActionStatus.error
        });
      });
  };

export type CreateScribeModelAction = {
  type: typeof CREATE_SCRIBE_MODEL;
  status?: ActionStatus;
  payload?: { scribeModel: ScribeModel };
};

export const createScribeModel =
  (
    createDraftOfModelId: number | undefined,
    data: ScribeModelEditData | undefined,
    successMessage: string = "Successfully created scribe model"
  ) =>
  (dispatch: Dispatch) => {
    const token = getToken();

    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: CREATE_SCRIBE_MODEL,
      status: ActionStatus.loading
    });

    const createData = createDraftOfModelId ? { createDraftOfModelId } : data;

    return axios
      .post(`${ROOT_URL}/scribe/models/`, createData, config)
      .then((response) => {
        closeModal()(dispatch);

        if (!response?.data?.success) {
          dispatch(
            addNotification({
              type: "error",
              title: "Failed to create scribe model",
              subtitle: response?.data?.message || "Please try again",
              autoDismiss: true
            })
          );
          return dispatch({
            type: CREATE_SCRIBE_MODEL,
            status: ActionStatus.error
          });
        }

        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: successMessage,
            autoDismiss: true
          })
        );
        return dispatch({
          type: CREATE_SCRIBE_MODEL,
          status: ActionStatus.success,
          payload: response.data
        });
      })
      .catch((err) => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to create scribe model",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
        return dispatch({
          type: CREATE_SCRIBE_MODEL,
          status: ActionStatus.error
        });
      });
  };
