import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";

import Loader from "../../../../ui/Loader";
import TableGrid from "../../../../ui/TableGrid";
import Heading from "../../../../ui/Heading";
import Button, { ButtonRow } from "../../../../ui/Button";

import {
  fetchScribeModels as fetchScribeModelsAction,
  openModal as openModalAction,
  OpenModal
} from "../../../../../actions";

import { PermissionsGuard, usePermissions } from "../../../../../hooks/usePermissions";

import { ScribeModel, ReduxStateType, Permissions } from "../../../../../types";
import { AdminModalTypes } from "../../../../../constants";

const headers = [
  { colName: "id", content: "ID" },
  { colName: "name", content: "Name" },
  { colName: "description", content: "Description" },
  { colName: "version", content: "Version" },
  { colName: "type", content: "Type" },
  { colName: "published", content: "Published" },
  { colName: "createdAtFormatted", content: "Created" },
  { colName: "globalTemplatesCount", content: "Mikata templates" },
  { colName: "customTemplatesCount", content: "Custom templates" }
];

type PropsType = {
  fetchScribeModels: () => void;
  openModal: OpenModal;
  scribeModelsLoading: boolean;
  scribeModels: ScribeModel[];
};

const ScribeModelsTable = ({
  fetchScribeModels,
  openModal,
  scribeModels,
  scribeModelsLoading
}: PropsType): JSX.Element => {
  const userCanGetModel = usePermissions([Permissions.GET_SCRIBE_MODEL_DETAILS], false);
  useEffect(() => {
    fetchScribeModels();
  }, []);
  if (scribeModelsLoading) return <Loader screen />;
  const scribeModelRows = scribeModels.map((scribeModel) => ({
    ...scribeModel,
    globalTemplatesCount: scribeModel.noteTemplates?.globalTemplates?.length || "0",
    customTemplatesCount: scribeModel.noteTemplates?.customTemplates?.length || "0",
    published: scribeModel.published ? "Yes" : "No",
    createdAtFormatted: moment(scribeModel.created_at).format("YYYY-MM-DD"),
    __onRowClick: (): void => {
      if (userCanGetModel) {
        openModal(AdminModalTypes.EDIT_SCRIBE_MODEL, {
          scribeModelId: scribeModel.id,
          editingExistingModel: true,
          onSuccess: fetchScribeModels
        });
      }
    }
  }));
  return (
    <>
      <Heading size="L">Mikata Scribe Models</Heading>
      <PermissionsGuard
        requiredPermissions={[Permissions.CREATE_SCRIBE_MODEL]}
        requiresVerifiedOrg={false}
      >
        <ButtonRow>
          <Button
            onClick={() =>
              openModal(AdminModalTypes.EDIT_SCRIBE_MODEL, { editingExistingModel: false })
            }
          >
            Create New Model
          </Button>
        </ButtonRow>
      </PermissionsGuard>
      <br />
      {scribeModelRows.length > 0 ? (
        <TableGrid
          id="noteTemplatesTable"
          headers={headers}
          rows={scribeModelRows}
          showRowFocus
          maxPageRows={15}
        />
      ) : (
        <Loader screen />
      )}
    </>
  );
};

const mapStateToProps = ({ scribeModels }: ReduxStateType) => {
  return {
    scribeModels: scribeModels.scribeModels,
    scribeModelsLoading: scribeModels.scribeModelsLoading
  };
};

export default connect(mapStateToProps, {
  fetchScribeModels: fetchScribeModelsAction,
  openModal: openModalAction
})(ScribeModelsTable);
