import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import Button from "../ui/Button";
import Text from "../ui/Text";
import Tooltip from "../ui/Tooltip";
import {
  Journey,
  MikataSimple,
  Upload,
  Calendar,
  Inbox,
  Info,
  LoudSpeaker,
  Analytics,
  Document
} from "../ui/Icon";
import AccountDropdown from "./AccountDropdown";
import ClinicAccountDropdown from "./ClinicAccountDropdown";

import DemoDaysRemaining from "./DemoDaysRemaining";

import { OrganizationContext } from "../providers/OrganizationProvider";
import { UserContext } from "../providers/UserProvider";

import { PermissionsGuard } from "../../hooks/usePermissions";

import { openModal as openModalAction, OpenModal } from "../../actions";

import { UserTypeConstants, ModalTypes, FEATURES } from "../../constants";
import { Permissions, ReduxStateType } from "../../types";

import styles from "./index.module.scss";
import { ResponsiveHide } from "../ui/Responsive";

type PropsType = {
  children: React.ReactNode;
  openModal: OpenModal;
  organizationLoading: boolean;
};

const NavBar = ({ children, openModal, organizationLoading }: PropsType) => {
  const organization = useContext(OrganizationContext);
  const user = useContext(UserContext);
  const isAdmin = user.userType === UserTypeConstants.MIKATA_ADMIN;
  const canUpload =
    organization && !organization.integrated && organization.canUpload && organization.verified;
  const hasAnalyticsFeature = organization?.features?.includes(FEATURES.ANALYTICS.value);
  const hasAppointmentRequestsFeature =
    organization && organization?.features?.includes(FEATURES.APPOINTMENT_REQUESTS.value);
  const hasInboxFeature = organization && organization?.features?.includes(FEATURES.INBOX.value);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.TopNav}>
        <div className={styles.Logo}>
          <MikataSimple size={40} />
        </div>
        <div className={styles.Links}>
          {isAdmin && (
            <NavLink
              to="/mikata-admin-dashboard/organizations"
              className={({ isActive }) => (isActive ? styles.LinkActive : styles.Link)}
            >
              <Text className={styles.LinkText} size="S" component="span" bold>
                Organizations
              </Text>
            </NavLink>
          )}
          {isAdmin && (
            <NavLink
              to="/mikata-admin-dashboard/chats"
              className={({ isActive }) => (isActive ? styles.LinkActive : styles.Link)}
            >
              <Text className={styles.LinkText} size="S" component="span" bold>
                Chats
              </Text>
            </NavLink>
          )}

          {isAdmin && (
            <NavLink
              to="/mikata-admin-dashboard/scribe-templates"
              className={({ isActive }) => (isActive ? styles.LinkActive : styles.Link)}
            >
              <Text className={styles.LinkText} size="S" component="span" bold>
                Scribe Templates
              </Text>
            </NavLink>
          )}

          {isAdmin && (
            <NavLink
              to="/mikata-admin-dashboard/scribe-models"
              className={({ isActive }) => (isActive ? styles.LinkActive : styles.Link)}
            >
              <Text className={styles.LinkText} size="S" component="span" bold>
                Scribe Models
              </Text>
            </NavLink>
          )}

          {isAdmin && (
            <PermissionsGuard
              requiresVerifiedOrg={false}
              requiredPermissions={[Permissions.FIND_MIKATA_USERS]}
            >
              <NavLink
                to="/mikata-admin-dashboard/users"
                className={({ isActive }) => (isActive ? styles.LinkActive : styles.Link)}
              >
                <Text className={styles.LinkText} size="S" component="span" bold>
                  Users
                </Text>
              </NavLink>
            </PermissionsGuard>
          )}
        </div>
        {!organizationLoading && <DemoDaysRemaining />}
        {isAdmin && organization && (
          <div className={styles.OrganizationName}>
            <Text size="M" bold className={styles.OrganizationNameText}>
              {organization ? organization.fullName : ""}
            </Text>
            {!organization.verified && (
              <Tooltip
                icon={
                  <div className={styles.OrganizationNameIcon}>
                    <Info size={15} />
                  </div>
                }
                position="bottomLeft"
              >
                Account not verified
              </Tooltip>
            )}
          </div>
        )}

        {canUpload && (
          <PermissionsGuard requiredPermissions={[Permissions.UPLOAD_DAILY]}>
            <Button
              id="uploadButton"
              className={styles.Upload}
              onClick={() => openModal(ModalTypes.UPLOAD_APPOINTMENTS)}
            >
              {organization?.setupComplete ? "Upload" : "Upload Sample"}
              <Upload />
            </Button>
          </PermissionsGuard>
        )}
        {isAdmin && (
          <div className={styles.Account}>
            <AccountDropdown logout={user.logout} />
          </div>
        )}
      </div>
      <div className={styles.Container}>
        {!isAdmin && (
          <div id="sideNav" className={styles.SideNav}>
            <PermissionsGuard
              requiresVerifiedOrg={false}
              requiredPermissions={[Permissions.GET_APPTS]}
            >
              <Tooltip
                className={styles.SideNavTooltip}
                contentClassName={styles.SideNavTooltipContent}
                size="S"
                icon={
                  <NavLink to="/appointments" className={styles.SideNavButton}>
                    <Calendar />
                  </NavLink>
                }
              >
                Appointments
              </Tooltip>
            </PermissionsGuard>

            <PermissionsGuard requiredPermissions={[Permissions.FIND_AUTOMATIONS]}>
              <ResponsiveHide hideOnMobile>
                <Tooltip
                  className={styles.SideNavTooltip}
                  contentClassName={styles.SideNavTooltipContent}
                  size="S"
                  icon={
                    <NavLink to="/journeys" className={styles.SideNavButton}>
                      <Journey />
                    </NavLink>
                  }
                >
                  Appointment Journeys
                </Tooltip>
              </ResponsiveHide>
            </PermissionsGuard>
            {hasInboxFeature ? (
              <PermissionsGuard requiredPermissions={[Permissions.FIND_TASKS]}>
                <ResponsiveHide hideOnMobile>
                  <Tooltip
                    className={styles.SideNavTooltip}
                    contentClassName={styles.SideNavTooltipContent}
                    size="S"
                    icon={
                      <NavLink to="/inbox" className={styles.SideNavButton}>
                        <Inbox />
                      </NavLink>
                    }
                  >
                    Inbox
                  </Tooltip>
                </ResponsiveHide>
              </PermissionsGuard>
            ) : (
              hasAppointmentRequestsFeature && (
                <PermissionsGuard requiredPermissions={[Permissions.FIND_APPT_REQUESTS]}>
                  <ResponsiveHide hideOnMobile>
                    <Tooltip
                      className={styles.SideNavTooltip}
                      contentClassName={styles.SideNavTooltipContent}
                      size="S"
                      icon={
                        <NavLink to="/requests" className={styles.SideNavButton}>
                          <Inbox />
                        </NavLink>
                      }
                    >
                      Appointment Requests
                    </Tooltip>
                  </ResponsiveHide>
                </PermissionsGuard>
              )
            )}
            <PermissionsGuard requiredPermissions={[Permissions.FIND_CAMPAIGN]}>
              <ResponsiveHide hideOnMobile>
                <Tooltip
                  className={styles.SideNavTooltip}
                  contentClassName={styles.SideNavTooltipContent}
                  size="S"
                  icon={
                    <NavLink to="/campaigns" className={styles.SideNavButton}>
                      <LoudSpeaker />
                    </NavLink>
                  }
                >
                  Campaigns
                </Tooltip>
              </ResponsiveHide>
            </PermissionsGuard>
            {hasAnalyticsFeature && (
              <PermissionsGuard requiredPermissions={[Permissions.VIEW_ANALYTICS]}>
                <ResponsiveHide hideOnMobile>
                  <Tooltip
                    className={styles.SideNavTooltip}
                    contentClassName={styles.SideNavTooltipContent}
                    size="S"
                    icon={
                      <NavLink to="/analytics" className={styles.SideNavButton}>
                        <Analytics />
                      </NavLink>
                    }
                  >
                    Analytics
                  </Tooltip>
                </ResponsiveHide>
              </PermissionsGuard>
            )}
            <PermissionsGuard requiredPermissions={[Permissions.FIND_DOCUMENTS]}>
              <ResponsiveHide hideOnMobile>
                <Tooltip
                  className={styles.SideNavTooltip}
                  contentClassName={styles.SideNavTooltipContent}
                  size="S"
                  icon={
                    <NavLink to="/documents" className={styles.SideNavButton}>
                      <Document />
                    </NavLink>
                  }
                >
                  Documents
                </Tooltip>
              </ResponsiveHide>
            </PermissionsGuard>

            <ClinicAccountDropdown organizationId={organization?.id} logout={user.logout} />
          </div>
        )}
        <div className={styles.Scroll}>
          <div className={styles.Content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ organizationDetails }: ReduxStateType) => {
  return {
    organizationLoading: organizationDetails.organizationLoading
  };
};

export default connect(mapStateToProps, { openModal: openModalAction })(NavBar);
