import React, { useContext } from "react";
import { connect } from "react-redux";
import { NavLink, Routes, Route } from "react-router-dom";

import Heading from "../../../../ui/Heading";
import Text from "../../../../ui/Text";
import StaffSettingsDataDropdown from "./StaffSettingsDataDropdown";
import ScribeTemplatesSettingDropdown from "./ScribeTemplateSettingDropdown";

import { openModal as openModalAction } from "../../../../../actions";
import { PermissionsGuard } from "../../../../../hooks/usePermissions";

import { StaffSettingsPageTabs, FEATURES } from "../../../../../constants";

import { Permissions, ReduxStateType } from "../../../../../types";
import { UserContext } from "../../../../providers/UserProvider";

import styles from "./index.module.scss";

const { BILLING, GENERAL, USERS, APPOINTMENT_REQUESTS_CONFIG } = StaffSettingsPageTabs;

type PropsType = {
  organizationFeatures?: string[];
};

const StaffSettingsNavBar = ({ organizationFeatures }: PropsType) => {
  const { settings } = useContext(UserContext);
  const hasScribeFeature = ["active", "trial"].includes(settings?.features?.scribe?.status || "");
  // expectation for below flag is the setting would not have this key if scribe was never activated
  const hasScribeSettings = settings?.features?.scribe || false;
  return (
    <Routes>
      <Route
        path={BILLING.path}
        element={
          <Heading size="XL" component="h1">
            Billing
          </Heading>
        }
      />
      <Route
        index
        element={
          <div className={styles.NavBar}>
            <div className={styles.Header} id="header">
              <Heading className={styles.SettingsTitle} size="XL" component="h1">
                Settings
              </Heading>
              <div className={styles.Tabs}>
                {hasScribeSettings && (
                  <PermissionsGuard
                    requiresVerifiedOrg={false}
                    requiredPermissions={[Permissions.UPDATE_USER_SELF]}
                  >
                    <NavLink
                      key={GENERAL.label}
                      to={GENERAL.path}
                      className={({ isActive }) => (isActive ? styles.LinkActive : styles.Link)}
                    >
                      <Text className={styles.LinkText} size="S" component="span" bold>
                        {GENERAL.label}
                      </Text>
                    </NavLink>
                  </PermissionsGuard>
                )}
                {hasScribeFeature && (
                  <PermissionsGuard
                    requiredPermissions={[Permissions.FIND_NOTE_TEMPLATES]}
                    requiresVerifiedOrg={false}
                  >
                    <div className={styles.Link}>
                      <ScribeTemplatesSettingDropdown />
                    </div>
                  </PermissionsGuard>
                )}
                <PermissionsGuard
                  requiredPermissions={[
                    Permissions.CREATE_STAFF,
                    Permissions.UPDATE_USER_OTHER_LEVEL_20
                  ]}
                >
                  <NavLink
                    key={USERS.label}
                    to={USERS.path}
                    className={({ isActive }) => (isActive ? styles.LinkActive : styles.Link)}
                  >
                    <Text className={styles.LinkText} size="S" component="span" bold>
                      {USERS.label}
                    </Text>
                  </NavLink>
                </PermissionsGuard>
                {organizationFeatures &&
                  organizationFeatures.includes(FEATURES.APPOINTMENT_REQUESTS.value) && (
                    <NavLink
                      key={APPOINTMENT_REQUESTS_CONFIG.label}
                      to={APPOINTMENT_REQUESTS_CONFIG.path}
                      className={({ isActive }) => (isActive ? styles.LinkActive : styles.Link)}
                    >
                      <Text className={styles.LinkText} size="S" component="span" bold>
                        {APPOINTMENT_REQUESTS_CONFIG.label}
                      </Text>
                    </NavLink>
                  )}
                <div className={styles.Link}>
                  <StaffSettingsDataDropdown />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </Routes>
  );
};

const mapStateToProps = ({ organizationDetails }: ReduxStateType) => {
  return { organizationFeatures: organizationDetails.data?.features };
};

export default connect(mapStateToProps, {
  openModal: openModalAction
})(StaffSettingsNavBar);
