import React from "react";

export * from "./automations";
export * from "./redux";
export * from "./permissions";
export * from "./chat";
export * from "./contentJSON";
export * from "./recordingSession";
export * from "./billing";
export * from "./sharedComponentTypes";
export * from "./scribe";
export * from "./tasks";
export * from "./users";

export type AuthToken = {
  userType?: "mikata admin" | "patient" | "staff";
};

export type CustomChangeEvent = {
  [fieldName: string]: string | undefined;
};

// Form types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InputValue = any;
export type Validate = (value: InputValue) => string | undefined;
export type CustomOnChangeHandler = (event: { [fieldName: string]: InputValue }) => void;

export type InputPropsType = {
  fieldName: string;
  label?: string;
  info?: React.ReactNode;
  infoStatus?: "info" | "warning" | "error";
  placeholder?: string;
  disabled?: boolean;
  customOnChange?: CustomOnChangeHandler;
  validate?: Validate;
  warningMessage?: string;
  showField?: boolean;
};

export type Option = { label: string; value: string | number };

export type OptionWithIcon = { label: string; value: string | number; icon?: React.ReactNode };

export type ActivityTimerValue = {
  pageOpenDurationMilliseconds: number;
  mikaActiveDurationMilliseconds: number;
  providerActiveDurationMilliseconds: number;
};

export enum ScribeToolMode {
  CHAT_TRANSCRIPTS = "chatTranscripts",
  CHAT_SUMMARIES = "chatSummaries",
  SENT_MESSAGES = "sentMessages",
  TIMERS = "timers",
  AUDIO_TRANSCRIPT = "audioTranscript",
  DO_MORE_WITH_MIKA = "doMoreWithMika"
}
