import React, { useRef, useCallback, useState, useEffect } from "react";
import { io, Socket } from "socket.io-client";
import uniqBy from "lodash/uniqBy";
import difference from "lodash/difference";
import debounce from "lodash/debounce";

import { getInputLanguage } from "../../hooks/useAudioSettings";

import { defaultLanguageOptionValue } from "../../constants";

import {
  ActivityTimerValue,
  AudioTranscript,
  Insight,
  NotificationData,
  PatientConsentStatus,
  RecordingSessionStatus,
  RecordingState,
  SocketMessageType,
  UserInteractionHistory,
  MessagePayload,
  RawAudioChunk,
  EventTypes
} from "../../types";

type EncounterSocketContextType = {
  recording: RecordingState | null;
  connected?: boolean;
  failedConnectionAttempts: number;
  insights: Insight[];
  audioTranscripts: AudioTranscript[];
  timer: number;
  activityTimerValues: ActivityTimerValue;
  sendMessage: (messageType: SocketMessageType, messagePayload?: MessagePayload) => void;
  sendAudioData: (payload: {
    rawAudioChunk: RawAudioChunk;
    sequenceNumber: number;
    durationSeconds: number;
    sentAtEpoch: number;
  }) => void;
  endSession: () => void;
  pauseSession: (pausedBoolean: boolean) => void;
  sentScribeInvite: boolean | null;
  userInteractionHistory: UserInteractionHistory;
  setUserInteractionHistory: React.Dispatch<React.SetStateAction<UserInteractionHistory>>;
  notifications: NotificationData[];
  clearScribeNotifications: (notification: NotificationData[]) => void;
};

type ProviderEncounterDetails = { sentScribeInvite: boolean | null };

type PropsType = {
  appointmentId: string;
  children: React.ReactNode;
};

const initialEncounterSocketContext: EncounterSocketContextType = {
  recording: null,
  connected: false,
  insights: [],
  audioTranscripts: [],
  timer: 0,
  activityTimerValues: {
    pageOpenDurationMilliseconds: 0,
    mikaActiveDurationMilliseconds: 0,
    providerActiveDurationMilliseconds: 0
  },
  sendMessage: () => console.error("EncounterSocket not initialized"),
  endSession: () => console.error("EncounterSocket not initialized"),
  pauseSession: () => console.error("EncounterSocket not initialized"),
  sendAudioData: () => true,
  failedConnectionAttempts: 0,
  sentScribeInvite: null,
  userInteractionHistory: {},
  setUserInteractionHistory: () => true,
  notifications: [],
  clearScribeNotifications: () => console.error("")
};

type StoppedSession = { appointmentId: string; stoppedAt: number };
type PausedSession = { appointmentId: string; pausedAt: number };

const getLocalStopped = (appointmentId: string) => {
  if (!appointmentId) return false;

  const localStoppedSessionsString = localStorage.getItem("locallyStoppedSessions");
  const localStoppedSessions: StoppedSession[] = localStoppedSessionsString
    ? JSON.parse(localStoppedSessionsString)
    : [];

  return Boolean(
    localStoppedSessions.find((stoppedSession) => stoppedSession.appointmentId === appointmentId)
  );
};

const getLocalPaused = (appointmentId: string) => {
  if (!appointmentId) return false;

  const localPausedSessionsString = localStorage.getItem("locallyPausedSessions");
  const localPausedSessions: PausedSession[] = localPausedSessionsString
    ? JSON.parse(localPausedSessionsString)
    : [];

  return Boolean(
    localPausedSessions.find((pausedSession) => pausedSession.appointmentId === appointmentId)
  );
};

const setLocalStopped = (appointmentId: string, stoppedBoolean: boolean) => {
  const localStoppedSessionsString = localStorage.getItem("locallyStoppedSessions");
  const currentStoppedSessions: StoppedSession[] = localStoppedSessionsString
    ? JSON.parse(localStoppedSessionsString)
    : [];

  let updatedStoppedSessions;
  if (!stoppedBoolean) {
    // Clear localStopped
    updatedStoppedSessions = currentStoppedSessions.filter(
      (stoppedSession) => stoppedSession.appointmentId !== appointmentId
    );
  } else {
    const now: number = Date.now();
    updatedStoppedSessions = [
      ...currentStoppedSessions,
      // Add new stopped session
      { appointmentId, stoppedAt: now }
    ].filter(
      // remove records older than two days
      (stoppedSession) => {
        const millisecondsSinceStopped = stoppedSession ? now - stoppedSession.stoppedAt : 0;

        return millisecondsSinceStopped < 1000 * 60 * 60 * 48;
      }
    );
  }

  return localStorage.setItem("locallyStoppedSessions", JSON.stringify(updatedStoppedSessions));
};

const setLocalPaused = (appointmentId: string, pausedBoolean: boolean) => {
  const localPausedSessionsString = localStorage.getItem("locallyPausedSessions");
  const currentPausedSessions: StoppedSession[] = localPausedSessionsString
    ? JSON.parse(localPausedSessionsString)
    : [];

  const now: number = Date.now();

  let updatedPausedSessions;

  if (!pausedBoolean) {
    // Remove appt from locally paused if unpausing
    updatedPausedSessions = currentPausedSessions.filter(
      (pausedSession) => pausedSession.appointmentId !== appointmentId
    );
  } else {
    // Add new paused session if pausing
    updatedPausedSessions = [...currentPausedSessions, { appointmentId, stoppedAt: now }].filter(
      // remove records older than two days
      (pausedSession) => {
        const millisecondsSincePaused = pausedSession ? now - pausedSession.stoppedAt : 0;
        return millisecondsSincePaused < 1000 * 60 * 60 * 48;
      }
    );
  }

  return localStorage.setItem("locallyPausedSessions", JSON.stringify(updatedPausedSessions));
};

export const EncounterSocketContext = React.createContext(initialEncounterSocketContext);

const EncounterSocketProvider = ({ appointmentId, children }: PropsType) => {
  const socket = useRef<Socket | null>(null);
  const inputLanguage = getInputLanguage();

  const [recording, setRecording] = useState<RecordingState | null>(null);
  const recordingStatusRef = useRef<RecordingSessionStatus | null>(recording?.status || null);

  const [sentScribeInvite, setSentScribeInvite] = useState<boolean | null>(null);
  const [failedConnectionAttempts, setFailedConnectionAttempts] = useState<number>(0);

  const [insights, setInsights] = useState<Insight[]>([]);
  const [audioTranscripts, setAudioTranscripts] = useState<AudioTranscript[]>([]);

  const [activityTimerValues, setActivityTimerValues] = useState<ActivityTimerValue>({
    pageOpenDurationMilliseconds: 0,
    mikaActiveDurationMilliseconds: 0,
    providerActiveDurationMilliseconds: 0
  });
  const [timer, setTimer] = useState(0);

  const timerRef = useRef(timer);

  useEffect(() => {
    timerRef.current = timer;
  }, [timer]);
  const [userInteractionHistory, setUserInteractionHistory] = useState<UserInteractionHistory>({});

  const [notifications, setNotifications] = useState<NotificationData[]>([]);

  const audioDataSequenceNumberRef = useRef(1);

  const WEBSOCKET_URL = process.env.REACT_APP_RECORDING_WEBSOCKET_URL || "ws://localhost:8080";

  const recordingStateTransitionMachine = (
    currentRecording: RecordingState | null,
    proposedChanges: RecordingState | { status: RecordingSessionStatus } | null
  ): RecordingState | null => {
    // Clear recordingState if current recording does NOT match appointmentId context
    if (
      !appointmentId ||
      (currentRecording && currentRecording.appointmentId !== parseInt(appointmentId, 10))
    ) {
      return null;
    }

    // Initialize new recordingState
    if (!currentRecording) {
      return {
        appointmentId: parseInt(appointmentId, 10),
        status: RecordingSessionStatus.PENDING,
        startedAt: null,
        providerReady: false,
        patientConsentStatus: PatientConsentStatus.PENDING,
        sessionCompleted: false,
        patientSignature: "",
        ...proposedChanges
      };
    }

    // Sanitize recording state transition
    const proposedNextRecording = { ...currentRecording, ...proposedChanges };
    const localStopped = getLocalStopped(appointmentId);
    const localPaused = getLocalPaused(appointmentId);

    let proposedNextStatus = proposedNextRecording.status;
    if (localStopped && proposedNextStatus !== RecordingSessionStatus.COMPLETE) {
      // Only allow transition from localStopped to complete
      proposedNextStatus = RecordingSessionStatus.LOCAL_STOPPED;
    }
    if (
      localPaused &&
      !(
        proposedNextStatus === RecordingSessionStatus.PAUSED ||
        proposedNextStatus === RecordingSessionStatus.COMPLETE
      )
    ) {
      // Only allow transition from localPaused to paused OR complete
      proposedNextStatus = RecordingSessionStatus.LOCAL_PAUSED;
    }
    if (
      proposedNextStatus === RecordingSessionStatus.LOCAL_PAUSED &&
      currentRecording.status === RecordingSessionStatus.PAUSED
    ) {
      // Prevent transitions from paused to localPaused
      proposedNextStatus = RecordingSessionStatus.PAUSED;
    }
    const nextSessionCompleted = localStopped ? true : proposedNextRecording.sessionCompleted;

    const nextRecording = {
      ...proposedNextRecording,
      sessionCompleted: nextSessionCompleted,
      status: proposedNextStatus
    };

    return nextRecording;
  };

  const sendMessage = useCallback(
    (messageType: SocketMessageType, messagePayload?: MessagePayload) => {
      if (socket.current && socket.current.connected && appointmentId) {
        const token = localStorage.getItem("jwt");
        socket.current.auth = { token };

        socket.current.emit(messageType, {
          appointmentId,
          ...messagePayload
        });
      }
    },
    [socket.current?.connected, appointmentId]
  );

  const sendAudioData = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ rawAudioChunk, sequenceNumber, durationSeconds, sentAtEpoch }: Record<string, any>) => {
      // Only sendAudioData if there is a matching appointmentId context
      if (
        socket.current?.connected &&
        recording?.appointmentId === parseInt(appointmentId, 10) &&
        recording?.status === RecordingSessionStatus.RECORDING
      ) {
        const messagePayload = {
          appointmentId,
          rawAudioChunk,
          sequenceNumber,
          durationSeconds,
          sentAtEpoch,
          inputLanguage
        };

        sendMessage(SocketMessageType.audioData, messagePayload);

        audioDataSequenceNumberRef.current += 1;
      }
    },
    [
      appointmentId,
      socket.current?.connected,
      recording?.appointmentId,
      recording?.status,
      inputLanguage
    ]
  );

  const endSession = useCallback(() => {
    setLocalStopped(appointmentId, true);

    if (socket.current && socket.current.connected && appointmentId) {
      // Connected
      const token = localStorage.getItem("jwt");
      socket.current.auth = { token };

      socket.current.emit(SocketMessageType.endSession, {
        appointmentId
      });

      socket.current.emit(SocketMessageType.activityTimers, { appointmentId });
    } else if (appointmentId) {
      // Not Connected
      setRecording((currentRecording) => {
        return recordingStateTransitionMachine(currentRecording, {
          status: RecordingSessionStatus.LOCAL_STOPPED,
          sessionCompleted: true
        });
      });
    }
  }, [socket.current?.connected, appointmentId]);

  const pauseSession = useCallback(
    (pausedBoolean: boolean) => {
      setLocalPaused(appointmentId, pausedBoolean);

      if (socket.current && socket.current.connected && appointmentId) {
        // Connected
        const token = localStorage.getItem("jwt");
        socket.current.auth = { token };

        socket.current.emit(SocketMessageType.pauseSession, {
          appointmentId,
          pausedBoolean,
          inputLanguage: inputLanguage || defaultLanguageOptionValue
        });

        socket.current.emit(SocketMessageType.activityTimers, { appointmentId });
      } else {
        // Not Connected
        const newStatus: RecordingSessionStatus = pausedBoolean
          ? RecordingSessionStatus.LOCAL_PAUSED
          : RecordingSessionStatus.RECORDING;
        setRecording((currentRecording) => {
          return recordingStateTransitionMachine(currentRecording, {
            status: newStatus
          });
        });
      }
    },
    [socket.current?.connected, appointmentId]
  );

  const clearScribeNotifications = (notificationsToClear: NotificationData[]) => {
    const hasNotificationMatch = (currentNotif: NotificationData, notif: NotificationData) => {
      const { context: currentNotifContext, type: currentNotifType } = currentNotif;
      const { context: notifContext, type: notifType } = notif;

      const apptIdMatches = notifContext.appointmentId === currentNotifContext.appointmentId;
      const insightCategoriesMatch =
        !currentNotifContext.insightCategories ||
        difference(notifContext.insightCategories, currentNotifContext.insightCategories).length ===
          0;
      const notificationTypeMatches = notifType === currentNotifType;

      return apptIdMatches && insightCategoriesMatch && notificationTypeMatches;
    };

    setNotifications((currentNotifications: NotificationData[]) =>
      currentNotifications.filter((currentNotif: NotificationData) => {
        return !notificationsToClear.some((notif: NotificationData) =>
          hasNotificationMatch(currentNotif, notif)
        );
      })
    );
  };

  const registerAuthenticatedSocketListeners = (socketConnection: Socket) => {
    // Log all incoming messages
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    socketConnection.onAny((messageType: string, ...args: any) => {
      console.log("MESSAGE EVENT: ", messageType, args);
    });

    socketConnection.on("connect", () => {
      const localStopped = getLocalStopped(appointmentId);
      const localPaused = getLocalPaused(appointmentId);
      if (localStopped) {
        sendMessage(SocketMessageType.endSession);
      } else if (localPaused) {
        sendMessage(SocketMessageType.pauseSession, { pausedBoolean: true });
      } else {
        sendMessage(SocketMessageType.recordingState);

        // wait a short while to allow the socket to be added to the correct room server-side
        setTimeout(() => {
          sendMessage(SocketMessageType.providerDetails);
          sendMessage(SocketMessageType.activityTimers);
        }, 500);
      }
    });

    socketConnection.on(SocketMessageType.recordingState, (recordingState: RecordingState) => {
      const localStopped = getLocalStopped(appointmentId);
      const localPaused = getLocalPaused(appointmentId);

      //  request endSession if locally stopped and not completed
      if (localStopped && recordingState?.status !== RecordingSessionStatus.COMPLETE) {
        sendMessage(SocketMessageType.endSession);
      }

      //  request pauseSession if locally paused and not paused or completed
      if (
        localPaused &&
        recordingState?.status !== RecordingSessionStatus.PAUSED &&
        recordingState?.status !== RecordingSessionStatus.COMPLETE
      ) {
        sendMessage(SocketMessageType.pauseSession, { pausedBoolean: true });
      }

      setRecording((currentRecording) => {
        const nextRecordingState = recordingStateTransitionMachine(currentRecording, {
          ...recordingState
        });

        // clear localStopped upon session completion message;
        if (localStopped && nextRecordingState?.status === RecordingSessionStatus.COMPLETE) {
          setLocalStopped(appointmentId, false);
        }

        // clear localPaused upon session completion message OR paused message;
        if (
          localPaused &&
          (nextRecordingState?.status === RecordingSessionStatus.PAUSED ||
            nextRecordingState?.status === RecordingSessionStatus.COMPLETE)
        ) {
          setLocalPaused(appointmentId, false);
        }

        return nextRecordingState;
      });
    });

    socketConnection.on(SocketMessageType.providerDetails, (data: ProviderEncounterDetails) => {
      setSentScribeInvite(data.sentScribeInvite);
    });

    socketConnection.on(SocketMessageType.insights, (newInsights: Insight[]) => {
      setInsights((currentInsights) => {
        return uniqBy([...currentInsights, ...newInsights], "id");
      });
    });

    socketConnection.on(SocketMessageType.transcripts, (newTranscripts: AudioTranscript[]) => {
      setAudioTranscripts((currentTranscripts) => {
        return uniqBy([...currentTranscripts, ...newTranscripts], "id");
      });
    });

    socketConnection.on(SocketMessageType.activityTimers, (activityTimers: ActivityTimerValue) => {
      setActivityTimerValues(activityTimers);
      if (timerRef.current === 0) {
        setTimer(Math.floor(activityTimers.mikaActiveDurationMilliseconds / 1000));
      }
    });

    socketConnection.on(
      SocketMessageType.notifications,
      async (notificationData: NotificationData[]) => {
        setNotifications((currentNotifications: NotificationData[]) => {
          return [...currentNotifications, ...notificationData];
        });
      }
    );
  };

  // Provider activity duration is the number of SCRIBE_PROVIDER_ACTIVITY events times the debounce interval
  const heartbeatInterval = 10 * 1000;
  const debouncedAction = useCallback(
    debounce(
      () => {
        sendMessage(SocketMessageType.providerActivity, {
          eventType: EventTypes.SCRIBE_PROVIDER_ACTIVITY
        });
      },
      heartbeatInterval,
      { leading: true, trailing: false, maxWait: heartbeatInterval }
    ),
    []
  );

  useEffect(() => {
    const onlineCheckInterval = setInterval(() => {
      if (socket.current?.connected) {
        setFailedConnectionAttempts(0);
      }

      if (!socket.current?.connected) {
        setFailedConnectionAttempts((count) => count + 1);
      }

      if (navigator && !navigator.onLine) {
        setFailedConnectionAttempts(100);
      }
    }, 3000);

    const sessionStatusInterval = setInterval(() => {
      sendMessage(SocketMessageType.recordingState, { appointmentId });
    }, 10 * 1000);

    socket.current?.io.on("reconnect", () => {
      setFailedConnectionAttempts(0);
    });
    socket.current?.io.on("open", () => {
      setFailedConnectionAttempts(0);
    });

    socket.current?.io.on("reconnect_error", () => {
      setFailedConnectionAttempts((count) => count + 1);
    });

    return () => {
      clearInterval(onlineCheckInterval);
      clearInterval(sessionStatusInterval);
    };
  }, [socket.current, appointmentId]);

  // Manage Page Open Heartbeat intervals
  useEffect(() => {
    const pageOpenHeartbeatInterval = setInterval(() => {
      sendMessage(SocketMessageType.providerActivity, {
        eventType: EventTypes.SCRIBE_APPT_PAGE_OPEN
      });
    }, heartbeatInterval);

    return () => {
      clearInterval(pageOpenHeartbeatInterval);
    };
  }, [socket.current, appointmentId]);

  // Manage Active Mika Heartbeat intervals
  useEffect(() => {
    const mikaActiveHeartbeatInterval = setInterval(() => {
      const shouldSendActivity = recording?.status === RecordingSessionStatus.RECORDING;
      if (shouldSendActivity) {
        sendMessage(SocketMessageType.providerActivity, {
          eventType: EventTypes.SCRIBE_MIKA_ACTIVE
        });
      }
    }, heartbeatInterval);

    return () => {
      clearInterval(mikaActiveHeartbeatInterval);
    };
  }, [socket.current, appointmentId, recording?.status]);

  useEffect(() => {
    // Reset state when appointmentId changes.
    // New state will be fetched via new websocket connection
    setFailedConnectionAttempts(0);
    setInsights([]);
    setAudioTranscripts([]);
    setTimer(0);
    setActivityTimerValues({
      pageOpenDurationMilliseconds: 0,
      mikaActiveDurationMilliseconds: 0,
      providerActiveDurationMilliseconds: 0
    });
    setSentScribeInvite(null);

    const token = localStorage.getItem("jwt");

    socket.current = appointmentId
      ? io(`${WEBSOCKET_URL}/scribeProvider`, {
          auth: {
            token
          },
          query: {
            appointmentId
          },
          transports: ["websocket"]
        })
      : null;

    if (socket.current) {
      registerAuthenticatedSocketListeners(socket.current);
    }
  }, [appointmentId]);

  // Keep recordingStatusRef aligned (Needed to access latest recording state on dismount)
  useEffect(() => {
    recordingStatusRef.current = recording?.status || null;
  }, [recording?.status]);

  // Clear Recording state and pause active recording sessions & Disconnect WS connection on Unmount event
  useEffect(() => {
    return () => {
      const hasActiveRecordingSession =
        recordingStatusRef.current === RecordingSessionStatus.RECORDING;

      if (hasActiveRecordingSession) {
        pauseSession(true);
      }

      if (socket.current) {
        // close socket, even if not connected
        socket.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (recording?.status === RecordingSessionStatus.RECORDING) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else if (recording?.status === RecordingSessionStatus.PAUSED) {
      if (interval) {
        clearInterval(interval);
      }
    } else {
      if (interval) {
        clearInterval(interval);
      }
      setTimer(0);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [recording?.status]);

  // Only provide a recordingValue if there is a matching appointmentId context
  const recordingValue =
    appointmentId && recording?.appointmentId === parseInt(appointmentId, 10) ? recording : null;

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    recording: recordingValue,
    connected: socket.current?.connected,
    failedConnectionAttempts,
    insights,
    audioTranscripts,
    activityTimerValues,
    timer,
    sendMessage,
    sendAudioData,
    endSession,
    pauseSession,
    sentScribeInvite,
    userInteractionHistory,
    setUserInteractionHistory,
    notifications,
    clearScribeNotifications
  };

  return (
    // div is a wrapper and doesn't need to be a semantic element
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onClick={debouncedAction} onKeyDown={debouncedAction}>
      <EncounterSocketContext.Provider value={value}>{children}</EncounterSocketContext.Provider>
    </div>
  );
};

export default EncounterSocketProvider;
