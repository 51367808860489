import {
  CREATE_SCRIBE_MODEL,
  FETCH_SCRIBE_MODELS,
  GET_SCRIBE_MODEL,
  UPDATE_SCRIBE_MODEL
} from "../constants";

import {
  CreateScribeModelAction,
  FetchScribeModelsAction,
  GetScribeModelAction,
  UpdateScribeModelAction
} from "../actions";

import { ScribeModel } from "../types";

export type ScribeModelAction =
  | FetchScribeModelsAction
  | GetScribeModelAction
  | CreateScribeModelAction
  | UpdateScribeModelAction;

export type ScribeModelsReduxState = {
  scribeModels: ScribeModel[];
  scribeModelsLoading: boolean;
  scribeModelDetailsLoading: boolean;
  scribeModelsCreateLoading: boolean;
  scribeModelsUpdateLoading: boolean;
  scribeModel?: ScribeModel;
};

const initialModels: ScribeModelsReduxState = {
  scribeModels: [],
  scribeModelsLoading: false,
  scribeModelDetailsLoading: false,
  scribeModelsCreateLoading: false,
  scribeModelsUpdateLoading: false,
  scribeModel: undefined
};

export const scribeModelsReducer = (
  state = initialModels,
  action: ScribeModelAction
): ScribeModelsReduxState => {
  const { type } = action;
  switch (type) {
    case CREATE_SCRIBE_MODEL: {
      const { status } = action as CreateScribeModelAction;

      return {
        ...state,
        scribeModelsCreateLoading: status === "loading"
      };
    }
    case FETCH_SCRIBE_MODELS: {
      const { status, payload } = action as FetchScribeModelsAction;

      return {
        ...state,
        scribeModels: payload?.scribeModels || state.scribeModels,
        scribeModelsLoading: status === "loading"
      };
    }
    case GET_SCRIBE_MODEL: {
      const { status, payload } = action as GetScribeModelAction;

      return {
        ...state,
        scribeModel:
          status === "success" && payload?.scribeModel ? payload?.scribeModel : undefined,
        scribeModelDetailsLoading: status === "loading"
      };
    }
    case UPDATE_SCRIBE_MODEL: {
      const { status } = action as UpdateScribeModelAction;

      return {
        ...state,
        scribeModelsUpdateLoading: status === "loading"
      };
    }
    default:
      return state;
  }
};
