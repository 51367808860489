/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import cx from "classnames";

import { Circle, CircleCheck } from "../../../../../Icon";
import Text from "../../../../../Text";

import styles from "./index.module.scss";
import Heading from "../../../../../Heading";

type PropsType = {
  isSelected?: boolean;
  setSelectedTemplate: (name: string) => void;
};

const ConsultNoteSelector = ({ isSelected, setSelectedTemplate }: PropsType) => {
  const placeHolderTexts = [
    "Reason for Consult",
    "Past Medical History",
    "History of Presenting Illness",
    "Medications",
    "Physical Examination",
    "Investigations",
    "Impressions",
    "Recommendations"
  ];
  const getPlaceHolderComponent = (name: string, index: number) => {
    return (
      <React.Fragment key={name}>
        <Text size="XS" bold>
          {name}
        </Text>
        <hr className={index > 2 && index < 6 ? styles.WidthHalf : ""} />
      </React.Fragment>
    );
  };
  return (
    <div
      className={cx(styles.TemplateCard, { [styles.SelectedTemplate]: isSelected })}
      onClick={() => setSelectedTemplate("letters")}
    >
      <div className={styles.TemplateHeader}>
        <span className={styles.IconColor}>
          {isSelected ? <CircleCheck size={24} /> : <Circle size={24} />}
        </span>
        <div>
          <Heading size="S" component="h5" bold className={styles.HeadingContent}>
            Consult Letter
          </Heading>
          <Text>A general purpose consult letter written in prose.</Text>
        </div>
      </div>
      <div className={styles.TemplatePreview}>
        {placeHolderTexts?.map((item, index) => getPlaceHolderComponent(item, index))}
      </div>
    </div>
  );
};

export default ConsultNoteSelector;
