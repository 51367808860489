import React from "react";
import { connect } from "react-redux";

import {
  AddCampaign,
  AddStaffUser,
  AddUnintegratedAppointment,
  ViewEditAppointment,
  BookAppointment,
  CampaignDetails,
  ConfirmationModal,
  EditProvider,
  EditStaffUser,
  DirectMessageDetails,
  SendAppointmentRequestDirectMessage,
  SendDirectMessage,
  SimulateJourneyModal,
  UploadAppointments,
  ViewAppointmentChatDetails,
  UploadDocument,
  FilterAppointments,
  CreateScribeSubscriptionPlan,
  InitializeNote,
  MicSetup,
  ViewPatientUploads,
  AddToVocabulary
} from "../../../common/Modals";
import {
  ActivateTestMode,
  AddAutomation,
  AddChatFlow,
  AddChatFlowOutput,
  AddChatNode,
  AddInstruction,
  AddLocation,
  AddMessageConfiguration,
  AddMessageTemplate,
  AddNoteTemplate,
  AddReportTemplate,
  AddService,
  AppointmentRequestsConfigModal,
  CloneChatFlowToOrganization,
  DeleteAutomation,
  DeleteChatFlowOutput,
  DeleteInstruction,
  DeleteMessageConfiguration,
  DeleteMessageTemplate,
  DeleteReportTemplateCard,
  DeleteService,
  EditAdminUser,
  EditBookingConfig,
  EditLocation,
  EditMessageTemplate,
  EditService,
  MigrateService,
  PublishChat,
  PurchaseNumbers,
  SendMessageTemplate,
  AddReasons,
  AddAdminUser,
  LinkOrganization
} from "../../../mikata_admin/Modals";

import isAdminUser from "../../../../utils/isAdminUser";
import { ModalTypes, AdminModalTypes } from "../../../../constants";

import { ReduxStateType, ModalType, ModalProps } from "../../../../types";
import EditScribeModel from "../../../mikata_admin/Modals/EditScribeModel";

const MODAL_COMPONENTS = {
  [ModalTypes.EDIT_STAFF_USER]: EditStaffUser,
  [ModalTypes.ADD_STAFF_USER]: AddStaffUser,
  [ModalTypes.ADD_TO_VOCABULARY]: AddToVocabulary,
  [ModalTypes.SEND_DIRECT_MESSAGE]: SendDirectMessage,
  [ModalTypes.SEND_APPOINTMENT_REQUEST_DIRECT_MESSAGE]: SendAppointmentRequestDirectMessage,
  [ModalTypes.UPLOAD_APPOINTMENTS]: UploadAppointments,
  [ModalTypes.CAMPAIGN_DETAILS]: CampaignDetails,
  [ModalTypes.BOOK_APPOINTMENT]: BookAppointment,
  [ModalTypes.CONFIRMATION]: ConfirmationModal,
  [ModalTypes.ADD_CAMPAIGN]: AddCampaign,
  [ModalTypes.ADD_UNINTEGRATED_APPOINTMENT]: AddUnintegratedAppointment,
  [ModalTypes.VIEW_EDIT_APPOINTMENT_DETAILS]: ViewEditAppointment,
  [ModalTypes.DIRECT_MESSAGE_DETAILS]: DirectMessageDetails,
  [ModalTypes.SIMULATE_JOURNEY]: SimulateJourneyModal,
  [ModalTypes.SEND_MESSAGE_TEMPLATE]: SendMessageTemplate,
  [ModalTypes.VIEW_APPOINTMENT_CHAT_DETAILS]: ViewAppointmentChatDetails,
  [ModalTypes.UPLOAD_DOCUMENT]: UploadDocument,
  [ModalTypes.FILTER_APPOINTMENTS]: FilterAppointments,
  [ModalTypes.EDIT_PROVIDER]: EditProvider,
  [ModalTypes.CREATE_SCRIBE_SUBSCRIPTION_PLAN]: CreateScribeSubscriptionPlan,
  [ModalTypes.INITIALIZE_NOTE]: InitializeNote,
  [ModalTypes.ADD_NOTE_TEMPLATE]: AddNoteTemplate,
  [ModalTypes.MIC_SETUP]: MicSetup,
  [ModalTypes.VIEW_PATIENT_UPLOADS]: ViewPatientUploads
};
const ADMIN_MODAL_COMPONENTS = {
  [AdminModalTypes.ADD_SMS_MESSAGE_TEMPLATE]: AddMessageTemplate,
  [AdminModalTypes.DELETE_SMS_MESSAGE_TEMPLATE]: DeleteMessageTemplate,
  [AdminModalTypes.ADD_AUTOMATION]: AddAutomation,
  [AdminModalTypes.DELETE_AUTOMATION]: DeleteAutomation,
  [AdminModalTypes.ADD_INSTRUCTION]: AddInstruction,
  [AdminModalTypes.DELETE_INSTRUCTION]: DeleteInstruction,
  [AdminModalTypes.ADD_MESSAGE_CONFIGURATION]: AddMessageConfiguration,
  [AdminModalTypes.ADD_REPORT_TEMPLATE]: AddReportTemplate,
  [AdminModalTypes.DELETE_MESSAGE_CONFIGURATION]: DeleteMessageConfiguration,
  [AdminModalTypes.DELETE_REPORT_TEMPLATE_CARD]: DeleteReportTemplateCard,
  [AdminModalTypes.ADD_LOCATION]: AddLocation,
  [AdminModalTypes.EDIT_LOCATION]: EditLocation,
  [AdminModalTypes.EDIT_MESSAGE_TEMPLATE]: EditMessageTemplate,
  [AdminModalTypes.PUBLISH_CHAT]: PublishChat,
  [AdminModalTypes.EDIT_BOOKING_CONFIGURATION]: EditBookingConfig,
  [AdminModalTypes.APPOINTMENT_REQUESTS_CONFIGURATION]: AppointmentRequestsConfigModal,
  [AdminModalTypes.ACTIVATE_TEST_MODE]: ActivateTestMode,
  [AdminModalTypes.ADD_CHAT_NODE]: AddChatNode,
  [AdminModalTypes.ADD_CHAT_FLOW]: AddChatFlow,
  [AdminModalTypes.CLONE_CHAT_FLOW_TO_ORGANIZATION]: CloneChatFlowToOrganization,
  [AdminModalTypes.ADD_SERVICE]: AddService,
  [AdminModalTypes.EDIT_SERVICE]: EditService,
  [AdminModalTypes.PURCHASE_NUMBERS]: PurchaseNumbers,
  [AdminModalTypes.MIGRATE_SERVICE]: MigrateService,
  [AdminModalTypes.DELETE_SERVICE]: DeleteService,
  [AdminModalTypes.ADD_CHAT_FLOW_OUTPUT]: AddChatFlowOutput,
  [AdminModalTypes.DELETE_CHAT_FLOW_OUTPUT]: DeleteChatFlowOutput,
  [AdminModalTypes.ADD_REASONS]: AddReasons,
  [AdminModalTypes.ADD_ADMIN_USER]: AddAdminUser,
  [AdminModalTypes.EDIT_ADMIN_USER]: EditAdminUser,
  [AdminModalTypes.ADD_NOTE_TEMPLATE]: AddNoteTemplate,
  [AdminModalTypes.EDIT_SCRIBE_MODEL]: EditScribeModel,
  [AdminModalTypes.LINK_ORGANIZATION]: LinkOrganization
};

type PropsType = {
  modalType?: ModalType;
  modalProps?: ModalProps;
};

const ModalManager = ({ modalType, modalProps }: PropsType): React.ReactElement | null => {
  if (!modalType) {
    return null;
  }

  const getModal = (modalType: string | undefined) => {
    if (!modalType) return undefined;

    const isAdmin = isAdminUser();

    const modalTypeMap: { [modalType: string]: React.ElementType } = isAdmin
      ? { ...MODAL_COMPONENTS, ...ADMIN_MODAL_COMPONENTS }
      : MODAL_COMPONENTS;

    return modalTypeMap[modalType];
  };
  const SpecificModal = getModal(modalType);

  if (!SpecificModal) {
    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <SpecificModal {...modalProps} />;
};

type MultiModalManagerPropsType = {
  modals: Array<{ modalType?: ModalType; modalProps?: ModalProps }>;
  modalsLength: number;
};

const MultiModalManager = ({ modals, modalsLength }: MultiModalManagerPropsType) => {
  if (modalsLength === 0) return null;
  return (
    <>
      {modals.map((modal, i) => {
        return (
          <ModalManager
            key={`${modal.modalType}-${i}`}
            modalType={modal.modalType}
            modalProps={modal.modalProps}
          />
        );
      })}
    </>
  );
};

const mapStateToProps = ({ modals }: ReduxStateType) => {
  return {
    modals: modals.modals,
    modalsLength: modals.modals.length
  };
};

export default connect(mapStateToProps)(MultiModalManager);
