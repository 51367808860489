import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import Text from "../../ui/Text";
import Button from "../../ui/Button";
import { ResponsiveHide } from "../../ui/Responsive";

import { UserContext } from "../../providers/UserProvider";

import { daysUntilExpiry, isDemoAccountExpired } from "../../../utils/demoAccountExpiry";
import { isEligibleForSelfServeBilling } from "../../../utils/scribeFeatureHelpers";

import { UserTypeConstants } from "../../../constants";

import styles from "./index.module.scss";

const DemoDaysRemaining = () => {
  const user = useContext(UserContext);
  const isAdmin = user.userType === UserTypeConstants.MIKATA_ADMIN;

  const demoAccountExpiry = user?.settings?.features?.scribe?.trialEnd || null;
  if (isAdmin || !demoAccountExpiry) {
    return null;
  }
  const isInTrial = user?.settings?.features?.scribe?.status === "trial";
  const demoAccountExpired = isDemoAccountExpired(user?.settings);
  const remainingDays = daysUntilExpiry(demoAccountExpiry);
  const demoAccountMessage =
    remainingDays > 0 ? `Your trial ends in ${remainingDays} days` : "Your trial ends in 24 hours";
  const isEligibleForSelfServeSignUp = isEligibleForSelfServeBilling(user.settings);

  if (!isInTrial) return null;

  return (
    <div className={styles.DemoAccount}>
      {!demoAccountExpired && (
        <>
          <Text size="S" align="right">
            {demoAccountMessage}
          </Text>
          <ResponsiveHide hideOnMobile>
            {isEligibleForSelfServeSignUp && !demoAccountExpired && (
              <NavLink
                to={{
                  pathname: "/settings/billing",
                  search: "?form=scribeSignUp"
                }}
                className={styles.DropdownListItem}
              >
                <Text id="upgradeNow" className={styles.BillingLink} size="S" component="span">
                  Upgrade Now
                </Text>
              </NavLink>
            )}
          </ResponsiveHide>
        </>
      )}

      {demoAccountExpired && (
        <>
          <Text className={styles.TrialExpired} size="S">
            Trial Expired
          </Text>
          {isEligibleForSelfServeSignUp ? (
            <NavLink
              to={{
                pathname: "/settings/billing",
                search: "?form=scribeSignUp"
              }}
              className={styles.DropdownListItem}
            >
              <Text className={styles.BillingLink} size="S" component="span">
                Upgrade Now
              </Text>
            </NavLink>
          ) : (
            <a href="mailto:billing@mikatahealth.com">
              <Button inline>Contact Us</Button>
            </a>
          )}
        </>
      )}
    </div>
  );
};

export default DemoDaysRemaining;
