import React, { useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import StreamingAnimation from "./StreamingAnimation";
import Button from "../../../../../ui/Button";
import { CircleCheck, Insights, Pause, Play, Stop } from "../../../../../ui/Icon";
import Text from "../../../../../ui/Text";

import RecordingDetailsModal from "./RecordingDetailsModal";

import { EncounterSocketContext } from "../../../../../providers/EncounterSocketProvider";
import { LayoutContext, LayoutViewMode } from "../../../../../providers/LayoutProvider";
import { UserContext } from "../../../../../providers/UserProvider";

import useAudioInput from "../useAudioInput";

import { openModal as openModalAction, OpenModal } from "../../../../../../actions";

import { updateQueryString, useQueryString } from "../../../../../../utils/queryStringHelpers";

import { ModalTypes } from "../../../../../../constants";

import {
  AppointmentConversation,
  RecordingSessionStatus,
  ReduxStateType,
  UserFeatureNames
} from "../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  patientFullName: string;
  conversations?: Array<AppointmentConversation>;
  openModal: OpenModal;
};

const displayMilliseconds = (ms: number) => {
  if (ms === undefined || ms === null) {
    return null;
  }
  const hours = Math.floor(ms / 3600000);
  return moment.utc(ms).format(hours ? "HH:mm:ss" : "m:ss");
};

const AppointmentRecordingButton = ({ patientFullName, conversations, openModal }: PropsType) => {
  const { viewMode } = useContext(LayoutContext);
  const user = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { parsed = {} } = useQueryString();
  const { promptStart = "n" } = parsed;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const canUseScribe = !!user.canUseFeature(UserFeatureNames.SCRIBE);
  const isMobile = viewMode === LayoutViewMode.MOBILE;

  const {
    recording,
    endSession,
    pauseSession,
    failedConnectionAttempts,
    activityTimerValues,
    timer
  } = useContext(EncounterSocketContext);
  const appointmentId = recording?.appointmentId;

  const { mikaActiveDurationMilliseconds } = activityTimerValues;

  const { micStreamErrorType, volume } = useAudioInput({
    appointmentIdContext: appointmentId
  });

  const isDisconnected = failedConnectionAttempts > 3;

  // If micStream error exist, then display debug mic modal
  useEffect(() => {
    if (micStreamErrorType) {
      openModal(ModalTypes.MIC_SETUP, {
        appointmentId,
        micStreamErrorType
      });
    }
  }, [micStreamErrorType]);

  // Handle "promptStart" params
  useEffect(() => {
    if (promptStart === "y") {
      if (!isDisconnected) {
        setModalOpen(true);
        updateQueryString({ promptStart: undefined }, setSearchParams);
      }
    }
  }, [promptStart, isDisconnected]);

  const determineButtonToRender = () => {
    if (user && !canUseScribe) {
      return (
        <div className={styles.Completed}>
          <Text align="right">Mika feature not available</Text>
        </div>
      );
    }

    // Awaiting initialization of session (connected & non-connected)
    if (!recording) {
      return (
        <Button className={styles.RecordingButton} disabled inline>
          Connecting...
        </Button>
      );
    }
    const isPaused = [RecordingSessionStatus.PAUSED, RecordingSessionStatus.LOCAL_PAUSED].includes(
      recording?.status
    );

    // Completed session (connected & non-connected)
    if (recording?.sessionCompleted) {
      return (
        <div className={styles.CompletedContainer}>
          <div className={styles.CompletedIcon}>
            <CircleCheck />
          </div>
          <div className={styles.Completed}>
            <Text align="right" size={isMobile ? "S" : "M"}>
              Session complete
            </Text>
            <Text align="right" size={isMobile ? "S" : "M"}>
              Mika active {displayMilliseconds(mikaActiveDurationMilliseconds)}
            </Text>
          </div>
        </div>
      );
    }

    // Awaiting provider acceptance (connected & non-connected)
    if (!recording?.providerReady && !isPaused) {
      return (
        <Button
          className={styles.RecordingButton}
          onClick={() => {
            if (!isDisconnected) setModalOpen(true);
          }}
          size="S"
          disabled={isDisconnected}
        >
          {isMobile ? (
            <Insights size={24} />
          ) : (
            <>
              <div className={styles.RecordingButtonIcon}>
                <Insights size={24} />
              </div>
              {isDisconnected ? "Connecting..." : "Activate Mika"}
            </>
          )}
        </Button>
      );
    }

    // Active session (connected & non-connected, paused and not paused)
    const controlIcon = isPaused ? <Play size={24} /> : <Pause size={24} />;
    return (
      <div className={styles.AppointmentDetailsController}>
        {isDisconnected ? (
          <Text className={styles.ReconnectingText} bold size="XS">
            Reconnecting...
          </Text>
        ) : (
          <>
            <div className={styles.WaveformButton}>
              <Text className={styles.TimeText} size="S">
                {displayMilliseconds(timer * 1000)}
              </Text>
            </div>
            <StreamingAnimation recording={recording} isPaused={isPaused} volume={volume} />
          </>
        )}

        <Button
          onClick={() => {
            pauseSession(!isPaused);
          }}
          inline
          className={styles.PlayPauseButton}
        >
          {controlIcon}
        </Button>

        <Button
          className={styles.Active}
          onClick={() => {
            endSession();
          }}
          size="S"
          id={styles.recordingButtonStop}
          inline
        >
          <div className={styles.EndButton}>
            <Stop />
          </div>
        </Button>
      </div>
    );
  };

  return (
    <>
      <div className={styles.ButtonWrapper}>{determineButtonToRender()}</div>
      <RecordingDetailsModal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        patientFullName={patientFullName}
        conversations={conversations}
        appointmentId={appointmentId}
      />
    </>
  );
};

const mapStateToProps = ({ appointments }: ReduxStateType) => ({
  conversations: appointments.details.data?.conversations || []
});

export default connect(mapStateToProps, { openModal: openModalAction })(AppointmentRecordingButton);
